<template>
    <div class="books">

        <div class="table-filters">
            <span>Pokaži</span>
            <container class="per-page">
                <select-field :options="perPageOptions"
                              @input="changeBooksPerPage"
                              :filterable="false"
                              :clearable="false"
                              :value="perPage"/>
            </container>
            <container>
                <input-field
                    class="table-search-input"
                    @input="searchBooksAction"
                    type="text"
                    icon="icon-search"
                    :value="searchText"
                    placeholder="Pretraži knjige"/>
            </container>
        </div>

        <custom-table :select="true"
                      :fields="tableFields"
                      :data="booksPage"
                      v-if="!async"
                      @column-click="item => editItem(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">
            <template v-slot:categories="props">
                <span>{{ props.item.categories | parseCategories }}</span>
            </template>
            <template v-slot:status="props">
                <book-status-dot :status="props.item.status"/>
                <span>{{ props.item.status|parseBookStatus }}</span>
            </template>
            <template v-slot:created_at="props">
                <span>{{ props.item.created_at | moment('DD.MM.YYYY') }}</span>
            </template>

            <template v-slot:is_featured="props">
                <TSSwitch :value="props.item.is_featured" @input="toggleIsFeatured(props.item)"/>
            </template>

            <template v-slot:featured_from="props">
                <input-field type="date" :value="props.item.featured_from"
                             @input="date => changeFeaturedDateField(props.item, date, 'featured_from')"/>
            </template>

            <template v-slot:featured_to="props">
                <input-field type="date" :value="props.item.featured_to"
                             @input="date => changeFeaturedDateField(props.item, date, 'featured_to')"/>
            </template>

            <template v-slot:actions="props">
                <div>
                    <dropdown-menu anchor="right">
                        <template v-slot:handle>
                            <i style="font-size: 15px" class="icon icon-menudots"/>
                        </template>

                        <dropdown-menu-item @click="editItem(props.item.id)">
                            Edituj
                        </dropdown-menu-item>
                        <dropdown-menu-item @click="deleteBook(props.item.id)">
                            Izbriši
                        </dropdown-menu-item>
                    </dropdown-menu>
                </div>
            </template>
        </custom-table>
        <div class="marketing-loader-container" v-else>
            <spinner/>
        </div>

        <div class=" footer">
            <custom-pagination :total-pages="totalPages"
                               :current-page="currentPage"
                               @change="changeBooksPage"
            />
        </div>
    </div>
</template>

<script>

import CustomTable from "@/components/Ui/CustomTable/CustomTable";
import {
    CHANGE_BOOKS_PER_PAGE_ACTION,
    DELETE_BOOK_ACTION, EDIT_BOOK_ACTION,
    FETCH_ALL_BOOKS_ACTION,
    SEARCH_BOOKS_ACTION, SELECT_BOOK,
    SET_BOOKS_CURRENT_PAGE,
} from "@/store/actions/books";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SelectField from "@/components/Ui/SelectField/SelectField";
import InputField from "@/components/Ui/InputField/InputField";
import Container from "@/components/Ui/Container/Container";
import CustomPagination from "@/components/CustomPagination/CustomPagination";
import BookStatusDot from "@/components/BookStatusDot/BookStatusDot";
import { RouteLink } from '@/store/modules/menu';
import DropdownMenu from "@/components/Ui/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "@/components/Ui/DropdownMenu/DropdownMenuItem";
import { showAreYouSureSwal, showSuccessSwal } from "@/utils/utils";
import TSSwitch from "@/components/Ui/TSSwitch/TSSwitch";
import Spinner from '@/components/Ui/Spinner/Spinner';

export default {
    name: "Marketing",
    components: {
        Spinner,
        TSSwitch,
        DropdownMenuItem,
        DropdownMenu,
        BookStatusDot,
        CustomPagination,
        Container,
        InputField,
        SelectField,
        CustomTable
    },
    data() {
        return {
            tableFields: [{
                name: 'title',
                title: 'Naziv Knjige',
                width: '12%',
                enableClick: true,
            }, {
                name: 'categories',
                title: 'Kategorija',
                width: '12%',
                lightText: true,
                slot: 'categories'
            }, {
                name: 'price',
                title: 'Cijena',
                width: '8%',
            }, {
                name: 'status',
                title: 'Status',
                slot: 'status',
                width: '8%',
            }, {
                name: 'is_featured',
                title: 'Promovisana',
                slot: 'is_featured',
                width: '12%',
            }, {
                name: 'featured_from',
                title: 'Datum promocije',
                slot: 'featured_from',
            }, {
                name: 'featured_to',
                title: 'Kraj promocije',
                slot: 'featured_to',
            }]
        };
    },
    computed: {
        ...mapGetters({
            booksPage: 'paginatedBooks',
            totalPages: 'totalBookPages'
        }),
        async() {
            return this.$store.state.books.async;
        },
        searchText() {
            return this.$store.state.books.searchText;
        },
        currentPage() {
            return this.$store.state.books.currentPage;
        },
        perPage() {
            return this.$store.state.books.perPage;
        },
        perPageOptions() {
            return [10, 25, 50];
        }
    },
    methods: {
        ...mapMutations({
            changeBooksPage: SET_BOOKS_CURRENT_PAGE,
            selectBook: SELECT_BOOK
        }),
        ...mapActions({
            fetchAllBooksAction: FETCH_ALL_BOOKS_ACTION,
            searchBooksAction: SEARCH_BOOKS_ACTION,
            deleteBookAction: DELETE_BOOK_ACTION,
            editBookAction: EDIT_BOOK_ACTION,
            changeBooksPerPage: CHANGE_BOOKS_PER_PAGE_ACTION
        }),
        goToAddBook() {
            this.$router.push(RouteLink.ManageBook);
        },
        onTableSelectItem(itemId) {
            console.log(itemId)
        },
        selectAllChanged(data) {
            console.log(data);
        },
        editItem(bookId) {
            this.selectBook(bookId);
            this.$router.push(RouteLink.ManageBook + '/' + bookId);
        },
        deleteBook(bookId) {
            showAreYouSureSwal({ text: "Jeste li sigurni da želite izbrisati knjigu?" }).then(result => {
                if (result.value) {
                    this.deleteBookAction(bookId).then(() => showSuccessSwal())
                }
            });
        },
        toggleIsFeatured(book) {
            showAreYouSureSwal({ text: "", icon: 'question' }).then(result => {
                if (result.value) {
                    this.editBookAction({
                        id: book.id,
                        data: {
                            is_featured: !book.is_featured
                        }
                    }).then(() => showSuccessSwal())
                }
            });
        },
        changeFeaturedDateField(book, date, field) {
            showAreYouSureSwal({ text: "", icon: 'question' }).then(result => {
                if (result.value) {
                    this.editBookAction({
                        id: book.id,
                        data: {
                            [field]: this.$moment(date).toISOString()
                        }
                    }).then(() => showSuccessSwal())
                }
            });
        }
    },
    mounted() {
        this.fetchAllBooksAction();
    },
};
</script>

<style lang="scss">
.books {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .marketing-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
