<template>
    <div :class="['tvornica-snova', 'checkbox-container', focus && 'focus']">
        <span class="checkbox-label" v-if="label">{{ label }}</span>

        <input @focusin="focus = true" @focusout="focus = false" type="checkbox" @change="$emit('input', !value)" :checked="value">
        <span class=" checkmark" @click="$emit('input', !value)"></span>
    </div>
</template>

<script>

export default {
    name: "Checkbox",
    data() {
        return {
            focus: false
        }
    },
    props: {
        label: {
            type: String,
            required: false
        },
        value: Boolean,
    },
};
</script>

<style scoped lang="scss">

.tvornica-snova.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.focus {
        .checkmark {
            border: 1px solid $mainAccentColor;
        }
    }

    .checkbox-label {

    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        transition: all 300ms ease-in-out;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $widgetBorderColor;
        transition: all 200ms ease-in-out;
    }

    /* When the checkbox is checked, add a background */
    input:checked ~ .checkmark {
        background-color: $mainAccentColor;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

</style>
