// import router from '../router';
import { STORAGE_KEYS, ERROR_MESSAGES } from '@/utils/consts';
import { cloneDeep, merge } from 'lodash';
import { showSwalToast } from '@/utils/utils';

// ENDPOINTS

export const endpoint = (process.env.VUE_APP_ENDPOINT || 'http://localhost:8000') + '/api';
export const storage_url = process.env.VUE_APP_STORAGE_URL || 'http://localhost:8001';

export const login = `${endpoint}/login`;
export const me = `${endpoint}/me`;
export const users = `${endpoint}/users`;
export const categories = `${endpoint}/categories`
export const authors = `${endpoint}/authors`
export const books = `${endpoint}/books`
export const chapters = `${endpoint}/chapters`;
export const tags = `${endpoint}/tags`;
export const discounts = `${endpoint}/discounts`;
export const publishers = `${endpoint}/publishers`;
export const promocodes = `${endpoint}/promo_codes`;
export const coins_packages = `${endpoint}/coins_packages`
export const book_discounts = `${endpoint}/book_discounts`;
export const chapter_transactions = `${endpoint}/chapter_transactions`
export const payment_requests = `${endpoint}/payment_requests`
export const agent_books = `${endpoint}/agent_books`
export const owned_books = `${endpoint}/owned_books`;
export const analytics = `${endpoint}/analytics`;

export function handleErrors(response) {
    if (response.ok || response.status === 204) {
        return response;
    } else {
        throw response;
    }
}

export function uploadImage() {

}

export function apiCall(params = {
    url: '',
}) {
    const originalParams = cloneDeep(params);

    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

    const defaultParams = {
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
        body: null,
    };

    if (params.body) {
        delete defaultParams.headers['content-type'];
    }

    if (params.jsonData) {
        params.body = JSON.stringify(params.jsonData);
        delete params.jsonData;
    }

    if (token) {
        defaultParams.headers['Authorization'] = `Bearer ${token}`;
    }

    const actualParams = merge(defaultParams, params);

    return new Promise((resolve, reject) => {
        return fetch(params.url, actualParams)
            .then(handleErrors)
            .then(resolve)
            .catch(async ex => {
                if (ex.status === 401) {
                    if (ex.json) {
                        const message = await ex.json()
                            .then(data => {
                                return data.message;
                            });
                        if (message === 'Invalid credentials.') {
                            // this is login, propagate this error up
                            // showSwalToast(message);
                            reject(message);
                        } else if (message === 'Expired JWT Token') {
                            // we should probs refresh token here
                            const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

                            if (refreshToken) {
                                // proceed
                                let data = new FormData();
                                data.append('refresh_token', refreshToken);
                                return fetch(endpoint + '/token/refresh', {
                                    method: 'post',
                                    body: data,
                                })
                                    .then(handleErrors)
                                    .then(r => r.json())
                                    .then(r => {
                                        console.log(r);
                                        localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
                                        localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
                                        //redo api call
                                        apiCall(originalParams)
                                            .then(resolve);
                                    })
                                    .catch(() => {
                                        logOut();
                                    });
                            } else {
                                logOut();
                            }
                        } else if (message === 'An authentication exception occurred.') {
                            // USER PROBABLY GOT DISABLED
                            // showSwalToast(message);
                            logOut();
                        }
                    }
                } else if (ex.status === 404) {
                    // showSwalToast(ex.url + '\n' + ex.statusText);
                } else {
                    if (ex.json) {
                        try {
                            await ex.json()
                                .then(data => {
                                    if (data.detail) {
                                        showSwalToast(ERROR_MESSAGES[data.detail] ?? data.detail);
                                    }
                                });
                        } catch (ex) {
                            // this is most likely an  internal server that returned html body.
                            // rip
                            reject("Desila se nepoznata greška");
                        }
                    }
                }

                reject(ex);
            });
    });
}

export function logOut() {
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    window.location.reload();
}
