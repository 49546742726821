import {
    SELECT_USER,
    SET_USERS,
    SELECT_CAM,
    FETCH_ALL_USERS_ACTION,
    SET_USERS_ASYNC,
    SET_SEARCH_USERS,
    SET_USERS_SEARCH_TEXT,
    FETCH_USERS_PAGE_ACTION,
    APPEND_USERS_PAGE,
    SEARCH_USERS_ACTION,
    SET_SEARCH_ASYNC,
    TOGGLE_USER_ENABLED_ACTION,
    UPDATE_USER, EDIT_USER_ACTION,
    ADD_USER_ACTION,
    ADD_NEW_USER,
    CHANGE_USER_PASSWORD_ACTION,
    DELETE_USER_ACTION,
    DELETE_USER,
    SET_USERS_TOTAL_PAGES,
    SET_USERS_CURRENT_PAGE, GET_USER_BY_ID_ACTION, CHANGE_USERS_PER_PAGE_ACTION
} from '@/store/actions/users';
import {
    addUser,
    changeUserPassword,
    deleteUser,
    editUser, fetchUserById,
    fetchUsersPage,
    toggleUserEnabled
} from '@/api/users/users';
import { getPerPage, isSalesAgent, searchUser, setPerPage } from '@/utils/utils';
import { ROLES } from "@/utils/consts";
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        users: [],

        searchText: null,
        searchUsers: null,
        searchAsync: false,

        selectedUserId: null,

        // Pagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        async: false,
    }),
    mutations: {
        [SET_USERS](state, users) {
            state.users = users;
        },
        [SELECT_USER](state, userId) {
            state.selectedUserId = userId;
        },
        [SELECT_CAM](state, camId) {
            state.selectedCamId = camId;
        },
        [SET_USERS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_USERS](state, searchUsers) {
            state.searchUsers = searchUsers;
        },
        [SET_USERS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_USERS_PAGE](state, users) {
            if (state.users !== null) {
                state.users = state.users.concat(users);
            } else {
                state.users = [...users];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_USER](state, { id, ...data }) {
            for (let user of state.users) {
                if (user.id === id) {
                    Object.assign(user, data);
                    return;
                }
            }
        },
        [ADD_NEW_USER](state, newUser) {
            const index = state.users.findIndex(u => u.id === newUser.id);

            if (index === -1) {
                state.users.unshift(newUser);
            }
        },
        [DELETE_USER](state, userId) {
            if (state.users?.length > 0) {
                state.users = state.users.filter(u => u.id !== userId);
            }
        },
        [SET_USERS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_USERS_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
    },
    actions: {
        async [FETCH_ALL_USERS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_USERS_ASYNC, true);
            commit(SET_USERS, []);
            commit(SET_SEARCH_USERS, null);
            commit(SET_USERS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_USERS_PAGE_ACTION, { page });
            }

            commit(SET_USERS_CURRENT_PAGE, 1)
            commit(SET_USERS_TOTAL_PAGES, page);

            commit(SET_USERS_ASYNC, false);
        },
        async [FETCH_USERS_PAGE_ACTION]({ commit, state}, { page }) {
            try {


                const { 'hydra:view': pagination, 'hydra:member': users } = await fetchUsersPage(page, state.perPage);
                if (Array.isArray(users)) {
                    // good, we have stuff
                    commit(APPEND_USERS_PAGE, users, page);
                }

                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Users Page', ex);
            }
        },
        [SEARCH_USERS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_USERS_SEARCH_TEXT, null);
                commit(SET_SEARCH_USERS, null);
            } else {
                commit(SET_USERS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_USERS, state.users.filter(user => searchUser(user, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [TOGGLE_USER_ENABLED_ACTION]({ commit }, { userId, toggle }) {
            commit(SET_USERS_ASYNC, true);
            try {
                const updatedUser = await toggleUserEnabled(userId, toggle);
                commit(UPDATE_USER, updatedUser);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [EDIT_USER_ACTION]({ commit }, { id, data }) {
            commit(SET_USERS_ASYNC, true);
            try {
                const updateUser = await editUser(id, data);
                commit(UPDATE_USER, updateUser);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [ADD_USER_ACTION]({ commit }, data) {
            commit(SET_USERS_ASYNC, true);
            try {
                const newUser = await addUser(data);
                commit(ADD_NEW_USER, newUser);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [CHANGE_USER_PASSWORD_ACTION]({ commit }, { userId, password }) {
            commit(SET_USERS_ASYNC, true);
            try {
                const updateUser = await changeUserPassword(userId, password);
                commit(UPDATE_USER, updateUser);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [DELETE_USER_ACTION]({ commit }, userId) {
            commit(SET_USERS_ASYNC, true);
            try {
                await deleteUser(userId);
                commit(DELETE_USER, userId);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [GET_USER_BY_ID_ACTION]({ state, commit }, { userId }) {
            if (state.async) return;

            commit(SET_USERS_ASYNC, true);
            const user = await fetchUserById(userId).catch(() => commit(SET_USERS_ASYNC, false));

            if (user) {
                state.users = [user, ...state.users];
                commit(SELECT_USER, user.id);
            }

            commit(SET_USERS_ASYNC, false);
        },
        async [CHANGE_USERS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);
            commit(UPDATE_PER_PAGE);
            dispatch(FETCH_ALL_USERS_ACTION);
        }
    },
    getters: {
        selectedUser(state) {
            return state.users.find(b => b.id === state.selectedUserId);
        },
        userOptions(state) {
            return state.users.map(m => ({ label: m.name, value: m.id }));
        },
        allUsers(state) {
            if (state.searchUsers !== null) {
                return state.searchUsers;
            } else {
                return state.users;
            }
        },
        paginatedUsers(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allUsers.slice(beginIndex, endIndex);
        },
        totalUsersPages(state) {
            return state.totalPages;
        },
        roleOptions() {
            return Object.keys(ROLES).map(r => ({ label: r, value: r }))
        },
        usersAsync(state) {
            return state.async;
        },
        salesAgents(state) {
            return state.users.filter(u => isSalesAgent(u));
        }
    },
};
