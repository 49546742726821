import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard/Dashboard";
import Books from "@/views/Books/Books";
import { RouteLink } from "@/store/modules/menu";
import InternalWidgets from "@/views/InternalWidgets/InternalWidgets";
import ManageBook from "@/views/ManageBook/ManageBook";
import Login from '@/views/Login/Login';
import Authors from '@/views/Authors/Authors';
import ManageAuthor from '@/views/ManageAuthor/ManageAuthor';
import Categories from '@/views/Categories/Categories';
import ManageCategory from '@/views/ManageCategory/ManageCategory';
import Tags from "@/views/Tags/Tags";
import ManageTag from "@/views/ManageTag/ManageTag";
// import Discounts from "@/views/Discounts/Discounts";
// import ManageDiscount from "@/views/ManageDiscount/ManageDiscount";
import Publishers from "@/views/Publishers/Publishers";
import ManagePublisher from "@/views/ManagePublisher/ManagePublisher";
import Users from "@/views/Users/Users";
import ManageUser from "@/views/ManageUser/ManageUser";
import Marketing from "@/views/Marketing/Marketing";
// import ManageCoinsPackage from "@/views/ManageCoinsPackage/ManageCoinsPackage";
// import CoinsPackages from "@/views/CoinsPackages/CoinsPackages";
import Analytics from "@/views/Analytics/Analytics";
import AgentBooks from "@/views/AgentBooks/AgentBooks";
import ManageAgentBook from "@/views/ManageAgentBook/ManageAgentBook";
import { ROLES_ACCESS_LEVELS } from "@/utils/consts";
import PromoCodes from '@/views/PromoCodes/PromoCodes';
import ManagePromocode from '@/views/ManagePromocode/ManagePromocode';
import AppleAnalytics from '@/views/AppleAnalytics/AppleAnalytics';

Vue.use(VueRouter)

const routes = [
    {
        path: RouteLink.Dashboard,
        name: 'Dashboard',
        component: Dashboard,
    }, {
        path: RouteLink.Login,
        name: 'Login',
        component: Login,
    }, {
        path: RouteLink.Books,
        name: 'Books',
        component: Books,
        meta: {
            mainName: 'Knjige',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.Books_Authors,
        name: 'Authors',
        component: Authors,
        meta: {
            mainName: 'Autori',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.Books_Categories,
        name: 'Categories',
        component: Categories,
        meta: {
            mainName: 'Kategorije',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.Books_Tags,
        name: 'Tags',
        component: Tags,
        meta: {
            mainName: 'Tagovi',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, /*{
        path: RouteLink.Bonuses,
        name: 'Bonuses',
        component: Discounts,
        meta: {
            mainName: 'Bonusi',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    },*/ {
        path: RouteLink.Publishers,
        name: 'Bonuses',
        component: Publishers,
        meta: {
            mainName: 'Izdavači',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.Users,
        name: 'Users',
        component: Users,
        meta: {
            mainName: 'Korisnici',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.PromoCodes,
        name: 'PromoCodes',
        component: PromoCodes,
        meta: {
            mainName: 'Promo Kodovi',
            subName: null,
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.ManageBonus + '/:id?',
        name: "Manage Promocode",
        component: ManagePromocode,
        meta: {
            mainName: 'Promo Kodovi',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.Marketing,
        name: 'Marketing',
        component: Marketing,
        meta: {
            mainName: 'Marketing',
            subName: null,
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.AgentBooks,
        name: 'Agent Books',
        component: AgentBooks,
        meta: {
            mainName: 'Agenti',
            subName: 'Knjige',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, /*{
        path: RouteLink.CoinsPackages,
        name: 'Coins Packages',
        component: CoinsPackages,
        meta: {
            mainName: 'Paketi',
            subName: 'Lista',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    },*/ {
        path: RouteLink.ManageBook + '/:id?',
        name: "Manage Book",
        component: ManageBook,
        meta: {
            mainName: 'Knjiga',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.ManageAuthor + '/:id?',
        name: "Manage Authors",
        component: ManageAuthor,
        meta: {
            mainName: 'Autor',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.ManageCategory + '/:id?',
        name: "Manage Categories",
        component: ManageCategory,
        meta: {
            mainName: 'Kategorija',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.ManageTag + '/:id?',
        name: "Manage Tags",
        component: ManageTag,
        meta: {
            mainName: 'Tag',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, /*{
        path: RouteLink.ManageBonus + '/:id?',
        name: "Manage Bonus",
        component: ManageDiscount,
        meta: {
            mainName: 'Bonus',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    },*/ {
        path: RouteLink.ManagePublisher + '/:id?',
        name: "Manage Publisher",
        component: ManagePublisher,
        meta: {
            mainName: 'Izdavač',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.ManageUser + '/:id?',
        name: "Manage User",
        component: ManageUser,
        meta: {
            mainName: 'Korisnik',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    },/* {
        path: RouteLink.ManageCoinsPackage + '/:id?',
        name: "Manage Coins Package",
        component: ManageCoinsPackage,
        meta: {
            mainName: 'Paket',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    },*/ {
        path: RouteLink.ManageAgentBook + '/:id?',
        name: "Manage Agent Book",
        component: ManageAgentBook,
        meta: {
            mainName: 'Knjiga agenta',
            subName: 'Uredi',
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
        }
    }, {
        path: RouteLink.Analytics,
        name: "Analytics",
        component: Analytics,
        meta: {
            mainName: 'Analitika',
            subName: null,
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SALES_AGENT
        }
    }, {
        path: RouteLink.AppleAnalytics,
        name: "AppleAnalytics",
        component: AppleAnalytics,
        meta: {
            mainName: 'Apple Analitika',
            subName: null,
            accessLevel: ROLES_ACCESS_LEVELS.ROLE_SALES_AGENT
        }
    }, {
        path: RouteLink.InternalWidgets,
        name: "Internal Widgets",
        component: InternalWidgets,
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
