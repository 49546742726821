<template>
    <button class="tvornica-snova-icon" @click="$emit('click')">
        <i :class="['some-icon-class', icon]"/>
    </button>
</template>

<script>

export default {
    name: "IconButton",
    props: {
        icon: {
            type: String,
            required: true
        }
    }
};

</script>

<style scoped lang="scss">

$iconButtonBackgroundLight: #edf0f3;
$iconButtonBackground: #dcdcdc;
$iconButtonColor: #83859b;

button.tvornica-snova-icon {
    outline: none;
    border: 1px solid transparent;
    background: $iconButtonBackgroundLight;
    color: $iconButtonColor;
    font-size: 14px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:focus,
    &:active,
    &:hover {
        background: $iconButtonBackground;
    }
}
</style>
