import {
    SELECT_AGENT_BOOK,
    SET_AGENT_BOOKS,
    FETCH_ALL_AGENT_BOOKS_ACTION,
    SET_AGENT_BOOKS_ASYNC,
    SET_SEARCH_AGENT_BOOKS,
    SET_AGENT_BOOKS_SEARCH_TEXT,
    FETCH_AGENT_BOOKS_PAGE_ACTION,
    APPEND_AGENT_BOOKS_PAGE,
    SEARCH_AGENT_BOOKS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_AGENT_BOOK_ACTION,
    ADD_AGENT_BOOK_ACTION,
    UPDATE_AGENT_BOOK,
    ADD_NEW_AGENT_BOOK,
    DELETE_AGENT_BOOK_ACTION,
    DELETE_AGENT_BOOK,
    SET_AGENT_BOOKS_CURRENT_PAGE,
    SET_TOTAL_PAGES,
    GET_AGENT_BOOK_BY_ID_ACTION,
    SET_AGENT_BOOKS_UPLOAD_ABORT_TOKEN,
    SET_AGENT_BOOKS_UPLOAD_PROGRESS,
    CHANGE_AGENT_BOOKS_PER_PAGE_ACTION,
} from '@/store/actions/agentBooks';
import {
    addAgentBook,
    deleteAgentBook,
    editAgentBook,
    fetchAgentBookById, fetchAgentBooksPage,
} from '@/api/agentBooks/agentBooks';
import { getPerPage, searchAgentBook, setPerPage } from '@/utils/utils';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        agentBooks: [],

        // Filtering
        searchText: null,
        searchAgentBooks: null,
        searchAsync: false,
        selectedAgentBookId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        uploadProgress: null,
        abortToken: null,
        async: false,
    }),
    mutations: {
        [SET_AGENT_BOOKS](state, agentBooks) {
            state.agentBooks = agentBooks;
        },
        [SELECT_AGENT_BOOK](state, agentBookId) {
            state.selectedAgentBookId = agentBookId;
        },
        [SET_AGENT_BOOKS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_AGENT_BOOKS](state, searchAgentBooks) {
            state.searchAgentBooks = searchAgentBooks;
        },
        [SET_AGENT_BOOKS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_AGENT_BOOKS_PAGE](state, agentBooks) {
            if (state.agentBooks !== null) {
                state.agentBooks = state.agentBooks.concat(agentBooks);
            } else {
                state.agentBooks = [...agentBooks];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_AGENT_BOOK](state, { id, ...data }) {
            for (let agentBook of state.agentBooks) {
                if (agentBook.id === id) {
                    Object.assign(agentBook, data);
                    return;
                }
            }
        },
        [ADD_NEW_AGENT_BOOK](state, newAgentBook) {
            const index = state.agentBooks.findIndex(agentBook => agentBook.id === newAgentBook.id);

            if (index === -1) {
                state.agentBooks.unshift(newAgentBook);
            }
        },
        [DELETE_AGENT_BOOK](state, agentBookId) {
            if (state.agentBooks !== null) {
                state.agentBooks = state.agentBooks.filter(agentBook => agentBook.id !== agentBookId);
            }
        },
        [SET_AGENT_BOOKS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
        [SET_AGENT_BOOKS_UPLOAD_ABORT_TOKEN](state, token) {
            state.abortToken = token;
        },
        [SET_AGENT_BOOKS_UPLOAD_PROGRESS](state, progress) {
            state.uploadProgress = progress;
        }
    },
    actions: {
        async [FETCH_ALL_AGENT_BOOKS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_AGENT_BOOKS_ASYNC, true);
            commit(SET_AGENT_BOOKS, []);
            commit(SET_SEARCH_AGENT_BOOKS, null);
            commit(SET_AGENT_BOOKS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_AGENT_BOOKS_PAGE_ACTION, { page });
            }

            commit(SET_AGENT_BOOKS_CURRENT_PAGE, 1);
            commit(SET_TOTAL_PAGES, page);

            commit(SET_AGENT_BOOKS_ASYNC, false);
        },
        async [FETCH_AGENT_BOOKS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': agentBooks,
                } = await fetchAgentBooksPage(page, state.perPage);
                if (Array.isArray(agentBooks)) {
                    commit(APPEND_AGENT_BOOKS_PAGE, agentBooks);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch agentBooks Page', ex);
            }
        },
        [SEARCH_AGENT_BOOKS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_AGENT_BOOKS_SEARCH_TEXT, null);
                commit(SET_SEARCH_AGENT_BOOKS, null);
            } else {
                commit(SET_AGENT_BOOKS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_AGENT_BOOKS, state.agentBooks.filter(agentBook => searchAgentBook(agentBook, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_AGENT_BOOK_ACTION]({ commit }, { id, ...data }) {
            commit(SET_AGENT_BOOKS_ASYNC, true);
            try {
                const updatedAgentBook = await editAgentBook(id, data);
                commit(UPDATE_AGENT_BOOK, updatedAgentBook);
            } finally {
                commit(SET_AGENT_BOOKS_ASYNC, false);
            }
        },
        async [ADD_AGENT_BOOK_ACTION]({ state, commit }, data) {
            if (state.async) return;

            commit(SET_AGENT_BOOKS_ASYNC, true);
            try {
                const newAgentBook = await addAgentBook(data);
                commit(ADD_NEW_AGENT_BOOK, newAgentBook);
                return newAgentBook;
            } finally {
                commit(SET_AGENT_BOOKS_ASYNC, false);
            }
        },
        async [DELETE_AGENT_BOOK_ACTION]({ commit }, agentBookId) {
            commit(SET_AGENT_BOOKS_ASYNC, true);
            try {
                await deleteAgentBook(agentBookId);
                commit(DELETE_AGENT_BOOK, agentBookId);
            } finally {
                commit(SET_AGENT_BOOKS_ASYNC, false);
            }
        },
        async [GET_AGENT_BOOK_BY_ID_ACTION]({ state, commit }, { agentBookId }) {
            if (state.async) return;

            commit(SET_AGENT_BOOKS_ASYNC, true);
            const agentBook = await fetchAgentBookById(agentBookId).catch(() => commit(SET_AGENT_BOOKS_ASYNC, false));

            if (agentBook) {
                state.agentBooks = [agentBook, ...state.agentBooks];
                commit(SELECT_AGENT_BOOK, agentBook.id);
            }

            commit(SET_AGENT_BOOKS_ASYNC, false);
        },
        async [CHANGE_AGENT_BOOKS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);
            commit(UPDATE_PER_PAGE);
            dispatch(FETCH_ALL_AGENT_BOOKS_ACTION);
        },
    },
    getters: {
        selectedAgentBook(state) {
            return state.agentBooks.find(b => b.id === state.selectedAgentBookId);
        },
        agentBookOptions(state) {
            return state.agentBooks.map(m => ({ label: m.name, value: m.id }));
        },
        allAgentBooks(state) {
            if (state.searchAgentBooks !== null) {
                return state.searchAgentBooks;
            } else {
                return state.agentBooks;
            }
        },
        paginatedAgentBooks(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allAgentBooks.slice(beginIndex, endIndex);
        },
        totalAgentBooksPages(state) {
            return state.totalPages;
        },
        agentBooksAsync(state) {
            return state.async;
        },
    },
};
