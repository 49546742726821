<template>
    <div class="book-status-dot" :style="{background: statusColor}"/>
</template>

<script>
import { BOOK_STATUS_COLORS } from "@/utils/consts";

export default {
    name: "BookStatusDot",
    props: {
        status: String
    },
    computed: {
        statusColor() {
            return BOOK_STATUS_COLORS[this.status];
        }
    }
}
</script>

<style scoped lang="scss">
.book-status-dot {
    height: 7px;
    width: 7px;
    border-radius: 64px;
    margin-right: 8px;
}

</style>