import { apiCall, owned_books, chapter_transactions } from '@/api/apiCall';

export async function fetchChapterTransactionsPage(page = 1, perPage = 10) {
    let url = new URL(chapter_transactions);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editChapterTransaction(chapterTransactionId, data) {
    return apiCall({
        url: chapter_transactions + `/${chapterTransactionId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addChapterTransaction({ bookId, discountId }) {
    return apiCall({
        url: chapter_transactions,
        method: 'POST',
        jsonData: {
            book: bookId,
            discount: discountId
        },
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteChapterTransaction(chapterTransactionId) {
    return apiCall({
        url: chapter_transactions + `/${chapterTransactionId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchChapterTransactionById(chapterTransactionId) {
    return apiCall({
        url: `${chapter_transactions}/${chapterTransactionId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function fetchChapterTransactionsAnalytics(query) {

    const url = `${owned_books}/analytics${query ? '?' + query : ''}`

    return apiCall({
        url: url,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
