import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'vue-moment';
import VueElementLoading from 'vue-element-loading';
import VueEllipseProgress from 'vue-ellipse-progress';
import UUID from 'vue-uuid';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';


import './utils/filters';

import 'vue-select/src/scss/vue-select.scss';
import '@/assets/fonts/icomoon/style.css';
import '@/assets/scss/main.scss';

import en from '../lang/en.json';
import bs from '../lang/bs.json';

import VueI18n from 'vue-i18n';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { IS_DEV } from '@/utils/consts';
import VueCurrencyInput from 'vue-currency-input'

Vue.config.productionTip = false;

Vue.use(VueCurrencyInput)
Vue.use(moment);
Vue.use(VueEllipseProgress);
Vue.component('VueElementLoading', VueElementLoading);
Vue.use(UUID);
Vue.use(VueSweetalert2);

const language = Object.assign({ en, bs });

Vue.use(VueI18n);

if (!IS_DEV) {
    Sentry.init({
        Vue,
        dsn: 'https://fe91bfebb9d741d290667db16f475552@o229960.ingest.sentry.io/5666863',
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

const i18n = new VueI18n({
    locale: 'bs', // set locale
    messages: language, // set locale messages
});

new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
}).$mount('#app');
