<template>
    <div class="labeled-input">

        <span class="label">{{ label }}</span>

        <i v-if="required" class="required-simbol"/>

        <span class="error-message" v-if="error">
            {{ error }}
        </span>

        <slot/>
    </div>
</template>

<script>
export default {
    name: 'InputLabel',
    props: {
        label: String,
        required: {
            type: Boolean,
            default: false
        },
        error: String
    }
};
</script>

<style lang="scss" scoped>

.labeled-input {
    margin-right: 20px;
    margin-bottom: 30px;

    .label {
        font-size: 16px;
        font-weight: 600;
        color: #A0A0B1;
    }

    .required-simbol:after {
        content: " *";
        color: indianred;
    }

    .error-message {
        margin-left: 5px;
        color: indianred;
        font-size: 0.875rem;
        font-weight: normal;
        text-transform: none;
        letter-spacing: 0;
    }

    .input-container, .select-container {
        margin-top: 10px;
        background: $widgetBg;
    }
}

</style>
