<template>
    <div v-click-outside="hide" :class="['tvornica-snova', 'dropdown-menu-container']">
        <span class="handle-container" @click="onHandleClick">
            <slot name="handle"/>
        </span>
        <div @click="hide"
             ref="menuAnchor"
             :style="{top: baseY + 'px', left: baseX + 'px'}"
             :class="['dropdown-menu', open && 'open']">
            <slot/>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
    name: 'DropdownMenu',
    directives: {
        ClickOutside,
    },
    props: {
        anchor: String,
    },
    data() {
        return {
            open: false,
            baseX: -100,
            baseY: -100,
            dropdownWidth: 0,
        };
    },
    mounted() {
        // prevent click outside event with popupItem.
        this.popupItem = this.$el;

        const menuRect = this.$refs.menuAnchor.getBoundingClientRect();
        this.dropdownWidth = menuRect.width;

        window.addEventListener("scroll", this.hide);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.hide);
    },
    methods: {
        hide() {
            if (!this.open) return;

            this.open = false;
        },
        onHandleClick(e) {
            e.preventDefault();
            e.stopPropagation();

            let clickRect = e.target.getBoundingClientRect();
            let xAdjust = 0;

            if (this.anchor) {
                if (this.anchor === 'right') {
                    xAdjust = -this.dropdownWidth + clickRect.width;
                }
            }

            this.baseX = clickRect.x + xAdjust;
            this.baseY = clickRect.y + clickRect.height;
            this.open = !this.open;
        },
    },
};
</script>

<style scoped lang="scss">
div.tvornica-snova.dropdown-menu-container {
    position: relative;

    .dropdown-menu {
        z-index: 100000;
        transition: 150ms transform ease-in-out;
        transform: scaleY(0);
        transform-origin: top center;
        overflow: hidden;
        user-select: none;
        position: fixed;
        background: #ffffff;
        border: 1px solid transparent;

        &.open {
            border: 1px solid #dfdfdf;
            transform: scaleY(1);
            max-height: unset;
        }
    }

    .handle-container {
        cursor: pointer;
    }
}
</style>
