<template>
    <div class="manage-user">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="user-title">Novi Korisnik</div>
        <div class="user-status">
        </div>
        <container class="body-container">
            <input-label :required="true" :error="errors.first_name" label="Ime">
                <input-field v-model="form.first_name" placeholder="Ime"/>
            </input-label>

            <input-label :required="true" :error="errors.last_name" label="Prezime">
                <input-field v-model="form.last_name" placeholder="Prezime"/>
            </input-label>

            <input-label label="Telefon">
                <input-field v-model="form.phone" placeholder="Telefon"/>
            </input-label>

            <input-label :required="true" :error="errors.email" label="Email">
                <input-field v-model="form.email" placeholder="Email"/>
            </input-label>

            <input-label label="Password">
                <input-field v-model="form.password" type="password" autocomplete="new-password"/>
            </input-label>

            <input-label label="Uloga">
                <select-field v-model="form.user_role" :options="roleOptions"/>
            </input-label>

            <template v-if="!publishersAsync">
                <input-label label="Izdavač" v-if="form.user_role === 'ROLE_PUBLISHER_ADMIN'">
                    <select-field v-model="form.publisher" :options="publisherOptions"/>
                </input-label>
            </template>
            <Spinner v-else/>

            <TSSwitch v-model="form.enabled">
                <span style="margin-right: 10px">Korisnik Omogućen</span>
            </TSSwitch>

            <TSSwitch v-model="form.is_demo_account">
                <span style="margin-right: 10px">Demo Account</span>
            </TSSwitch>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <Button class="save-button" @click="submit" :loading="async">
                Snimi
            </Button>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference } from '@/utils/utils';
import {
    ADD_USER_ACTION,
    CHANGE_USER_PASSWORD_ACTION,
    EDIT_USER_ACTION,
    GET_USER_BY_ID_ACTION,
    SELECT_USER,
} from '@/store/actions/users';
import { cloneDeep } from 'lodash';
import TSSwitch from '@/components/Ui/TSSwitch/TSSwitch';
import SelectField from '@/components/Ui/SelectField/SelectField';
import Spinner from '@/components/Ui/Spinner/Spinner';
import { FETCH_ALL_PUBLISHERS_ACTION } from '@/store/actions/publishers';

const defaultForm = {
    name: '',
    address: '',
    phone: '',
    website: '',
    email: '',
    password: '',
};

const transformFormFields = (form) => {

    if (form.roles) {
        form.user_role = form?.roles?.find(r => r !== 'ROLE_USER') ?? 'ROLE_USER';
    }

    if (form.publisher) {
        form.publisher = form.publisher.id;
    }

    return form;
};

export default {
    name: 'ManageUser',
    components: { Spinner, SelectField, TSSwitch, Button, InputLabel, InputField, Container },
    data() {
        return {
            form: cloneDeep(defaultForm),
            errors: {
                general: [],
            },
        };
    },
    methods: {
        ...mapActions({
            addUser: ADD_USER_ACTION,
            editUser: EDIT_USER_ACTION,
            changeUserPasswordAction: CHANGE_USER_PASSWORD_ACTION,
            getUserById: GET_USER_BY_ID_ACTION,
            fetchAllPublishers: FETCH_ALL_PUBLISHERS_ACTION,
        }),
        ...mapMutations({
            selectUser: SELECT_USER,
        }),
        setData() {
            if (this.user) {
                this.form = transformFormFields(cloneDeep(this.user));
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.first_name) {
                errors.first_name = 'Ime je obavezno';
            }

            if (!this.form.last_name) {
                errors.last_name = 'Prezime je obavezno';
            }

            if (!this.form.email) {
                errors.email = 'Email je obavezan';
            }

            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        async submit() {
            if (!this.validateFields()) return;

            if (this.user) {

                const changed = difference(this.form, this.user);

                if (changed.password !== undefined) {
                    await this.changeUserPasswordAction({ userId: this.form.id, password: changed.password });
                    delete changed.password;
                }

                if (changed.roles) {
                    delete changed.roles;
                }

                try {
                    await this.editUser({ id: this.user.id, data: changed });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing user');
                }
            } else {
                //adding
                try {
                    await this.addUser({ ...difference(this.form, defaultForm) }).then(() => this.$router.back());
                } catch (ex) {
                    this.errors.general.push('Error adding user');
                    console.error('error adding user');
                }
            }
        },
    },
    async beforeMount() {
        const { params } = this.$route;

        if (!this.user) {
            if (params.id) {
                await this.getUserById({ userId: params.id });
            }
        }

        if (!this.publishersAsync) {
            await this.fetchAllPublishers();
        }

        this.setData();
    },
    beforeDestroy() {
        this.selectUser(null);
    },
    computed: {
        ...mapGetters({
            user: 'selectedUser',
            roleOptions: 'roleOptions',
            allPublishers: 'allPublishers',
        }),
        async() {
            return this.$store.state.users.async;
        },
        publishersAsync() {
            return this.$store.state.publishers.async;
        },
        publisherOptions() {
            return this.allPublishers.map(publisher => ({
                value: publisher.id,
                label: publisher.name,
            }));
        },
    },
};
</script>

<style lang="scss">
.manage-user {
    .body-container {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .spinner-container {
            width: 50%;
        }

        .labeled-input {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .switch-container {
            margin: 25px 0;
        }

        .save-button {
            margin-left: auto;
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }
    }
}
</style>
