<template>
    <div class="manage-author">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="author-title">Novi Autor</div>
        <div class="author-status">
        </div>
        <container class="body-container">
            <TSRow>
                <TSCol :span="12">
                    <input-label label="Ime Autora">
                        <input-field v-model="name" placeholder="Ime"/>
                    </input-label>
                </TSCol>
                <TSCol/>
            </TSRow>

            <Button class="save-button" @click="submit" :loading="async">
                Snimi
            </Button>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference, mergeName, splitName } from '@/utils/utils';
import { ADD_AUTHOR_ACTION, EDIT_AUTHOR_ACTION, GET_AUTHOR_BY_ID_ACTION, SELECT_AUTHOR } from '@/store/actions/authors';
import TSRow from "@/components/Ui/Layout/TSRow";
import TSCol from "@/components/Ui/Layout/TSCol";
import Spinner from "@/components/Ui/Spinner/Spinner";

export default {
    name: 'ManageAuthor',
    components: { Spinner, TSCol, TSRow, Button, InputLabel, InputField, Container },
    data() {
        return {
            name: mergeName(this.author) || '',
        };
    },
    methods: {
        ...mapActions({
            addAuthor: ADD_AUTHOR_ACTION,
            editAuthor: EDIT_AUTHOR_ACTION,
            getAuthorById: GET_AUTHOR_BY_ID_ACTION,
        }),
        ...mapMutations({
            selectAuthor: SELECT_AUTHOR,
        }),
        setData() {
            this.name = mergeName(this.author) || '';
        },
        async submit() {
            if (this.author) {
                //editing
                let name = splitName(this.name);

                let data = { ...name };
                try {
                    await this.editAuthor({ id: this.author.id, ...difference(data, this.author) });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing author');
                }
            } else {
                //adding
                if (this.name) {
                    let name = splitName(this.name);
                    this.addAuthor({ ...name }).then(() => {
                        this.$router.back();
                    })
                }
            }
        },
    },
    async beforeMount() {

        const { params } = this.$route;

        if (!this.author) {

            if (params.id) {
                await this.getAuthorById({ authorId: params.id });
            }
        }

        this.setData();
    },
    beforeDestroy() {
        this.selectAuthor(null);
    },
    computed: {
        ...mapGetters({
            author: 'selectedAuthor',
        }),
        async() {
            return this.$store.state.authors.async;
        },
    },
};
</script>

<style lang="scss">
.manage-author {
    .body-container {
        margin-top: 16px;

        .container {
            padding: 16px;
        }

        .save-button {
            margin-left: auto;
        }
    }
}
</style>
