<template>
    <div class="manage-category">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="category-title">Nova Kategorija</div>
        <div class="category-status">
        </div>
        <container class="body-container">
            <input-label :required="true" :error="errors.name" label="Naziv ">
                <input-field v-model="form.name" placeholder="Naziv"/>
            </input-label>
            <input-label label="Opis">
                <input-field v-model="form.description" placeholder="Opis"/>
            </input-label>

            <input-label class="image-container" label="Slika" v-if="imageUrl">
                <img :src="imageUrl" class="category-image"/>
            </input-label>

            <input-label label="Zamijeni Sliku Kategorije">
                <uploader :progress="uploadProgress"
                          :async="uploadProgress !== null"
                          @cancel="cancelImageUpload"
                          v-model="images"/>
            </input-label>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <Button class="save-button" @click="submit" :loading="async">
                Snimi
            </Button>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference } from '@/utils/utils';
import {
    ADD_CATEGORY_ACTION,
    EDIT_CATEGORY_ACTION,
    GET_CATEGORY_BY_ID_ACTION,
    CHANGE_CATEGORY_IMAGE_ACTION,
    SELECT_CATEGORY,
} from '@/store/actions/categories';
import { cloneDeep } from 'lodash';
import Uploader from '@/components/Ui/Uploader/Uploader';
import Spinner from '@/components/Ui/Spinner/Spinner';
import { storage_url } from '@/api/apiCall';

const defaultForm = {
    name: '',
    description: '',
};

export default {
    name: 'ManageCategory',
    components: { Spinner, Uploader, Button, InputLabel, InputField, Container },
    data() {
        return {
            form: cloneDeep(defaultForm),
            images: [],
            errors: {
                general: [],
            },
        };
    },
    methods: {
        ...mapActions({
            addCategory: ADD_CATEGORY_ACTION,
            editCategory: EDIT_CATEGORY_ACTION,
            getCategoryById: GET_CATEGORY_BY_ID_ACTION,
            changeCategoryImage: CHANGE_CATEGORY_IMAGE_ACTION,
        }),
        ...mapMutations({
            selectCategory: SELECT_CATEGORY,
        }),
        setData() {
            if (this.category) {
                this.form = cloneDeep(this.category);
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.name) {
                errors.name = 'Naziv je obavezan';
            }

            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        cancelImageUpload() {
            this.images = [];
            this.abortToken.abort();
        },
        async submit() {
            if (!this.validateFields()) return;

            delete this.form.discounts;

            if (this.category) {
                try {
                    await this.editCategory({ id: this.category.id, ...difference(this.form, this.category) });
                    // check didimage change ?
                    await this.changeCategoryImage({ categoryId: this.category.id, images: this.images });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing category');
                }
            } else {
                //adding
                try {
                    const r = await this.addCategory({ ...difference(this.form, defaultForm) });
                    try {
                        await this.changeCategoryImage({ categoryId: r.id, images: this.images });
                        this.$router.back();
                    } catch (ex) {
                        this.errors.general.push('Error uploading image');
                    }
                } catch (ex) {
                    this.errors.general.push('Error adding category');
                    console.error('error adding category');
                }
            }
        },
    },
    async beforeMount() {
        const { params } = this.$route;

        if (!this.category) {
            if (params.id) {
                await this.getCategoryById({ categoryId: params.id });
            }
        }

        this.setData();
    },
    beforeDestroy() {
        this.selectCategory(null);
    },

    watch: {
        images: function (nextVal) {
            console.log(nextVal);
        },
    },
    computed: {
        ...mapGetters({
            category: 'selectedCategory',
        }),
        uploadProgress() {
            return this.$store.state.categories.uploadProgress;
        },
        abortToken() {
            return this.$store.state.categories.abortToken;
        },
        async() {
            return this.$store.state.categories.async;
        },
        imageUrl() {
            if (this.form?.image?.content_url) {
                return `${storage_url}${this.form.image?.content_url}`;
            }

            return null;
        },
    },
};
</script>

<style lang="scss">
.manage-category {
    .body-container {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .image-container {
            display: flex;
            flex-direction: column;

            .category-image {
                margin-top: 8px;
                height: 58px;
                width: 58px;
                border-radius: 8px;
            }
        }

        .labeled-input {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .save-button {
            margin-left: auto;
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }
    }
}
</style>
