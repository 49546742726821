import { apiCall, publishers } from '@/api/apiCall';

export async function fetchPublishersPage(page = 1, perPage = 10) {
    let url = new URL(publishers);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editPublisher(publisherId, data) {
    return apiCall({
        url: publishers + `/${publisherId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function unlockAllPublisherBooks(publisherId) {
    return apiCall({
        url: publishers + `/${publisherId}/unlock_all_books`,
        method: 'POST',
        jsonData: {},
    })
        .then(r => {
            return r.json();
        });
}
export async function addPublisher(data) {
    return apiCall({
        url: publishers,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deletePublisher(publisherId) {
    return apiCall({
        url: publishers + `/${publisherId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchPublisherById(publisherId) {
    return apiCall({
        url: `${publishers}/${publisherId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
