// actions
export const FETCH_ALL_AGENT_BOOKS_ACTION = 'FETCH_ALL_AGENT_BOOKS_ACTION';
export const FETCH_AGENT_BOOKS_PAGE_ACTION = 'FETCH_AGENT_BOOKS_PAGE_ACTION';
export const SEARCH_AGENT_BOOKS_ACTION = 'SEARCH_AGENT_BOOKS_ACTION';
export const ADD_AGENT_BOOK_ACTION = 'ADD_AGENT_BOOK_ACTION';
export const EDIT_AGENT_BOOK_ACTION = 'EDIT_AGENT_BOOK_ACTION';
export const DELETE_AGENT_BOOK_ACTION = 'DELETE_AGENT_BOOK_ACTION';
export const CHANGE_AGENT_BOOK_IMAGE_ACTION = 'CHANGE_AGENT_BOOK_IMAGE_ACTION';
export const GET_AGENT_BOOK_BY_ID_ACTION = 'GET_AGENT_BOOK_BY_ID_ACTION';
export const CHANGE_AGENT_BOOKS_PER_PAGE_ACTION = 'CHANGE_AGENT_BOOKS_PER_PAGE_ACTION';

// mutations
export const SET_AGENT_BOOKS = 'SET_AGENT_BOOKS';
export const SELECT_AGENT_BOOK = 'SELECT_AGENT_BOOK';
export const SET_AGENT_BOOKS_ASYNC = 'SET_AGENT_BOOKS_ASYNC';
export const SET_SEARCH_AGENT_BOOKS = 'SET_SEARCH_AGENT_BOOKS';
export const SET_AGENT_BOOKS_SEARCH_TEXT = 'SET_AGENT_BOOKS_SEARCH_TEXT';
export const APPEND_AGENT_BOOKS_PAGE = 'APPEND_AGENT_BOOKS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_AGENT_BOOK = 'UPDATE_AGENT_BOOK';
export const ADD_NEW_AGENT_BOOK = 'ADD_NEW_AGENT_BOOK';
export const DELETE_AGENT_BOOK = 'DELETE_AGENT_BOOK';
export const SET_AGENT_BOOKS_CURRENT_PAGE = 'SET_AGENT_BOOKS_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_AGENT_BOOKS_UPLOAD_ABORT_TOKEN = 'SET_AGENT_BOOKS_UPLOAD_ABORT_TOKEN';
export const SET_AGENT_BOOKS_UPLOAD_PROGRESS = 'SET_AGENT_BOOKS_UPLOAD_PROGRESS';
