<template>
    <div class="internal-widgets">
        hello books
        <Button @click="clicky" style="margin: 16px">
            Dodaj Knjigu
        </Button>
        <Button :inverted="true" @click="clicky" style="margin: 16px">
            Dodaj Knjigu
        </Button>
        <Button :flat="true" @click="clicky" style="margin: 16px">
            Dodaj Knjigu
        </Button>
        <Button :flat="true" :inverted="true" @click="clicky" style="margin: 16px">
            Dodaj Knjigu
        </Button>
        <div style="padding: 16px;">
            <IconButton icon="icon-search" @click="clicky"/>
        </div>

        <Container style="padding: 16px">
            <InputField placeholder="Komentar" v-model="data"/>
        </Container>
        <Container style="padding: 16px; width: 100%;">
            <InputField placeholder="Search" v-model="data2" icon="icon-search"/>
        </Container>
        <Container style="padding: 16px; width: 100%;">
            <select-field v-model="singleSelect" :options="selectOptions"/>
        </Container>
        <Container style="padding: 16px; width: 100%;">
            <select-field v-model="multiSelect" :options="selectOptions" :multiple="true" name="Izdavač"/>
        </Container>
        <div style="padding: 16px;">
            <Checkbox :label="'test'" v-model="isChecked"/>
        </div>
        <TSSwitch @input="logSwitch" v-model="switchPos">
            Obavjesti korisnike
        </TSSwitch>
        <Container style="padding: 16px;">
            <textarea-field placeholder="Komentar" v-model="textareaData"/>
        </Container>
        <Spinner/>

        <div style="margin: 24px">
            <dropdown-menu anchor="left">
                <template v-slot:handle>
                    <i class="icon-down"/>
                </template>

                <dropdown-menu-item @click="itemClick">
                    Hello
                </dropdown-menu-item>
                <dropdown-menu-item>
                    Hello 123
                </dropdown-menu-item>
                <dropdown-menu-item>
                    Hello 324
                </dropdown-menu-item>
            </dropdown-menu>
        </div>

        <uploader/>

        <div style="padding: 16px; width: 100%">
            <custom-tabs>
                <tab name="First" :selected="true">
                    <h3>First tab content</h3>
                </tab>
                <tab name="Second">
                    <h3>Second tab content</h3>
                </tab>
            </custom-tabs>
        </div>
        <div style="padding: 16px; width: 100%">
            <CustomTable :select="true" :fields="tableFields" :data="tableData" @selectAllChanged="selectAllChanged"
                         @selectItem="onTableSelectItem">
                <template v-slot:status="props">
                    <div
                        style="height: 7px; width: 7px; border-radius: 64px; background: #26A53F; margin-right: 8px;">

                    </div>
                    <span>{{ itemStatus(props.item) }}</span>
                </template>
                <template v-slot:date="props">
                    <span>{{ props.item.date | moment('DD.MM.YYYY') }}</span>
                </template>
                <template v-slot:actions="">
                    <div>
                        <i style="font-size: 15px" class="icon icon-menudots"/>
                    </div>
                </template>
            </CustomTable>
        </div>

    </div>
</template>

<script>
import Button from '@/components/Ui/Button/Button.vue';
import InputField from '@/components/Ui/InputField/InputField.vue';
import IconButton from '@/components/Ui/IconButton/IconButton.vue';
import SelectField from '@/components/Ui/SelectField/SelectField.vue';
import Checkbox from '@/components/Ui/Checkbox/Checkbox.vue';
import TextareaField from '@/components/Ui/TextareaField/TextareaField';
import CustomTable from '@/components/Ui/CustomTable/CustomTable';
import CustomTabs from '@/components/Ui/CustomTabs/CustomTabs';
import Tab from '@/components/Ui/CustomTabs/Tab';
import Container from '@/components/Ui/Container/Container';
import Spinner from '@/components/Ui/Spinner/Spinner';
import TSSwitch from '@/components/Ui/TSSwitch/TSSwitch';
import DropdownMenu from '@/components/Ui/DropdownMenu/DropdownMenu';
import Uploader from '@/components/Ui/Uploader/Uploader';
import DropdownMenuItem from '@/components/Ui/DropdownMenu/DropdownMenuItem';

const STATUS = {
    PUBLISHED: 'published',
};

export default {
    name: 'InternalWidgets',
    components: {
        DropdownMenuItem,
        Uploader,
        DropdownMenu,
        TSSwitch,
        Spinner,
        Container,
        Tab,
        CustomTabs,
        CustomTable,
        TextareaField,
        SelectField,
        IconButton,
        InputField,
        Button,
        Checkbox,
    },
    data() {
        return {
            data: '',
            data2: '',
            select1: '',
            textareaData: '',
            switchPos: true,
            isChecked: false,
            selectOptions: [{ label: 'Cat', value: 1 }, { label: 'Dog', value: 2 }],
            singleSelect: null,
            multiSelect: [],
            tableFields: [{
                name: 'code',
                title: 'Šifra',
                width: '8%',
                lightText: true,
            }, {
                name: 'name',
                title: 'Naziv Knjige',
            }, {
                name: 'category',
                title: 'Kategorija',
                width: '15%',
                lightText: true,
            }, {
                name: 'price',
                title: 'Cijena',
                width: '8%',
            }, {
                name: 'date',
                title: 'Datum',
                lightText: true,
                slot: 'date',
                width: '8%',
            }, {
                name: 'status',
                title: 'Status',
                slot: 'status',
                width: '10%',
            }, {
                name: 'actions',
                title: '',
                width: '3%',
                lightText: true,
                slot: 'actions',
            }],
        };
    },
    computed: {
        tableData() {
            return [{
                id: 1,
                code: '#12345',
                name: 'Samo ti, Alfa',
                category: 'Romantika',
                price: 300,
                date: '2021-02-23T08:33:11Z',
                status: STATUS.PUBLISHED,
            }, {
                id: 2,
                code: '#12345',
                name: 'Samo ti, Alfa',
                category: 'Romantika',
                price: 300,
                date: '2021-02-23T08:33:11Z',
                status: STATUS.PUBLISHED,
            }, {
                id: 3,
                code: '#12345',
                name: 'Samo ti, Alfa',
                category: 'Romantika',
                price: 300,
                date: '2021-02-23T08:33:11Z',
                status: STATUS.PUBLISHED,
            }, {
                id: 4,
                code: '#12345',
                name: 'Samo ti, Alfa',
                category: 'Romantika',
                price: 300,
                date: '2021-02-23T08:33:11Z',
                status: STATUS.PUBLISHED,
            }];
        },
    },
    methods: {
        itemClick() {
            // if you dont want to auto close dropdown on click
            // e.stopPropagation();
            console.log("big brain");
        },
        logSwitch() {
            console.log(this.switchPos);
        },
        selectAllChanged(selectAll) {
            console.log(selectAll);
        },
        onTableSelectItem(itemId) {
            console.log(itemId);
        },
        clicky() {
            console.log('hello');
            console.log(this.data);
        },
        itemStatus(item) {
            switch (item.status) {
                case STATUS.PUBLISHED:
                    return 'Objavljeno';
            }
        },
    },
};
</script>

<style>
.internal-widgets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
</style>
