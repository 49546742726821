// import { doLogin, doMe } from '../../api/login/login';

import {
    DO_ME_ACTION,
    PERFORM_LOGIN_ACTION,
    PERFORM_LOGOUT_ACTION,
    SET_LOGIN_ASYNC,
    SET_LOGIN_ERRORS,
    CLEAR_STATE,
    SET_SIGN_UP_ERRORS,
    SET_USER,
} from '@/store/actions/login';
import router from '../../router';
import { STORAGE_KEYS } from '@/utils/consts';
import {
    doLogin,
    doMe,
} from '@/api/login/login';
import {
    getAccessLevel,
    hasAccessToAdminPanel,
    isPublisherAdmin,
    isSalesAgent,
    isSuperAdmin,
    showSwalToast
} from '@/utils/utils';
import { RouteLink } from "@/store/modules/menu";

const getInitialState = () => ({
    user: null,
    async: false,
    loginErrors: {},
    signupErrors: {},
});

export default {
    state: getInitialState(),
    mutations: {
        [SET_LOGIN_ASYNC](state, async) {
            state.async = async;
        },
        [SET_LOGIN_ERRORS](state, errors) {
            state.loginErrors = errors;
        },
        [SET_SIGN_UP_ERRORS](state, errors) {
            state.signupErrors = errors;
        },
        [SET_USER](state, user) {
            state.user = user;
        },
        [CLEAR_STATE](state) {
            Object.assign(state, getInitialState());
        },
    },
    actions: {
        async [PERFORM_LOGIN_ACTION]({ commit, dispatch, getters }, { email, password }) {
            const errors = {};

            if (!email) {
                errors['email'] = 'Please enter a email';
            }

            if (!password) {
                errors['password'] = 'Please enter a password';
            }

            if (email && !email.includes('@') || email.length === 0) {
                errors['email'] = 'Please enter a valid email.';
            }

            if (password && password.length < 8) {
                errors['password'] = 'Please enter a valid password.';
            }

            if (Object.keys(errors).length > 0) {
                commit(SET_LOGIN_ERRORS, errors);
                return;
            }

            commit(SET_LOGIN_ASYNC, true);

            try {
                await doLogin(email, password);

                const me = await doMe();

                // CHECK IF HAS ACCESS TO PANEL
                if (!hasAccessToAdminPanel(me)) {
                    showSwalToast('Forbidden');
                    return dispatch(PERFORM_LOGOUT_ACTION);
                }

                commit(SET_LOGIN_ERRORS, {});

                await commit(SET_USER, me);

                await router.push(getters.isSuperAdmin ? RouteLink.Books : RouteLink.Analytics);
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            } finally {
                commit(SET_LOGIN_ASYNC, false);
            }
        },

        async [DO_ME_ACTION]({ commit, dispatch }) {
            commit(SET_LOGIN_ASYNC, true);

            try {
                const me = await doMe();

                if (!hasAccessToAdminPanel(me)) {
                    showSwalToast('Forbidden');
                    return dispatch(PERFORM_LOGOUT_ACTION);
                }

                commit(SET_LOGIN_ERRORS, {});
                commit(SET_USER, me);
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    console.log(ex);
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            }

            commit(SET_LOGIN_ASYNC, false);
        },
        [PERFORM_LOGOUT_ACTION]({ commit }) {
            commit(CLEAR_STATE);
            localStorage.removeItem(STORAGE_KEYS.TOKEN);
            localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
            router.push('/login');
        },
    },
    getters: {
        userAccessLevel(state) {
            return getAccessLevel(state.user);
        },
        isSuperAdmin(state) {
            return isSuperAdmin(state.user);
        },
        isPublisherAdmin(state) {
            return isPublisherAdmin(state.user);
        },
        isSalesAgent(state) {
            return isSalesAgent(state.user);
        },
        user(state) {
            return state.user;
        },
    },
};
