import {
    SELECT_TAG,
    SET_TAGS,
    FETCH_ALL_TAGS_ACTION,
    SET_TAGS_ASYNC,
    SET_SEARCH_TAGS,
    SET_TAGS_SEARCH_TEXT,
    FETCH_TAGS_PAGE_ACTION,
    APPEND_TAGS_PAGE,
    SEARCH_TAGS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_TAG_ACTION,
    ADD_TAG_ACTION,
    UPDATE_TAG,
    ADD_NEW_TAG,
    DELETE_TAG_ACTION,
    DELETE_TAG,
    SET_TAGS_CURRENT_PAGE,
    SET_TAG_TOTAL_PAGES,
    GET_TAG_BY_ID_ACTION, CHANGE_TAGS_PER_PAGE_ACTION,
} from '@/store/actions/tags';
import {
    addTag,
    deleteTag,
    editTag,
    fetchTagsPage,
} from '@/api/tags/tags';
import { getPerPage, searchTag, setPerPage } from '@/utils/utils';
import { fetchTagById } from '@/api/tags/tags';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        tags: [],

        // Filtering
        searchText: null,
        searchTags: null,
        searchAsync: false,
        selectedTagId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        async: false,
    }),
    mutations: {
        [SET_TAGS](state, Tags) {
            state.tags = Tags;
        },
        [SELECT_TAG](state, tagId) {
            state.selectedTagId = tagId;
        },
        [SET_TAGS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_TAGS](state, searchTags) {
            state.searchTags = searchTags;
        },
        [SET_TAGS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_TAGS_PAGE](state, Tags) {
            if (state.tags !== null) {
                state.tags = state.tags.concat(Tags);
            } else {
                state.tags = [...Tags];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_TAG](state, { id, ...data }) {
            for (let tag of state.tags) {
                if (tag.id === id) {
                    Object.assign(tag, data);
                    return;
                }
            }
        },
        [ADD_NEW_TAG](state, newtag) {
            const index = state.tags.findIndex(tag => tag.id === newtag.id);

            if (index === -1) {
                state.tags.unshift(newtag);
            }
        },
        [DELETE_TAG](state, tagId) {
            if (state.tags !== null) {
                state.tags = state.tags.filter(tag => tag.id !== tagId);
            }
        },
        [SET_TAGS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TAG_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
    },
    actions: {
        async [FETCH_ALL_TAGS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_TAGS_ASYNC, true);
            commit(SET_TAGS, []);
            commit(SET_SEARCH_TAGS, null);
            commit(SET_TAGS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_TAGS_PAGE_ACTION, { page });
            }

            commit(SET_TAGS_CURRENT_PAGE, 1);
            commit(SET_TAG_TOTAL_PAGES, page);

            commit(SET_TAGS_ASYNC, false);
        },
        async [FETCH_TAGS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Tags,
                } = await fetchTagsPage(page, state.perPage);
                if (Array.isArray(Tags)) {
                    commit(APPEND_TAGS_PAGE, Tags);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Tags Page', ex);
            }
        },
        [SEARCH_TAGS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_TAGS_SEARCH_TEXT, null);
                commit(SET_SEARCH_TAGS, null);
            } else {
                commit(SET_TAGS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_TAGS, state.tags.filter(tag => searchTag(tag, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_TAG_ACTION]({ commit }, { id, ...data }) {
            commit(SET_TAGS_ASYNC, true);
            try {
                const updatedTag = await editTag(id, data);
                commit(UPDATE_TAG, updatedTag);
            } finally {
                commit(SET_TAGS_ASYNC, false);
            }
        },
        async [ADD_TAG_ACTION]({ commit }, data) {
            commit(SET_TAGS_ASYNC, true);
            try {
                const newTag = await addTag(data);
                commit(ADD_NEW_TAG, newTag);
                return newTag;
            } finally {
                commit(SET_TAGS_ASYNC, false);
            }
        },
        async [DELETE_TAG_ACTION]({ commit }, tagId) {
            commit(SET_TAGS_ASYNC, true);
            try {
                await deleteTag(tagId);
                commit(DELETE_TAG, tagId);
            } finally {
                commit(SET_TAGS_ASYNC, false);
            }
        },
        async [GET_TAG_BY_ID_ACTION]({ state, commit }, { tagId }) {
            if (state.async) return;

            commit(SET_TAGS_ASYNC, true);
            const tag = await fetchTagById(tagId).catch(() => commit(SET_TAGS_ASYNC, false));

            if (tag) {
                state.tags = [tag, ...state.tags];
                commit(SELECT_TAG, tag.id);
            }

            commit(SET_TAGS_ASYNC, false);
        },
        async [CHANGE_TAGS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);

            commit(UPDATE_PER_PAGE);

            dispatch(FETCH_ALL_TAGS_ACTION);
        },
    },
    getters: {
        selectedTag(state) {
            return state.tags.find(b => b.id === state.selectedTagId);
        },
        tagOptions(state) {
            return state.tags.map(m => ({ label: m.name, value: m.id }));
        },
        allTags(state) {
            if (state.searchTags !== null) {
                return state.searchTags;
            } else {
                return state.tags;
            }
        },
        paginatedTags(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allTags.slice(beginIndex, endIndex);
        },
        totalTagsPages(state) {
            return state.totalPages;
        },
        tagsAsync(state) {
            return state.async;
        },
    },
};
