import {
    SELECT_CATEGORY,
    SET_CATEGORIES,
    FETCH_ALL_CATEGORIES_ACTION,
    SET_CATEGORIES_ASYNC,
    SET_SEARCH_CATEGORIES,
    SET_CATEGORIES_SEARCH_TEXT,
    FETCH_CATEGORIES_PAGE_ACTION,
    APPEND_CATEGORIES_PAGE,
    SEARCH_CATEGORIES_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_CATEGORY_ACTION,
    ADD_CATEGORY_ACTION,
    UPDATE_CATEGORY,
    ADD_NEW_CATEGORY,
    DELETE_CATEGORY_ACTION,
    DELETE_CATEGORY,
    SET_CATEGORIES_CURRENT_PAGE,
    SET_TOTAL_PAGES,
    CHANGE_CATEGORY_IMAGE_ACTION,
    GET_CATEGORY_BY_ID_ACTION,
    SET_CATEGORIES_UPLOAD_ABORT_TOKEN,
    SET_CATEGORIES_UPLOAD_PROGRESS,
    CHANGE_CATEGORIES_PER_PAGE_ACTION
} from '@/store/actions/categories';
import {
    addCategory,
    changeCategoryImage,
    deleteCategory,
    editCategory,
    fetchCategoriesPage,
    fetchCategoryById,
} from '@/api/categories/categories';
import { getPerPage, searchCategory, setPerPage } from '@/utils/utils';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        categories: [],

        // Filtering
        searchText: null,
        searchCategories: null,
        searchAsync: false,
        selectedCategoryId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        uploadProgress: null,
        abortToken: null,
        async: false,
    }),
    mutations: {
        [SET_CATEGORIES](state, Categories) {
            state.categories = Categories;
        },
        [SELECT_CATEGORY](state, categoryId) {
            state.selectedCategoryId = categoryId;
        },
        [SET_CATEGORIES_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_CATEGORIES](state, searchCategories) {
            state.searchCategories = searchCategories;
        },
        [SET_CATEGORIES_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_CATEGORIES_PAGE](state, Categories) {
            if (state.categories !== null) {
                state.categories = state.categories.concat(Categories);
            } else {
                state.categories = [...Categories];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_CATEGORY](state, { id, ...data }) {
            for (let category of state.categories) {
                if (category.id === id) {
                    Object.assign(category, data);
                    return;
                }
            }
        },
        [ADD_NEW_CATEGORY](state, newcategory) {
            const index = state.categories.findIndex(category => category.id === newcategory.id);

            if (index === -1) {
                state.categories.unshift(newcategory);
            }
        },
        [DELETE_CATEGORY](state, categoryId) {
            if (state.categories !== null) {
                state.categories = state.categories.filter(category => category.id !== categoryId);
            }
        },
        [SET_CATEGORIES_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
        [SET_CATEGORIES_UPLOAD_ABORT_TOKEN](state, token) {
            state.abortToken = token;
        },
        [SET_CATEGORIES_UPLOAD_PROGRESS](state, progress) {
            state.uploadProgress = progress;
        }
    },
    actions: {
        async [FETCH_ALL_CATEGORIES_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_CATEGORIES_ASYNC, true);
            commit(SET_CATEGORIES, []);
            commit(SET_SEARCH_CATEGORIES, null);
            commit(SET_CATEGORIES_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_CATEGORIES_PAGE_ACTION, { page });
            }

            commit(SET_CATEGORIES_CURRENT_PAGE, 1);
            commit(SET_TOTAL_PAGES, page);

            commit(SET_CATEGORIES_ASYNC, false);
        },
        async [FETCH_CATEGORIES_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Categories,
                } = await fetchCategoriesPage(page, state.perPage);
                if (Array.isArray(Categories)) {
                    commit(APPEND_CATEGORIES_PAGE, Categories);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Categories Page', ex);
            }
        },
        [SEARCH_CATEGORIES_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_CATEGORIES_SEARCH_TEXT, null);
                commit(SET_SEARCH_CATEGORIES, null);
            } else {
                commit(SET_CATEGORIES_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_CATEGORIES, state.categories.filter(category => searchCategory(category, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_CATEGORY_ACTION]({ commit }, { id, ...data }) {
            commit(SET_CATEGORIES_ASYNC, true);
            try {
                const updatedCategory = await editCategory(id, data);
                commit(UPDATE_CATEGORY, updatedCategory);
            } finally {
                commit(SET_CATEGORIES_ASYNC, false);
            }
        },
        async [ADD_CATEGORY_ACTION]({ state, commit }, data) {
            if (state.async) return;

            commit(SET_CATEGORIES_ASYNC, true);
            try {
                const newCategory = await addCategory(data);
                commit(ADD_NEW_CATEGORY, newCategory);
                return newCategory;
            } finally {
                commit(SET_CATEGORIES_ASYNC, false);
            }
        },
        async [DELETE_CATEGORY_ACTION]({ commit }, categoryId) {
            commit(SET_CATEGORIES_ASYNC, true);
            try {
                await deleteCategory(categoryId);
                commit(DELETE_CATEGORY, categoryId);
            } finally {
                commit(SET_CATEGORIES_ASYNC, false);
            }
        },
        async [CHANGE_CATEGORY_IMAGE_ACTION]({ commit }, { categoryId, images }) {
            const image = images.length > 0 ? images[0] : null;
            if (!image) return;

            commit(SET_CATEGORIES_ASYNC, true);

            let abortToken = new AbortController();

            commit(SET_CATEGORIES_UPLOAD_ABORT_TOKEN, abortToken);

            try {
                const { image: updatedImage } = await changeCategoryImage(categoryId, image, abortToken, (progress) => {
                    commit(SET_CATEGORIES_UPLOAD_PROGRESS, progress);
                });
                commit(UPDATE_CATEGORY, { id: categoryId, image: updatedImage });
            } finally {
                commit(SET_CATEGORIES_ASYNC, false);
                commit(SET_CATEGORIES_UPLOAD_PROGRESS, null);
                commit(SET_CATEGORIES_UPLOAD_ABORT_TOKEN, null);
            }
        },
        async [GET_CATEGORY_BY_ID_ACTION]({ state, commit }, { categoryId }) {
            if (state.async) return;

            commit(SET_CATEGORIES_ASYNC, true);
            const category = await fetchCategoryById(categoryId).catch(() => commit(SET_CATEGORIES_ASYNC, false));

            if (category) {
                state.categories = [category, ...state.categories];
                commit(SELECT_CATEGORY, category.id);
            }

            commit(SET_CATEGORIES_ASYNC, false);
        },
        async [CHANGE_CATEGORIES_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);

            commit(UPDATE_PER_PAGE);

            dispatch(FETCH_ALL_CATEGORIES_ACTION);
        }
    },
    getters: {
        selectedCategory(state) {
            return state.categories.find(b => b.id === state.selectedCategoryId);
        },
        categoryOptions(state) {
            return state.categories.map(m => ({ label: m.name, value: m.id }));
        },
        allCategories(state) {
            if (state.searchCategories !== null) {
                return state.searchCategories;
            } else {
                return state.categories;
            }
        },
        paginatedCategories(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allCategories.slice(beginIndex, endIndex);
        },
        totalCategoriesPages(state) {
            return state.totalPages;
        },
        categoriesAsync(state) {
            return state.async;
        },
    },
};
