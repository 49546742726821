<template>
    <div class="publishers">

        <div class="table-filters">
            <span>Pokaži</span>
            <container class="per-page">
                <select-field :options="perPageOptions"
                              @input="changePublishersPerPage"
                              :filterable="false"
                              :clearable="false"
                              :value="perPage"/>
            </container>
            <container>
                <input-field
                    class="table-search-input"
                    @input="searchPublishersAction"
                    type="text"
                    icon="icon-search"
                    :value="searchText"
                    placeholder="Pretraži izdavače"/>
            </container>

            <container>
                <Button :flat="true" @click="goToManagePublisher">
                    Dodaj izdavača
                </Button>
            </container>
        </div>

        <custom-table :select="true"
                      :fields="tableFields"
                      :data="publishersPage"
                      v-if="!async"
                      @column-click="item => editItem(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:created_at="props">
                <span>{{ props.item.created_at | moment('DD.MM.YYYY') }}</span>
            </template>
            <template v-slot:actions="props">
                <div>
                    <dropdown-menu anchor="right">
                        <template v-slot:handle>
                            <i style="font-size: 15px" class="icon icon-menudots"/>
                        </template>

                        <dropdown-menu-item @click="editItem(props.item.id)">
                            Edituj
                        </dropdown-menu-item>
                        <dropdown-menu-item @click="deletePublisher(props.item.id)">
                            Izbriši
                        </dropdown-menu-item>
                    </dropdown-menu>
                </div>
            </template>
        </custom-table>
        <div class="publishers-loader-container" v-else>
            <spinner/>
        </div>

        <div class=" footer">
            <custom-pagination :total-pages="totalPages"
                               :current-page="currentPage"
                               @change="changePublishersPage"/>
        </div>
    </div>
</template>

<script>
import CustomTable from '@/components/Ui/CustomTable/CustomTable';
import CustomPagination from '@/components/CustomPagination/CustomPagination';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RouteLink } from '@/store/modules/menu';
import {
    CHANGE_PUBLISHERS_PER_PAGE_ACTION,
    DELETE_PUBLISHER_ACTION,
    FETCH_ALL_PUBLISHERS_ACTION,
    SEARCH_PUBLISHERS_ACTION,
    SELECT_PUBLISHER,
    SET_PUBLISHERS_CURRENT_PAGE,
} from '@/store/actions/publishers';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import SelectField from '@/components/Ui/SelectField/SelectField';
import Button from '@/components/Ui/Button/Button';
import DropdownMenu from '@/components/Ui/DropdownMenu/DropdownMenu';
import DropdownMenuItem from '@/components/Ui/DropdownMenu/DropdownMenuItem';
import { showAreYouSureSwal, showSuccessSwal } from '@/utils/utils';
import Spinner from '@/components/Ui/Spinner/Spinner';

export default {
    name: 'Publishers',
    components: {
        Spinner,
        DropdownMenuItem,
        DropdownMenu,
        Button,
        SelectField,
        InputField,
        Container,
        CustomPagination,
        CustomTable,
    },
    data() {
        return {
            tableFields: [{
                name: 'name',
                title: 'Ime',
                enableClick: true,
            }, {
                name: 'phone',
                title: 'Telefon',
            }, {
                name: 'email',
                title: 'Email',
            }, {
                name: 'created_at',
                title: 'Datum Kreiranja',
                slot: 'created_at',
            }, {
                name: 'actions',
                title: '',
                width: '3%',
                lightText: true,
                slot: 'actions',
            }],
        };
    },
    computed: {
        ...
            mapGetters({
                publishersPage: 'paginatedPublishers',
                totalPages: 'totalPublishersPages',
            }),
        async() {
            return this.$store.state.publishers.async;
        }
        ,
        searchText() {
            return this.$store.state.publishers.searchText;
        }
        ,
        currentPage() {
            return this.$store.state.publishers.currentPage;
        }
        ,
        perPage() {
            return this.$store.state.publishers.perPage;
        }
        ,
        perPageOptions() {
            return [10, 25, 50];
        },
    }
    ,
    methods: {
        ...mapMutations({
            changePublishersPage: SET_PUBLISHERS_CURRENT_PAGE,
            selectPublisher: SELECT_PUBLISHER,
        }),
        ...mapActions({
            fetchAllPublishersAction: FETCH_ALL_PUBLISHERS_ACTION,
            searchPublishersAction: SEARCH_PUBLISHERS_ACTION,
            deletePublisherAction: DELETE_PUBLISHER_ACTION,
            changePublishersPerPage: CHANGE_PUBLISHERS_PER_PAGE_ACTION,
        }),
        goToManagePublisher() {
            this.$router.push(RouteLink.ManagePublisher);
        },
        onTableSelectItem(itemId) {
            console.log(itemId);
        },
        selectAllChanged(data) {
            console.log(data);
        },
        editItem(id) {
            this.selectPublisher(id);
            this.$router.push(RouteLink.ManagePublisher + `/${id}`);
        },
        deletePublisher(publisherId) {
            showAreYouSureSwal({ text: 'Jeste li sigurni da želite izbrisati izdavača?' }).then(result => {
                if (result.value) {
                    this.deletePublisherAction(publisherId).then(() => showSuccessSwal());
                }
            });
        },
    }
    ,
    mounted() {
        this.fetchAllPublishersAction();
    },
};
</script>

<style scoped lang="scss">
.publishers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .publishers-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
