<template>
    <div class="custom-tabs">
        <div class="tabs-row">
            <button v-for="tab in filteredTabs"
                    :class="{'tab-option': true, 'active': tab.isActive }"
                    :key="tab.name"
                    @click="selectTab(tab)">
                <span>{{ tab.name }}</span>
            </button>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "CustomTabs",
    data() {
        return {
            tabs: []
        };
    },
    created() {
        this.tabs = this.$children;
    },
    computed: {
        filteredTabs() {
            return this.tabs.filter(t => !t.disabled);
        }
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name === selectedTab.name);
            });
        }
    }
}
</script>

<style scoped lang="scss">

.custom-tabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tabs-row {
        background: $mainAccentColor;
        padding: 14px 40px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        transition: all 100ms linear;

        .tab-option {
            outline: none;
            border: 1px solid transparent;
            border-radius: 16px;
            padding: 6px 18px;
            font-weight: bold;
            font-size: 16px;
            margin-right: 25px;
            cursor: pointer;
            color: #fff;
            background: transparent;
            transition: all 250ms ease-in-out;
            user-select: none;

            &:active,
            &:hover,
            &:focus {
                border: 1px solid $mainAccentLightColor;
                box-shadow: $widgetShadow;
            }


            &.active {
                background: #fff;
                color: $mainAccentColor;

                &:active,
                &:hover,
                &:focus {
                    border: 1px solid $mainAccentLightColor;
                    box-shadow: $widgetShadow;
                }
            }
        }
    }
}

</style>
