import { ROLES, ROLES_ACCESS_LEVELS, STORAGE_KEYS } from '@/utils/consts';
import Vue from 'vue';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';

export function getDeepProp(obj, props) {
    return props.split('.').reduce(function (acc, p) {
        if (acc == null) return;
        return acc[p];
    }, obj);
}

export function searchBook(book, searchText) {
    const needle = searchText.toLowerCase();
    if (!book) return false;

    return book?.title?.toLowerCase()?.includes(needle) ||
        mergeName(book?.author)?.toLowerCase()?.includes(needle) ||
        book?.publisher?.name?.toLowerCase()?.includes(needle);
}

export function searchPromocode(promocode, searchText) {
    const needle = searchText.toLowerCase();
    if (!promocode) return false;

    return promocode?.name?.toLowerCase()?.includes(needle);
}

export function searchAuthor(author, searchText) {
    const needle = searchText.toLowerCase();
    if (!author) return false;

    return mergeName(author).toLowerCase().includes(needle);
}

export function searchCategory(category, searchText) {
    const needle = searchText.toLowerCase();
    if (!category) return false;

    return category.name.toLowerCase().includes(needle);
}

export function searchTag(tag, searchText) {
    const needle = searchText.toLowerCase();
    if (!tag) return false;

    return tag.name.toLowerCase().includes(needle);
}

export function searchDiscount(tag, searchText) {
    const needle = searchText.toLowerCase();
    if (!tag) return false;

    return tag.title.toLowerCase().includes(needle);
}

export function searchPublisher(publisher, searchText) {
    const needle = searchText.toLowerCase();
    if (!publisher) return false;

    return publisher.name?.toLowerCase()?.includes(needle) || publisher.email?.toLowerCase()?.includes(needle);
}

export function searchUser(user, searchText) {
    const needle = searchText.toLowerCase();
    if (!user) return false;

    return mergeName(user).toLowerCase().includes(needle) || user.email.toLowerCase().includes(needle);
}

export function searchCoinsPackage(coinsPackage, searchText) {
    const needle = searchText.toLowerCase();
    if (!coinsPackage) return false;

    return coinsPackage?.name.toLowerCase().includes(needle);
}

export function searchAgentBook(agentBook, searchText) {
    const needle = searchText.toLowerCase();
    if (!agentBook) return false;

    return agentBook?.book?.title.toLowerCase().includes(needle) || mergeName(agentBook?.sales_agent).toLowerCase().includes(needle);
}

export async function readFileToDataUrl(file) {
    return new Promise(resolve => {
        let reader = new FileReader();

        reader.onload = function (e) {
            resolve(e.target.result);
        };

        reader.readAsDataURL(file);
    });
}


export function mergeName(user) {
    return [user?.first_name, user?.middle_name, user?.last_name].filter(v => Boolean(v)).join(' ');
}

export function isSuperAdmin(user) {
    return user ? user.roles.includes(ROLES.ROLE_SUPER_ADMIN) : false;
}

export function isPublisherAdmin(user) {
    return user ? user.roles.includes(ROLES.ROLE_PUBLISHER_ADMIN) : false;
}

export function isSalesAgent(user) {
    return user ? user.roles.includes(ROLES.ROLE_SALES_AGENT) : false;
}

export function hasAccessToAdminPanel(user) {
    return isSuperAdmin(user) || isPublisherAdmin(user) || isSalesAgent(user);
}

//diffs two objects
export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}

export function splitName(name) {
    let data = {};
    let nameParts = name.split(' ');
    data.first_name = nameParts[0];
    nameParts.splice(0, 1);
    data.last_name = nameParts.join(' ');
    return data;
}

export function getAccessLevel(user) {
    if (!user) return null;

    if (user.roles.includes(ROLES.ROLE_SUPER_ADMIN)) {
        return ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN;
    } else if (user.roles.includes(ROLES.ROLE_PUBLISHER_ADMIN)) {
        return ROLES_ACCESS_LEVELS.ROLE_PUBLISHER_ADMIN;
    } else if (user.roles.includes(ROLES.ROLE_SALES_AGENT)) {
        return ROLES_ACCESS_LEVELS.ROLE_SALES_AGENT;
    } else if (user.roles.includes(ROLES.ROLE_USER)) {
        return ROLES_ACCESS_LEVELS.ROLE_USER;
    }

    return 0;
}

export function showSwalToast(message, type = 'error') {
    Vue.swal({
        toast: true,
        position: 'top-end',
        timer: 3500,
        title: message,
        icon: type,
        showConfirmButton: false,
    });
}

export function showAreYouSureSwal(options) {
    const defaultOptions = {
        title: 'Jeste li sigurni?',
        text: 'Are you sure you want to do this?',
        icon: 'warning',
    };

    const actualOptions = Object.assign(defaultOptions, options);

    return Vue.swal({
        title: actualOptions.title,
        text: actualOptions.text,
        icon: actualOptions.icon,
        showCancelButton: true,
        confirmButtonColor: '#cd5c5c',
        cancelButtonColor: '#d8d8d8',
        confirmButtonText: 'Potvrdi',
        cancelButtonText: 'Otkaži',
        reverseButtons: true,
    });
}

export function showErrorSwal() {
    Vue.swal({
        toast: true,
        position: 'top-end',
        timer: 2500,
        title: 'Greška',
        icon: 'error',
        showConfirmButton: false,
    });
}

export function showSuccessSwal() {
    Vue.swal({
        toast: true,
        position: 'top-end',
        timer: 2500,
        title: 'Uspješno',
        icon: 'success',
        showConfirmButton: false,
    });
}

export function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;

    let styleNodes = tmp.querySelectorAll("style");

    styleNodes.forEach(n => {
        tmp.removeChild(n);
    })

    let ret = tmp.innerHTML;
    tmp.remove();
    return ret;
}

export function roundDecimals(num, places = 2) {
    return +(Math.round(num + "e+" + places) + "e-" + places);
}

export function getPerPage() {
    return +localStorage.getItem(STORAGE_KEYS.PER_PAGE) || 10;
}

export function searchChapterTransaction() {

}

export function setPerPage(perPage) {
    localStorage.setItem(STORAGE_KEYS.PER_PAGE, perPage);
}

export function chaptersSortFunc(a, b) {
    return a.order_index < b.order_index ? -1 : 1;
}

export function chaptersSortFuncReverse(a, b) {
    return a.order_index > b.order_index ? -1 : 1;
}
