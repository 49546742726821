// actions
export const FETCH_ALL_CATEGORIES_ACTION = 'FETCH_ALL_CATEGORIES_ACTION';
export const FETCH_CATEGORIES_PAGE_ACTION = 'FETCH_CATEGORIES_PAGE_ACTION';
export const SEARCH_CATEGORIES_ACTION = 'SEARCH_CATEGORIES_ACTION';
export const ADD_CATEGORY_ACTION = 'ADD_CATEGORY_ACTION';
export const EDIT_CATEGORY_ACTION = 'EDIT_CATEGORY_ACTION';
export const DELETE_CATEGORY_ACTION = 'DELETE_CATEGORY_ACTION';
export const CHANGE_CATEGORY_IMAGE_ACTION = 'CHANGE_CATEGORY_IMAGE_ACTION';
export const GET_CATEGORY_BY_ID_ACTION = 'GET_CATEGORY_BY_ID_ACTION';
export const CHANGE_CATEGORIES_PER_PAGE_ACTION = 'CHANGE_CATEGORIES_PER_PAGE_ACTION';

// mutations
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SET_CATEGORIES_ASYNC = 'SET_CATEGORIES_ASYNC';
export const SET_SEARCH_CATEGORIES = 'SET_SEARCH_CATEGORIES';
export const SET_CATEGORIES_SEARCH_TEXT = 'SET_CATEGORIES_SEARCH_TEXT';
export const APPEND_CATEGORIES_PAGE = 'APPEND_CATEGORIES_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SET_CATEGORIES_CURRENT_PAGE = 'SET_CATEGORIES_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_CATEGORIES_UPLOAD_ABORT_TOKEN = 'SET_CATEGORIES_UPLOAD_ABORT_TOKEN';
export const SET_CATEGORIES_UPLOAD_PROGRESS = 'SET_CATEGORIES_UPLOAD_PROGRESS';
