import { apiCall, categories } from '@/api/apiCall';
import { progressFetch } from '@/api/progressFetch';
import { showSwalToast } from "@/utils/utils";
import { ERROR_MESSAGES } from "@/utils/consts";

export async function fetchCategoriesPage(page = 1, perPage = 10) {
    let url = new URL(categories);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editCategory(categoryId, data) {
    return apiCall({
        url: categories + `/${categoryId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addCategory(data) {
    return apiCall({
        url: categories,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteCategory(categoryId) {
    return apiCall({
        url: categories + `/${categoryId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function changeCategoryImage(categoryId, image, abortToken, onProgressChange) {
    let url = new URL(`${categories}/${categoryId}/change_image`);

    const formData = new FormData();

    formData.append('file', image.file);

    try {
        return await progressFetch({
            url: url.href,
            method: 'POST',
            body: formData,
            signal: abortToken.signal,
            uploadProgress: onProgressChange,
        }).then(r => r.json());
    } catch (ex) {
        console.error('error uploading image');
        if (ex?.detail) {
            showSwalToast(ERROR_MESSAGES[ex.detail] ?? ex.detail, 'error');
        }
        throw ex;
    }
}

export async function fetchCategoryById(categoryId) {
    return apiCall({
        url: `${categories}/${categoryId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
