<template>
    <div :class="{'root-layout': true, 'drawer-expanded': isDrawerExpanded}">
        <template v-if="!isLogin">
            <Header/>
            <drawer/>
            <div class="main-view">
                <div class="page">
                    <transition duration="200ms" name="fade" mode="out-in">
                        <router-view/>
                    </transition>
                </div>
            </div>
        </template>
        <template v-else>
            <router-view/>
        </template>
    </div>
</template>

<script>
import Drawer from '@/components/Ui/Drawer/Drawer';
import Header from '@/components/Ui/Header/Header';
import { mapActions, mapGetters } from 'vuex';
import { STORAGE_KEYS } from '@/utils/consts';
import { DO_ME_ACTION } from '@/store/actions/login';
import { RouteLink } from "@/store/modules/menu";

export default {
    components: { Header, Drawer },
    async beforeMount() {

        //  ROUTE GUARD
        this.$router.beforeEach((to, from, next) => {
            this.handleRouteAccess(to, next);
        });

        if (this.user) {
            return;
        }

        const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

        if (!token) {
            this.$router.push('/login').catch(() => {
            });
            return;
        }

        if (token && !this.user) {
            await this.doMe();
        }

        // Can guard once before mount
        await this.handleRouteAccess(this.$route);
    },

    mounted() {

    },

    methods: {
        ...mapActions({
            doMe: DO_ME_ACTION,
        }),
        async handleRouteAccess(route, next) {

            const { path, meta } = route;

            const redirectPath = this.isSuperAdmin ? RouteLink.Books : RouteLink.Analytics;

            const actualPath = path !== '/' ? path : redirectPath;

            if (this.userAccessLevel < meta?.accessLevel) {                     // Deny access
                next ? next({ redirectPath }) : this.$router.push(redirectPath);
            } else {
                next ? next() : this.$router.push(actualPath);
            }
        }
    },
    computed: {
        ...mapGetters([
            'isDrawerExpanded',
            'isSuperAdmin',
            'userAccessLevel'
        ]),
        isLogin() {
            return this.$route.name === 'Login';
        },
        async() {
            return this.$store.state.login.async;
        },
        user() {
            return this.$store.state.login.user;
        },
    },
};
</script>

<style lang="scss">
html, body, #app {
    padding: 0px;
    margin: 0px;
    height: 100%;
    background: $pageBg;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: $pageBg;
}

.root-layout {
    display: flex;
    height: 100%;
    max-height: 100vh;

    &.drawer-expanded {

        .drawer {
            width: $expandedDrawerWidth;
        }

        .header {
            left: $expandedDrawerWidth;
        }

        .main-view {
            padding-left: $expandedDrawerWidth;
        }
    }
}
</style>
