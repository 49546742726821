<template>
    <div class="drawer">
        <div class="title-holder">
            <span v-if="isDrawerExpanded">Tvornica Snova</span>
            <i class="icon-menu drawer-toggle" @click="toggleDrawerExpanded"/>
        </div>

        <div class="menu-items">
            <div v-for="group in groupsForUser" :key="group.name" class="menu-item">
                <router-link :to="group.link" custom v-slot="{ href, navigate, isActive, isExactActive }">
                    <div
                        :class="['route-link', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                        <a :href="href"
                           @click="navigate"
                           v-if="isDrawerExpanded">
                            {{ group.name }}
                        </a>
                    </div>
                </router-link>

                <div class="menu-group">
                    <div v-for="item in group.items" :key="item.name" class="menu-sub-item">
                        <router-link :to="item.link" custom v-slot="{ href, navigate, isActive, isExactActive }">
                            <div
                                :class="['route-link', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                <a :href="href"
                                   @click="navigate"
                                   v-if="isDrawerExpanded">
                                    {{ item.name }}
                                </a>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="profile-version-holder">
            <div class="profile-holder">
                <dropdown-menu anchor="left">
                    <template v-slot:handle>
                        <div class="profile" @click="toggleUserDropdown">
                            <img :src="userProfilePicture" alt="" v-if="userProfilePicture"/>
                            <rounded-initials v-else :user="user"/>
                        </div>
                    </template>

                    <dropdown-menu-item @click="logout">
                        Logout
                    </dropdown-menu-item>
                </dropdown-menu>
                <span v-if="isDrawerExpanded" class="username">{{ user|mergeName }}</span>
            </div>

            <div class="version-field" :style="{visibility: isDrawerExpanded ? 'visible' : 'hidden'}">
                <span>{{ versionText }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import { SET_DRAWER_EXPANDED } from '@/store/actions/menu';
import RoundedInitials from '@/components/RoundedInitials/RoundedInitials';
import DropdownMenu from '@/components/Ui/DropdownMenu/DropdownMenu';
import DropdownMenuItem from '@/components/Ui/DropdownMenu/DropdownMenuItem';
import { BUILD_VERSION, RELEASE_TIMESTAMP, STORAGE_KEYS } from '@/utils/consts';
import moment from 'moment';

export default {
    name: 'Drawer',
    components: { DropdownMenuItem, DropdownMenu, RoundedInitials },
    data() {
        return {
            userDropdownOpened: false,
        };
    },
    computed: {
        ...mapGetters([
            'isDrawerExpanded',
            'groupsForUser',
            'user',
        ]),
        userProfilePicture() {
            return null;
        },
        versionText() {
            return `Verzija ${BUILD_VERSION}@${moment(RELEASE_TIMESTAMP).format('DD/MM/YY')}`;
        },
    },
    methods: {
        ...mapMutations({
            setDrawerExpanded: SET_DRAWER_EXPANDED,
        }),
        toggleDrawerExpanded() {
            this.setDrawerExpanded(!this.isDrawerExpanded);
        },
        toggleUserDropdown() {
            this.userDropdownOpened = !this.userDropdownOpened;
        },
        logout() {
            localStorage.removeItem(STORAGE_KEYS.TOKEN);
            localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
            window.location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>
.drawer {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 16px 22px 0px 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px;
    box-shadow: 0px 2px 15px 10px rgba(229, 237, 240, 0.333452);
    box-sizing: border-box;
    background: #ffffff;
    width: $drawerWidth;
    transition: all 250ms ease-in-out;
    overflow: hidden;

    .title-holder {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;

        span {
            font-size: 18px;
            font-weight: 900;
            margin-right: auto;
            color: $darkTextColor;
        }

        .drawer-toggle {
            cursor: pointer;
            font-size: 20px;
        }
    }

    .menu-items {
        padding-top: 16px;

        .menu-item {
            margin-top: 26px;
            margin-bottom: 26px;

            .route-link {
                text-align: start;

                a {
                    font-size: 16px;
                    text-align: start;
                    text-decoration: none;
                    font-weight: 600;
                    color: $darkTextColor;
                }

                &.router-link-exact-active {
                    a {
                        font-weight: 700;
                        color: $mainAccentColor;
                    }
                }
            }
        }

        .menu-group {
            margin-top: 18px;

            .menu-sub-item {
                margin-left: 16px;
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }

    .profile-version-holder {
        margin-top: auto;

        .profile-holder {
            display: flex;
            align-items: center;

            .profile {
                cursor: pointer;
            }

            .username {
                white-space: nowrap;
                overflow: hidden;
                margin-left: 20px;
            }
        }

        .version-field {
            margin-top: 82px;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            color: #777;
            margin-bottom: 8px;
        }
    }
}
</style>
