<template>
    <div class="sales-agent-analytics">
        <custom-table :select="true"
                      :fields="tableFields"
                      v-if="!async"
                      :data="analytics"
                      @column-click="item => checkBookDetails(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:amount_apple="props">
                <span>{{ parseCentsToEuros(props.item.price_in_cents_apple) }} </span>
            </template>

            <template v-slot:amount_monri="props">
                <span>{{ parseCentsToEuros(props.item.price_in_cents_monri) }} </span>
            </template>

            <template v-slot:total_amount="props">
                <span>{{ parseCentsToEuros(props.item.price_in_cents + props.item.price_in_cents_apple) }} </span>
            </template>

            <template v-slot:agent_share="props">
                <span>{{ getSalesAgentShare(props.item) }} </span>
            </template>

        </custom-table>
        <div class="chapterTransactions-loader-container" v-else>
            <spinner/>
        </div>
    </div>
</template>

<script>
import CustomTable from '@/components/Ui/CustomTable/CustomTable';
import Spinner from '@/components/Ui/Spinner/Spinner';
import { mapGetters } from 'vuex';
import Decimal from 'decimal.js';

export default {
    name: 'PublisherAnalytics',
    components: {
        Spinner,
        CustomTable,
    },
    data() {
        return {
            tableFields: [{
                name: 'title',
                title: 'Knjiga',
                enableClick: true,
            }, {
                name: 'amount_monri',
                title: 'Monri (€)',
                slot: 'amount_monri',
            }, {
                name: 'amount_apple',
                title: 'Apple ($)',
                slot: 'amount_apple',
            }, {
                name: 'total_amount',
                title: 'Ukupna zarada (€)',
                slot: 'total_amount',
            }, {
                title: 'Zarada agenta (€)',
                slot: 'agent_share',
            }],
        };
    },
    computed: {
        ...mapGetters({
            analytics: 'analytics',
            user: 'user',
        }),
        async() {
            return this.$store.state.chapterTransactions.async;
        },
    },
    methods: {
        onTableSelectItem(itemId) {
            console.log(itemId);
        },
        selectAllChanged(data) {
            console.log(data);
        },
        checkBookDetails(book) {
            console.log(book);
        },
        parseCentsToEuros(priceInCents) {
            return new Decimal(priceInCents).div(100).toFixed(2);
        },
        getSalesAgentShare(bookItem) {
            const { total_amount, price_in_cents_apple, agent_percent } = bookItem;
            const total = new Decimal(total_amount).add(price_in_cents_apple);
            return this.parseCentsToEuros(total.mul(new Decimal(agent_percent).div(100)));
        },
    },
};
</script>

<style scoped lang="scss">
.sales-agent-analytics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .chapterTransactions-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
