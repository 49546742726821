<template>
    <div class="tvornica-snova spinner-container">
        <clip-loader :loading="true" :size="small ? '15px' : '35px'"
                     :color="invert ? '#FFFFFF' : '#4122F0'"></clip-loader>
    </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader';

export default {
    name: 'Spinner',
    components: { ClipLoader },
    props: {
        invert: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
div.tvornica-snova.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
