<template>
    <div :class="['input-container', focus && 'focus', icon && 'icon']">
        <i :class="[icon]" v-if="icon"/>
        <input :value="actualValue"
               :autocomplete="autocomplete"
               :placeholder="placeholder"
               :min="min"
               :max="max"
               :disabled="disabled"
               :type="type"
               @input="$emit('input', $event.target.value)" @focusin="focus = true"
               @focusout="focus = false"
               class="tvornica-snova"/>
    </div>
</template>

<script>

export default {
    name: "InputField",
    data() {
        return {
            focus: false,
        }
    },
    props: {
        icon: String,
        value: [String, Number],
        placeholder: String,
        type: String,
        disabled: Boolean,
        autocomplete: String,
        dateFormat: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        min: [Number, String],
        max: [Number, String]
    },
    computed: {
        actualValue() {
            if (this.type === 'date') {
                return this.$moment(this.value).format(this.dateFormat);
            }

            return this.value;
        }
    }
}
</script>

<style scoped lang="scss">
</style>
