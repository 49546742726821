<template>
    <div class="analytics">
        <div class="table-filters">
            <span>Prikaži Za: </span>

            <container>
                <select-field v-model="dateRange"
                              :clearable="true"
                              :options="dateRangeOptions"/>
            </container>

            <div class="buttons-container">
                <add-apple-analytic-modal />

                <Button class="apply-btn" @click="fetchFilteredAppleAnalytics">
                    Primijeni
                </Button>
            </div>
        </div>
        <admin-apple-analytics v-if="isAdmin"/>
        <publisher-apple-analytics v-else-if="isPubAdmin"/>
        <sales-agent-apple-analytics v-else/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isPublisherAdmin, isSuperAdmin } from '@/utils/utils';
import { FETCH_APPLE_ANALYTICS } from '@/store/actions/chapterTransactions';
import Container from '@/components/Ui/Container/Container';
import Button from '@/components/Ui/Button/Button';
import AdminAppleAnalytics from '@/views/AppleAnalytics/components/AdminAppleAnalytics';
import PublisherAppleAnalytics from '@/views/AppleAnalytics/components/PublisherAppleAnalytics';
import SalesAgentAppleAnalytics from '@/views/AppleAnalytics/components/SalesAgentAppleAnalytics';
import SelectField from '@/components/Ui/SelectField/SelectField';
import 'moment/locale/bs';
import AddAppleAnalyticModal from '@/views/AppleAnalytics/AddAppleAnalyticModal';

export default {
    name: 'AppleAnalytics',
    data() {
        return {
            dateRange: null,
        };
    },
    components: {
        AddAppleAnalyticModal,
        SelectField,
        PublisherAppleAnalytics,
        SalesAgentAppleAnalytics,
        AdminAppleAnalytics,
        Button,
        Container,
    },
    computed: {
        ...mapGetters([
            'user',
            'appleAnalytics',
        ]),
        isAdmin() {
            return isSuperAdmin(this.user);
        },
        isPubAdmin() {
            return isPublisherAdmin(this.user);
        },
        dateRangeOptions() {
            return [];
        },
    },
    methods: {
        ...mapActions({
            fetchAppleAnalytics: FETCH_APPLE_ANALYTICS,
        }),
        addAppleAnalytics() {

        },
        fetchFilteredAppleAnalytics() {
            // const fromDate = date.toISOString();
            // const toDate = date.add(1, isSelectedFullYear ? "year" : "month").toISOString();
            //
            // const query = date.isValid() ? `from_date=${fromDate}&to_date=${toDate}` : null;

            const query = '';

            this.fetchAppleAnalytics(query);
        },
    },
    beforeMount() {
        if (!this.appleAnalytics.length) {
            this.fetchAppleAnalytics();
        }
    },
};
</script>

<style scoped lang="scss">

.analytics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;

    .table-filters {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }

        .select-container {
            min-width: 150px;
        }

        .to-date {
            margin-left: 20px;
        }

        .buttons-container {
            margin-left: auto;
            display: flex;

            .apply-btn {
                margin-left: 8px;
            }
        }

    }
}
</style>
