import Vue from 'vue'
import Vuex from 'vuex'
import menu from "@/store/modules/menu";
import books from "@/store/modules/books";
import login from '@/store/modules/login';
import authors from '@/store/modules/authors';
import categories from '@/store/modules/categories';
import tags from "@/store/modules/tags";
import discounts from "@/store/modules/discounts";
import publishers from "@/store/modules/publishers";
import users from "@/store/modules/users";
import coinsPackages from "@/store/modules/coinsPackages";
import chapterTransactions from "@/store/modules/chapterTransactions";
import agentBooks from "@/store/modules/agentBooks";
import promoCodes from "@/store/modules/promoCodes";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        menu,
        books,
        login,
        authors,
        categories,
        tags,
        discounts,
        publishers,
        users,
        coinsPackages,
        chapterTransactions,
        agentBooks,
        promoCodes,
    }
})
