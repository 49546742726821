import { apiCall, authors } from '@/api/apiCall';

export async function fetchAuthorsPage(page = 1, perPage = 10) {
    let url = new URL(authors);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editAuthor(authorId, data) {
    return apiCall({
        url: authors + `/${authorId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addAuthor(data) {
    return apiCall({
        url: authors,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteAuthor(authorId) {
    return apiCall({
        url: authors + `/${authorId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function changeAuthorImage(authorId, image) {
    let url = new URL(`${authors}/${authorId}/change_image`);

    const formData = new FormData();

    formData.append('file', image);

    return apiCall({
        url: url.href,
        method: 'POST',
        headers: {
            'accept': 'application/json',
        },
        body: formData,
    }).then(r => r.json());
}

export async function fetchAuthorById(authorId) {
    return apiCall({
        url: `${authors}/${authorId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
