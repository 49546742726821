import {
    SELECT_BOOK,
    SET_BOOKS,
    FETCH_ALL_BOOKS_ACTION,
    SET_BOOKS_ASYNC,
    SET_SEARCH_BOOKS,
    SET_BOOKS_SEARCH_TEXT,
    FETCH_BOOKS_PAGE_ACTION,
    APPEND_BOOKS_PAGE,
    SEARCH_BOOKS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_BOOK_ACTION,
    ADD_BOOK_ACTION,
    UPDATE_BOOK,
    ADD_NEW_BOOK,
    DELETE_BOOK_ACTION,
    DELETE_BOOK,
    SET_BOOKS_CURRENT_PAGE,
    SET_TOTAL_PAGES,
    CHANGE_BOOK_IMAGE_ACTION,
    DELETE_CHAPTER_ACTION,
    DELETE_CHAPTER,
    EDIT_CHAPTER_ACTION,
    UPDATE_CHAPTER,
    SELECT_CHAPTER,
    ADD_CHAPTER_ACTION,
    ADD_CHAPTER,
    GET_BOOK_BY_ID_ACTION,
    TOGGLE_BOOK_PUBLISHED_ACTION,
    REQUEST_APPROVAL_ACTION,
    APPROVE_BOOK_ACTION,
    REJECT_BOOK_ACTION,
    SET_BOOKS_UPLOAD_PROGRESS,
    SET_BOOKS_UPLOAD_ABORT_TOKEN,
    ADD_BOOK_DISCOUNT_ACTION,
    DELETE_BOOK_DISCOUNT_ACTION,
    DELETE_BOOK_DISCOUNT,
    ADD_BOOK_DISCOUNT,
    TOGGLE_BOOK_DISCOUNT_ACTION,
    UPDATE_BOOK_DISCOUNT,
    CHANGE_BOOKS_PER_PAGE_ACTION,
    MOVE_CHAPTER_ACTION,
} from '@/store/actions/books';
import {
    addBook, addChapter, approveBook, changeBookImage,
    deleteBook, deleteChapter,
    editBook, editChapter, fetchBookById,
    fetchBooksPage, rejectBook, requestBookApproval, toggleBookPublished,
    moveChapterApi
} from '@/api/books/books';
import { getPerPage, searchBook, setPerPage } from '@/utils/utils';
import { addBookDiscount, deleteBookDiscount, toggleBookDiscountEnabled } from '@/api/bookDiscounts/bookDiscounts';
import { cloneDeep } from 'lodash';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        books: [],

        // Filtering
        searchText: null,
        searchBooks: null,
        searchAsync: false,
        selectedBookId: null,
        selectedChapterId: null,

        // CustomPagination
        perPage: getPerPage(),
        currentPage: 1,
        totalPages: 1,

        uploadProgress: null,
        abortToken: null,
        async: false,
    }),
    mutations: {
        [SET_BOOKS](state, Books) {
            state.books = Books;
        },
        [SELECT_BOOK](state, bookId) {
            state.selectedBookId = bookId;
        },
        [SET_BOOKS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_BOOKS](state, searchBooks) {
            state.searchBooks = searchBooks;
        },
        [SET_BOOKS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_BOOKS_PAGE](state, Books) {
            if (state.books !== null) {
                state.books = state.books.concat(Books);
            } else {
                state.books = [...Books];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_BOOK](state, { id, ...data }) {
            let newBooks = [...state.books];
            for (let book of newBooks) {
                if (book.id === id) {
                    Object.assign(book, data);
                    state.books = newBooks;
                    return;
                }
            }
        },
        [ADD_NEW_BOOK](state, newbook) {
            const index = state.books.findIndex(book => book.id === newbook.id);

            if (index === -1) {
                state.books.unshift(newbook);
            }
        },
        [DELETE_BOOK](state, bookId) {
            if (state.books !== null) {
                state.books = state.books.filter(book => book.id !== bookId);
            }
        },
        [SET_BOOKS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
        [DELETE_CHAPTER](state, { bookId, chapterId }) {
            for (let book of state.books) {
                if (book.id === bookId) {
                    book.chapters = book.chapters.filter(c => c.id !== chapterId);
                }
            }
        },
        [UPDATE_CHAPTER](state, { bookId, chapterId, data }) {
            for (let book of state.books) {
                if (book.id === bookId) {
                    for (let chapter of book.chapters) {
                        if (chapter.id === chapterId) {
                            Object.assign(chapter, data);
                            return;
                        }
                    }
                }
            }
        },
        [SELECT_CHAPTER](state, chapterId) {
            state.selectedChapterId = chapterId;
        },
        [ADD_CHAPTER](state, { bookId, newChapter }) {
            for (let book of state.books) {
                if (book.id === bookId) {
                    book.chapters.push(newChapter);
                }
            }
        },
        [SET_BOOKS_UPLOAD_ABORT_TOKEN](state, token) {
            state.abortToken = token;
        },
        [SET_BOOKS_UPLOAD_PROGRESS](state, progress) {
            state.uploadProgress = progress;
        },
        [ADD_BOOK_DISCOUNT](state, { bookId, newBookDiscount }) {
            for (let book of state.books) {
                if (book.id === bookId) {
                    book.book_discounts.push(newBookDiscount);
                }
            }
        },
        [DELETE_BOOK_DISCOUNT](state, { bookId, bookDiscountId }) {
            let newBooks = [...state.books];
            for (let book of newBooks) {
                if (book.id === bookId) {
                    book.book_discounts = book.book_discounts.filter(bd => bd.id !== bookDiscountId);
                    state.books = newBooks;
                    return;
                }
            }
        },
        [UPDATE_BOOK_DISCOUNT](state, { bookId, bookDiscountId, data }) {

            for (let book of state.books) {
                if (book.id === bookId) {
                    for (let bookDiscount of book.book_discounts) {
                        if (bookDiscount.id === bookDiscountId) {
                            Object.assign(bookDiscount, data);
                            return;
                        }
                    }
                }
            }
        },
    },
    actions: {
        async [FETCH_ALL_BOOKS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_BOOKS_ASYNC, true);
            commit(SET_BOOKS, []);
            commit(SET_SEARCH_BOOKS, null);
            commit(SET_BOOKS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_BOOKS_PAGE_ACTION, { page });
            }

            commit(SET_BOOKS_CURRENT_PAGE, 1);
            commit(SET_TOTAL_PAGES, page);

            commit(SET_BOOKS_ASYNC, false);
        },
        async [FETCH_BOOKS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Books,
                } = await fetchBooksPage(page, state.perPage);
                if (Array.isArray(Books)) {
                    commit(APPEND_BOOKS_PAGE, Books);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Books Page', ex);
            }
        },
        [SEARCH_BOOKS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_BOOKS_SEARCH_TEXT, null);
                commit(SET_SEARCH_BOOKS, null);
            } else {
                commit(SET_BOOKS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_BOOKS, state.books.filter(book => searchBook(book, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_BOOK_ACTION]({ commit }, { id, data }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedbook = await editBook(id, data);
                commit(UPDATE_BOOK, updatedbook);
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [ADD_BOOK_ACTION]({ commit }, data) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const newbook = await addBook(data);
                commit(ADD_NEW_BOOK, newbook);
                return newbook;
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [DELETE_BOOK_ACTION]({ commit }, bookId) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                await deleteBook(bookId);
                commit(DELETE_BOOK, bookId);
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [CHANGE_BOOK_IMAGE_ACTION]({ commit }, { bookId, images }) {
            const image = images.length > 0 ? images[0] : null;
            if (!image) return;

            commit(SET_BOOKS_ASYNC, true);

            let abortToken = new AbortController();

            commit(SET_BOOKS_UPLOAD_ABORT_TOKEN, abortToken);

            try {
                const { image: updatedImage } = await changeBookImage(bookId, image, abortToken, (progress) => {
                    commit(SET_BOOKS_UPLOAD_PROGRESS, progress);
                });
                commit(UPDATE_BOOK, { id: bookId, image: updatedImage });
            } finally {
                commit(SET_BOOKS_ASYNC, false);
                commit(SET_BOOKS_UPLOAD_ABORT_TOKEN, null);
                commit(SET_BOOKS_UPLOAD_PROGRESS, null);
            }
        },
        async [DELETE_CHAPTER_ACTION]({ commit }, { bookId, chapterId }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                await deleteChapter(chapterId);
                commit(DELETE_CHAPTER, { bookId, chapterId });
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [EDIT_CHAPTER_ACTION]({ commit }, { bookId, chapterId, data }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedChapter = await editChapter(chapterId, data);
                commit(UPDATE_CHAPTER, { bookId, chapterId, data: updatedChapter });
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [ADD_CHAPTER_ACTION]({ commit }, { bookId, data }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const newChapter = await addChapter(bookId, data);
                commit(ADD_CHAPTER, { bookId, newChapter });

                return newChapter;
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [MOVE_CHAPTER_ACTION]({ state, commit }, { bookId, chapterId, up = true }) {
            const book = state.books?.find(book => book.id === bookId);

            if (book) {
                const sortedChapters = [...book.chapters].sort((a, b) => a.order_index > b.order_index ? -1 : 1);
                const meIndex = sortedChapters.findIndex(chapter => chapter.id === chapterId);

                if (meIndex !== -1) {
                    console.log(meIndex);
                    let switcherIndex = -1;

                    if (up) {
                        switcherIndex = meIndex - 1;

                        if (switcherIndex < 0) {
                            switcherIndex = -1;
                        }
                    } else {
                        switcherIndex = meIndex + 1;

                        if (switcherIndex >= sortedChapters.length) {
                            switcherIndex = -1;
                        }
                    }

                    if (switcherIndex !== -1) {
                        const switcher = cloneDeep(sortedChapters[switcherIndex]);
                        const me = cloneDeep(sortedChapters[meIndex]);

                        commit(SET_BOOKS_ASYNC, true);

                        try {
                            await moveChapterApi(chapterId, up);

                            commit(UPDATE_CHAPTER, {
                                bookId,
                                chapterId: me.id,
                                data: {
                                    order_index: switcher.order_index,
                                },
                            });

                            commit(UPDATE_CHAPTER, {
                                bookId,
                                chapterId: switcher.id,
                                data: {
                                    order_index: me.order_index,
                                },
                            });

                        } catch (ex) {
                            console.error(ex);
                        } finally {
                            commit(SET_BOOKS_ASYNC, false);
                        }
                    }
                }
            }
        },
        async [GET_BOOK_BY_ID_ACTION]({ state, commit }, { bookId }) {
            if (state.async) return;

            commit(SET_BOOKS_ASYNC, true);
            const book = await fetchBookById(bookId).catch(() => commit(SET_BOOKS_ASYNC, false));

            if (book) {
                state.books = [book, ...state.books];
                commit(SELECT_BOOK, book.id);
            }

            commit(SET_BOOKS_ASYNC, false);
        },
        async [TOGGLE_BOOK_PUBLISHED_ACTION]({ commit }, { bookId, toggle }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedBook = await toggleBookPublished(bookId, toggle);
                commit(UPDATE_BOOK, updatedBook);
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [REQUEST_APPROVAL_ACTION]({ commit }, bookId) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedBook = await requestBookApproval(bookId);
                commit(UPDATE_BOOK, updatedBook);
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [APPROVE_BOOK_ACTION]({ commit }, bookId) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedBook = await approveBook(bookId);
                commit(UPDATE_BOOK, updatedBook);
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [REJECT_BOOK_ACTION]({ commit }, bookId) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedBook = await rejectBook(bookId);
                commit(UPDATE_BOOK, updatedBook);
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [ADD_BOOK_DISCOUNT_ACTION]({ commit }, { bookId, discountId }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                const newBookDiscount = await addBookDiscount({ bookId, discountId });
                commit(ADD_BOOK_DISCOUNT, { bookId, newBookDiscount });
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [DELETE_BOOK_DISCOUNT_ACTION]({ commit }, { bookId, bookDiscountId }) {
            commit(SET_BOOKS_ASYNC, true);
            try {
                await deleteBookDiscount(bookDiscountId);
                commit(DELETE_BOOK_DISCOUNT, { bookId, bookDiscountId });
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [TOGGLE_BOOK_DISCOUNT_ACTION]({ commit }, { bookId, bookDiscountId, toggle }) {

            commit(SET_BOOKS_ASYNC, true);
            try {
                const updatedBookDiscount = await toggleBookDiscountEnabled(bookDiscountId, toggle);
                commit(UPDATE_BOOK_DISCOUNT, { bookId, bookDiscountId, data: updatedBookDiscount });
            } finally {
                commit(SET_BOOKS_ASYNC, false);
            }
        },
        async [CHANGE_BOOKS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);

            commit(UPDATE_PER_PAGE);

            dispatch(FETCH_ALL_BOOKS_ACTION);
        },
    },
    getters: {
        selectedBook(state) {
            return state.books.find(b => b.id === state.selectedBookId);
        },
        selectedChapter(state, getters) {
            return getters.selectedBook?.chapters?.find(c => c.id === state.selectedChapterId) ?? null;
        },
        bookOptions(state) {
            return state.books.map(m => ({ label: m.title, value: m.id }));
        },
        allBooks(state) {
            if (state.searchBooks !== null) {
                return state.searchBooks;
            } else {
                return state.books;
            }
        },
        paginatedBooks(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allBooks.slice(beginIndex, endIndex);
        },
        totalBookPages(state) {
            return state.totalPages;
        },
        booksAsync(state) {
            return state.async;
        },
    },
};
