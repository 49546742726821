<template>
    <div class="reservation-tab">
        <div class="available-form">

            <input-label :label="$t('original_book_title')" :error="errors['original_title']" required>
                <input-field v-model="form.original_title"/>
            </input-label>

            <input-label label="Autor" :error="errors['author']" required>
                <select-field v-model="form.author" :options="authorOptions" :on-add-option="onAddAuthorOption"/>
            </input-label>

            <Button :inverted="true"
                    :loading="checkAsync"
                    @click="checkIfAvailable">
                Provjeri dostupnost naslova
            </Button>
        </div>

        <div class="reserve-book" v-if="isBookAvailable">
            <span class="main-message">
                {{ $t('title_is_available_to_publish') }}
            </span>
            <span class="second-message">
                {{ $t('publish_now_or_reserve') }}
            </span>

            <Button :flat="true"
                    @click="$emit('save', form)"
                    :loading="async"
                    class="reserve-btn">
                {{ $t('reserve_a_post') }}
            </Button>
        </div>

        <!--        <Button :flat="true" class="save-btn">{{ $t('save') }}</Button>-->
    </div>
</template>

<script>
import InputField from "@/components/Ui/InputField/InputField";
import SelectField from "@/components/Ui/SelectField/SelectField";
import Button from "@/components/Ui/Button/Button";
import InputLabel from "@/components/Ui/InputLabel/InputLabel";
import { ADD_AUTHOR_ACTION, FETCH_ALL_AUTHORS_ACTION } from "@/store/actions/authors";
import { mapActions, mapGetters } from "vuex";
import { apiCall, books } from "@/api/apiCall";
import { showSwalToast, splitName } from "@/utils/utils";

export default {
    name: "ReservationTab",
    components: { Button, SelectField, InputField, InputLabel },
    data() {
        return {
            form: {},
            errors: {},
            checkAsync: false,
            isBookAvailable: false
        }
    },
    computed: {
        ...mapGetters([
            'authorOptions'
        ]),
        async() {
            return this.$store.state.books.async;
        }
    },
    methods: {
        ...mapActions({
            fetchAuthors: FETCH_ALL_AUTHORS_ACTION,
            addAuthorAction: ADD_AUTHOR_ACTION
        }),
        onAddAuthorOption(newOption) {

            let name = splitName(newOption.label);

            this.addAuthorAction({ ...name }).then(newAuthor => {
                if (newAuthor?.id) {
                    this.form.author = newAuthor.id;
                }
            });
        },
        async checkIfAvailable() {
            const { original_title, author } = this.form;

            const errors = {};

            if (!original_title) {
                this.errors['original_title'] = "Morate unijeti naslov";
            }

            if (!author) {
                this.errors['author'] = "Morate odabrati autora";
            }

            this.errors = errors;

            if (Object.keys(errors).length !== 0) return;

            this.checkAsync = true;

            const isAvailable = await apiCall({
                url: `${books}/check_availability?original_title=${original_title}&author_id=${author}`,
            }).then(r => r.json()).then(r => r.is_available).catch(() => this.checkAsync = false)

            this.checkAsync = false;
            this.isBookAvailable = isAvailable;

            if (!isAvailable) {
                showSwalToast('Knjiga nije dostupna.', 'info');
            }
        },
    },
    async beforeMount() {
        if (!this.authorOptions?.length) {
            await this.fetchAuthors();
        }
    }
}
</script>

<style scoped lang="scss">

.reservation-tab {
    padding: 30px;
    box-shadow: $widgetShadow;
    background: #fff;

    .available-form {
        display: flex;
        flex-direction: column;
        max-width: 480px;

        .labeled-input {
            margin-right: 0;
            margin-bottom: 30px;
        }

        button {
            border-radius: 10px;
        }
    }

    .reserve-book {
        display: flex;
        flex-direction: column;
        color: $darkTextColor;
        max-width: 480px;

        .main-message {
            font-weight: bold;
            font-size: 28px;
            margin-top: 30px;
            margin-bottom: 5px;
        }

        .second-message {
            font-weight: bold;
        }

        .reserve-btn {
            margin-bottom: 20px;
            margin-top: 40px;
        }
    }

    .save-btn {
        margin-top: auto;
        margin-left: auto;
    }
}

</style>