import { SET_DRAWER_EXPANDED } from "@/store/actions/menu";
import { ROLES_ACCESS_LEVELS } from "@/utils/consts";

export const RouteLink = {
    Dashboard: "/",
    Login: "/login",
    Books: "/books",
    Books_Authors: "/books/authors",
    Books_Categories: "/books/categories",
    Books_Tags: "/books/tags",
    ManageBook: "/books/manage",
    ManageAuthor: "/books/authors/manage",
    ManageCategory: "/books/categories/manage",
    ManageTag: "/books/tags/manage",
    Publishers: "/publishers",
    Users: "/users",
    // CoinsPackages: '/coins_packages',
    // Bonuses: "/bonuses",
    PromoCodes: "/promo-codes",
    ManagePromoCode: '/promo-codes/manage',
    Reports: "/reports",
    Marketing: "/marketing",
    Settings: "/settings",
    ManageBonus: '/bonuses/manage',
    ManagePublisher: '/publishers/manage',
    ManageCoinsPackage: '/coins_packages/manage',
    ManageUser: '/users/manage',
    AgentBooks: '/agent_books',
    ManageAgentBook: '/agent_books/manage',
    Analytics: '/analytics',
    AppleAnalytics: '/apple_analytics',
    InternalWidgets: "/internal-widgets",
}

export default {
    state: {
        drawerExpanded: true,
        groups: [
            {
                link: RouteLink.Books,
                name: "Knjige",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN,
                items: [{
                    link: RouteLink.Books_Authors,
                    name: "Autori",
                    icon: "",
                }, {
                    link: RouteLink.Books_Categories,
                    name: "Kategorije",
                    icon: "",
                }, {
                    link: RouteLink.Books_Tags,
                    name: "Tagovi",
                    icon: "",
                }]
            }, {
                link: RouteLink.Publishers,
                name: "Izdavači",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
            }, {
                link: RouteLink.Users,
                name: "Korisnici",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
            }, /*{
                link: RouteLink.Bonuses,
                name: "Bonusi",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN,
            }, {
                link: RouteLink.CoinsPackages,
                name: "Paketi",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
            },*/
            {
                link: RouteLink.PromoCodes,
                name: "Promo Kodovi",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
            },
            {
                link: RouteLink.Marketing,
                name: "Marketing",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
            },
            {
                link: RouteLink.AgentBooks,
                name: "Knjige agenata",
                icon: "",
                accessLevel: ROLES_ACCESS_LEVELS.ROLE_SUPER_ADMIN
            },
            {
                link: RouteLink.Analytics,
                name: "Analitika",
                icon: "",
            },
            // {
            //     link: RouteLink.AppleAnalytics,
            //     name: "Apple Analitika",
            //     icon: "",
            // },
        ],
    },
    mutations: {
        [SET_DRAWER_EXPANDED](state, toggle) {
            state.drawerExpanded = toggle;
        }
    },
    actions: {},
    getters: {
        activeItem: (state) => (activeItemPath) => {
            return state.groups.map(g => g.items ? [g, g.items].flat() : g).flat().find(i => i.link === activeItemPath) || null
        },
        isDrawerExpanded: (state) => state.drawerExpanded,
        groupsForUser(state, getters, rootState, rootGetters) {

            return state.groups.filter(g => {
                if (!g.accessLevel) {
                    return true;
                }
                return rootGetters.userAccessLevel >= g.accessLevel
            })
        }
    }
};
