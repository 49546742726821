export const STORAGE_KEYS = {
    TOKEN: 'tvornicasnova_admin_token',
    REFRESH_TOKEN: 'tvornicasnova_admin_refresh_token',
    PER_PAGE: 'tvornicasnova_per_page',
};

export const IS_DEV = process.env.NODE_ENV === 'development';

export const MEDIA_URL = process.env.VUE_APP_STORAGE_URL || 'http://localhost:5000/';

export const BUILD_VERSION = process.env.VUE_APP_BUILD_VERSION;

export const RELEASE_TIMESTAMP = process.env.VUE_APP_RELEASE_TIMESTAMP;

export const ROLES = {
    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    ROLE_PUBLISHER_ADMIN: 'ROLE_PUBLISHER_ADMIN',
    ROLE_SALES_AGENT: 'ROLE_SALES_AGENT',
    ROLE_USER: 'ROLE_USER',
}

export const ROLES_ACCESS_LEVELS = {
    ROLE_SUPER_ADMIN: 3,
    ROLE_PUBLISHER_ADMIN: 2,
    ROLE_SALES_AGENT: 1,
    ROLE_USER: 0
}

export const DISCOUNT_BASE_TYPES = {
    FIXED_BUY_ALL: "FIXED_BUY_ALL",
    VARIABLE: "VARIABLE"
}

export const DISCOUNT_BASE_TYPE_TEXT = {
    FIXED_BUY_ALL: 'Cijela knjiga',
    VARIABLE: 'Varijabilni'
}

export const DISCOUNT_OPERATOR_TYPES = {
    FIXED: "FIXED",
    PERCENTAGE: "PERCENTAGE"
}

export const DISCOUNT_OPERATOR_TYPES_TEXT = {
    FIXED: "Fiksno",
    PERCENTAGE: "Procent"
}

export const BOOK_STATUS = {
    RESERVED: "RESERVED",
    IN_PROGRESS: "IN_PROGRESS",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    PUBLISHED: "PUBLISHED",
    UNPUBLISHED: "UNPUBLISHED",
};

export const PROMOCODE_TYPE = {
    NORMAL: "NORMAL",
    ONESHOT: "ONESHOT",
    REGISTER: "REGISTER",
    ONESHOT_GLOBAL: "ONESHOT_GLOBAL",
};

export const BOOK_STATUS_COLORS = {
    [BOOK_STATUS.RESERVED]: "#FEBF5E",
    [BOOK_STATUS.IN_PROGRESS]: "#FEBF5E",
    [BOOK_STATUS.AWAITING_APPROVAL]: "#4222F1",
    [BOOK_STATUS.APPROVED]: "#4222F1",
    [BOOK_STATUS.REJECTED]: "#F34658",
    [BOOK_STATUS.PUBLISHED]: "#26A53F",
    [BOOK_STATUS.UNPUBLISHED]: "#F34658",
}

export const ERROR_MESSAGES = {
    ERR_IMAGE_RESOLUTION_TOO_BIG: 'Slika prevelika',
    ERR_DISCOUNT_BASE_TYPE_CAN_NOT_BE_APPLIED: 'Samo varijabilni bonus se može primijeniti na kategorije.',
    ERR_BOOK_ALREADY_ASSIGNED_TO_AGENT: "Knjiga je već dodijeljena agentu"
}

export const CENTS_TO_COINS_FACTOR = 1;

export const COINS_TO_CENTS_FACTOR = 1 / CENTS_TO_COINS_FACTOR;
