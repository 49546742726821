// mutations
export const SET_LOGIN_ASYNC = 'SET_LOGIN_ASYNC';
export const SET_LOGIN_ERRORS = 'SET_LOGIN_ERRORS';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_SIGN_UP_ERRORS = 'SET_SIGN_UP_ERRORS';
export const SET_USER = 'SET_USER';

// actions
export const PERFORM_LOGIN_ACTION = 'PERFORM_LOGIN_ACTION';
export const DO_ME_ACTION = 'DO_ME_ACTION';
export const PERFORM_SIGN_UP_ACTION = 'PERFORM_SIGN_UP_ACTION';
export const PERFORM_LOGOUT_ACTION = 'PERFORM_LOGOUT_ACTION';
