import {
    SELECT_CHAPTER_TRANSACTION,
    SET_CHAPTER_TRANSACTIONS,
    FETCH_ALL_CHAPTER_TRANSACTIONS_ACTION,
    SET_CHAPTER_TRANSACTIONS_ASYNC,
    SET_SEARCH_CHAPTER_TRANSACTIONS,
    SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT,
    FETCH_CHAPTER_TRANSACTIONS_PAGE_ACTION,
    APPEND_CHAPTER_TRANSACTIONS_PAGE,
    SEARCH_CHAPTER_TRANSACTIONS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_CHAPTER_TRANSACTION_ACTION,
    ADD_CHAPTER_TRANSACTION_ACTION,
    UPDATE_CHAPTER_TRANSACTION,
    ADD_NEW_CHAPTER_TRANSACTION,
    DELETE_CHAPTER_TRANSACTION_ACTION,
    DELETE_CHAPTER_TRANSACTION,
    SET_CHAPTER_TRANSACTIONS_CURRENT_PAGE,
    SET_TOTAL_PAGES,
    GET_CHAPTER_TRANSACTION_BY_ID_ACTION,
    SET_CHAPTER_TRANSACTIONS_UPLOAD_ABORT_TOKEN,
    SET_CHAPTER_TRANSACTIONS_UPLOAD_PROGRESS,
    SET_ANALYTICS,
    SET_APPLE_ANALYTICS,
    FETCH_ANALYTICS,
    CHANGE_CHAPTER_TRANSACTIONS_PER_PAGE_ACTION, FETCH_APPLE_ANALYTICS,
} from '@/store/actions/chapterTransactions';
import {
    addChapterTransaction,
    deleteChapterTransaction,
    editChapterTransaction,
    fetchChapterTransactionById, fetchChapterTransactionsAnalytics,
    fetchChapterTransactionsPage,
} from '@/api/chapterTransactions/chapterTransactions';
import { getPerPage, searchChapterTransaction, setPerPage } from '@/utils/utils';
import { UPDATE_PER_PAGE } from '@/store/actions';
import { apiFetchAppleAnalytics } from '@/api/analytics/analytics';

export default {
    state: () => ({
        chapterTransactions: [],

        // Filtering
        searchText: null,
        searchChapterTransactions: null,
        searchAsync: false,
        selectedChapterTransactionId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        uploadProgress: null,
        abortToken: null,
        async: false,

        // Analytics
        analytics: [],
        appleAnalytics: [],
    }),
    mutations: {
        [SET_CHAPTER_TRANSACTIONS](state, chapterTransactions) {
            state.chapterTransactions = chapterTransactions;
        },
        [SELECT_CHAPTER_TRANSACTION](state, chapterTransactionId) {
            state.selectedChapterTransactionId = chapterTransactionId;
        },
        [SET_CHAPTER_TRANSACTIONS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_CHAPTER_TRANSACTIONS](state, searchChapterTransactions) {
            state.searchChapterTransactions = searchChapterTransactions;
        },
        [SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_CHAPTER_TRANSACTIONS_PAGE](state, chapterTransactions) {
            if (state.chapterTransactions !== null) {
                state.chapterTransactions = state.chapterTransactions.concat(chapterTransactions);
            } else {
                state.chapterTransactions = [...chapterTransactions];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_CHAPTER_TRANSACTION](state, { id, ...data }) {
            for (let chapterTransaction of state.chapterTransactions) {
                if (chapterTransaction.id === id) {
                    Object.assign(chapterTransaction, data);
                    return;
                }
            }
        },
        [ADD_NEW_CHAPTER_TRANSACTION](state, newChapterTransaction) {
            const index = state.chapterTransactions.findIndex(chapterTransaction => chapterTransaction.id === newChapterTransaction.id);

            if (index === -1) {
                state.chapterTransactions.unshift(newChapterTransaction);
            }
        },
        [DELETE_CHAPTER_TRANSACTION](state, chapterTransactionId) {
            if (state.chapterTransactions !== null) {
                state.chapterTransactions = state.chapterTransactions.filter(chapterTransaction => chapterTransaction.id !== chapterTransactionId);
            }
        },
        [SET_CHAPTER_TRANSACTIONS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
        [SET_CHAPTER_TRANSACTIONS_UPLOAD_ABORT_TOKEN](state, token) {
            state.abortToken = token;
        },
        [SET_CHAPTER_TRANSACTIONS_UPLOAD_PROGRESS](state, progress) {
            state.uploadProgress = progress;
        },
        [SET_ANALYTICS](state, data) {
            state.analytics = data;
        },
        [SET_APPLE_ANALYTICS](state, data) {
            state.appleAnalytics = data;
        }
    },
    actions: {
        async [FETCH_ALL_CHAPTER_TRANSACTIONS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            commit(SET_CHAPTER_TRANSACTIONS, []);
            commit(SET_SEARCH_CHAPTER_TRANSACTIONS, null);
            commit(SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_CHAPTER_TRANSACTIONS_PAGE_ACTION, { page });
            }

            commit(SET_CHAPTER_TRANSACTIONS_CURRENT_PAGE, 1);
            commit(SET_TOTAL_PAGES, page);

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
        },
        async [FETCH_CHAPTER_TRANSACTIONS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': chapterTransactions,
                } = await fetchChapterTransactionsPage(page, state.perPage);
                if (Array.isArray(chapterTransactions)) {
                    commit(APPEND_CHAPTER_TRANSACTIONS_PAGE, chapterTransactions);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch chapterTransactions Page', ex);
            }
        },
        [SEARCH_CHAPTER_TRANSACTIONS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT, null);
                commit(SET_SEARCH_CHAPTER_TRANSACTIONS, null);
            } else {
                commit(SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_CHAPTER_TRANSACTIONS, state.chapterTransactions.filter(chapterTransaction => searchChapterTransaction(chapterTransaction, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_CHAPTER_TRANSACTION_ACTION]({ commit }, { id, ...data }) {
            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            try {
                const updatedChapterTransaction = await editChapterTransaction(id, data);
                commit(UPDATE_CHAPTER_TRANSACTION, updatedChapterTransaction);
            } finally {
                commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
            }
        },
        async [ADD_CHAPTER_TRANSACTION_ACTION]({ state, commit }, data) {
            if (state.async) return;

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            try {
                const newChapterTransaction = await addChapterTransaction(data);
                commit(ADD_NEW_CHAPTER_TRANSACTION, newChapterTransaction);
                return newChapterTransaction;
            } finally {
                commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
            }
        },
        async [DELETE_CHAPTER_TRANSACTION_ACTION]({ commit }, chapterTransactionId) {
            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            try {
                await deleteChapterTransaction(chapterTransactionId);
                commit(DELETE_CHAPTER_TRANSACTION, chapterTransactionId);
            } finally {
                commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
            }
        },
        async [GET_CHAPTER_TRANSACTION_BY_ID_ACTION]({ state, commit }, { chapterTransactionId }) {
            if (state.async) return;

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            const chapterTransaction = await fetchChapterTransactionById(chapterTransactionId).catch(() => commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false));

            if (chapterTransaction) {
                state.chapterTransactions = [chapterTransaction, ...state.chapterTransactions];
                commit(SELECT_CHAPTER_TRANSACTION, chapterTransaction.id);
            }

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
        },
        async [FETCH_ANALYTICS]({ state, commit }, query) {
            if (state.async) return;

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            const analytics = await fetchChapterTransactionsAnalytics(query).catch(() => commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false));

            if (analytics) {
                commit(SET_ANALYTICS, analytics);
            }

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
        },
        async [FETCH_APPLE_ANALYTICS]({ state, commit }, query) {
            if (state.async) return;

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, true);
            const analytics = await apiFetchAppleAnalytics(query).catch(() => commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false));

            if (analytics) {
                commit(SET_APPLE_ANALYTICS, analytics);
            }

            commit(SET_CHAPTER_TRANSACTIONS_ASYNC, false);
        },
        async [CHANGE_CHAPTER_TRANSACTIONS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);

            commit(UPDATE_PER_PAGE);

            dispatch(FETCH_ALL_CHAPTER_TRANSACTIONS_ACTION);
        }
    },
    getters: {
        selectedChapterTransaction(state) {
            return state.chapterTransactions.find(b => b.id === state.selectedChapterTransactionId);
        },
        chapterTransactionOptions(state) {
            return state.chapterTransactions.map(m => ({ label: m.name, value: m.id }));
        },
        allChapterTransactions(state) {
            if (state.searchChapterTransactions !== null) {
                return state.searchChapterTransactions;
            } else {
                return state.chapterTransactions;
            }
        },
        paginatedChapterTransactions(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allChapterTransactions.slice(beginIndex, endIndex);
        },
        totalChapterTransactionsPages(state) {
            return state.totalPages;
        },
        chapterTransactionsAsync(state) {
            return state.async;
        },
        analytics(state) {
            return state.analytics;
        },
        appleAnalytics(state) {
            return state.appleAnalytics;
        }
    },
};
