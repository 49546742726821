<template>
    <div class="tvornica-snova uploader">
        <Button :disabled="async" class="add-button" :inverted="true" @click="onAddImageClicked">
            Dodaj Sliku
        </Button>
        <input @change="onImagePicked" :multiple="false" type="file" accept=".jpg,.jpeg,.png" style="display: none"
               ref="fileInput"/>
        <div class="files-container">
            <div class="file" v-for="file in value" :key="file.name">
                <img class="img-preview" :src="file.url" v-if="!async"/>
                <div v-else class="spinner">
                    <vue-ellipse-progress
                        :legendFormatter="({currentValue}) => ''"
                        :thickness="5"
                        emptyThickness="10%"
                        color="#4122F0"
                        :size="40"
                        :progress="progress" />
                    <div class="cancel-btn" v-if="cancellable" @click="$emit('cancel')">
                        <i class="icon-close"/>
                    </div>
                </div>
                <span class="name">{{ file.file.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Ui/Button/Button';
import { readFileToDataUrl } from '@/utils/utils';

export default {
    name: 'Uploader',
    components: { Button },
    props: {
        progress: Number,
        async: Boolean,
        value: Array,
    },
    computed: {
        cancellable() {
            return this.async === true && this.progress > 97;
        }
    },
    methods: {
        async onImagePicked(e) {
            const files = e.target.files;

            const retFiles = [];

            if (files.length > 0) {
                for (let file of files) {
                    const url = await readFileToDataUrl(file);
                    retFiles.push({
                        url,
                        file,
                    });
                }
            }

            this.$emit("input", retFiles);
        },
        onAddImageClicked() {
            this.$refs.fileInput.click();
        },
    },
};
</script>

<style scoped lang="scss">
div.tvornica-snova.uploader {
    .add-button {
        width: 100%;
        margin-top: 8px;
    }

    .files-container {
        margin-top: 14px;

        .file {
            display: flex;
            align-items: center;

            .img-preview {
                height: 77px;
                width: 77px;
                border: 1px solid rgba(151, 151, 151, 0.177775);
                border-radius: 4px;
                object-fit: cover;
                background: #F8FAFB;
            }

            .spinner {
                position: relative;
                height: 77px;
                width: 77px;
                border: 1px solid rgba(151, 151, 151, 0.177775);
                border-radius: 4px;
                object-fit: cover;
                background: #F8FAFB;
                display: flex;
                align-items: center;
                justify-content: center;

                .cancel-btn {
                    position: absolute;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin: auto;
                }
            }

            .name {
                margin-left: 20px;
                font-weight: 600;
                font-size: 14px;
                color: #34335B;
            }
        }
    }
}
</style>
