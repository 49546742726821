<template>
    <div class="bonus-tab">
        <input-label label="Dodaj bonus" class="all-discounts-select">
            <select-field :value="selectedDiscount"
                          @input="addDiscountToBook"
                          :options="discountOptions"/>
        </input-label>
        <custom-table :select="true"
                      :fields="tableFields"
                      :data="actualBookDiscounts"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:type="props">
                <span>{{ props.item.base | parseDiscountType }}</span>
            </template>

            <template v-slot:valid_from="props">
                <span>{{ props.item.valid_from | moment('DD.MM.YYYY') }}</span>
            </template>

            <template v-slot:valid_to="props">
                <span>{{ props.item.valid_to | moment('DD.MM.YYYY') }}</span>
            </template>

            <template v-slot:is_enabled="props">
                <TSSwitch :value="props.item.isEnabled" @input="toggleDiscountEnabled(props.item)"/>
            </template>

            <template v-slot:actions="props">
                <div>
                    <dropdown-menu anchor="right">
                        <template v-slot:handle>
                            <i style="font-size: 15px" class="icon icon-menudots"/>
                        </template>

                        <dropdown-menu-item @click="removeDiscountFromBook(props.item.bookDiscountId)">
                            Izbriši
                        </dropdown-menu-item>
                    </dropdown-menu>
                </div>
            </template>
        </custom-table>
    </div>
</template>

<script>
import CustomTable from "@/components/Ui/CustomTable/CustomTable";
import DropdownMenu from "@/components/Ui/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "@/components/Ui/DropdownMenu/DropdownMenuItem";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
    SEARCH_DISCOUNTS_ACTION,
    SELECT_DISCOUNT,
    SET_DISCOUNTS_CURRENT_PAGE,
} from "@/store/actions/discounts";
import { RouteLink } from "@/store/modules/menu";
import { showAreYouSureSwal, showSuccessSwal, showSwalToast } from "@/utils/utils";
import InputLabel from "@/components/Ui/InputLabel/InputLabel";
import SelectField from "@/components/Ui/SelectField/SelectField";
import {
    ADD_BOOK_DISCOUNT_ACTION,
    DELETE_BOOK_DISCOUNT_ACTION,
    EDIT_BOOK_ACTION,
    TOGGLE_BOOK_DISCOUNT_ACTION
} from "@/store/actions/books";
import TSSwitch from "@/components/Ui/TSSwitch/TSSwitch";

export default {
    name: "BonusTab",
    components: { TSSwitch, SelectField, InputLabel, DropdownMenuItem, DropdownMenu, CustomTable },
    data() {
        return {
            selectedDiscount: null,
            tableFields: [{
                name: 'title',
                title: 'Naziv',
                width: '15%',
            }, {
                name: 'amount',
                title: 'Iznos',
                width: '10%',
            }, {
                name: 'base',
                title: 'Tip popusta',
                slot: 'type',
                width: '15%',
            }, {
                name: 'valid_from',
                title: 'Početak',
                slot: "valid_from",
                width: '15%',
            }, {
                name: 'valid_to',
                title: 'Kraj',
                slot: "valid_to",
                width: '15%',
            }, {
                name: 'is_enabled',
                title: 'Aktivan',
                slot: "is_enabled",
            }, {
                name: 'actions',
                title: '',
                width: '3%',
                lightText: true,
                slot: 'actions',
            }]
        };
    },
    computed: {
        ...mapGetters([
            'selectedBook',
            'discountOptions'
        ]),
        async() {
            return this.$store.state.discounts.async;
        },
        actualBookDiscounts() {
            return this.selectedBook?.book_discounts.map(bd => ({
                bookDiscountId: bd.id,
                isEnabled: bd.is_enabled,
                ...bd.discount
            }));
        },
        bookDiscountIds() {
            return this.actualBookDiscounts.map(bd => bd.bookDiscountId);
        },
        actualDiscountIds() {
            return this.actualBookDiscounts.map(bd => bd.id);
        }
    },
    methods: {
        ...mapMutations({
            changeDiscountsPage: SET_DISCOUNTS_CURRENT_PAGE,
            selectDiscount: SELECT_DISCOUNT,
        }),
        ...mapActions({
            searchDiscountsAction: SEARCH_DISCOUNTS_ACTION,
            addBookDiscountAction: ADD_BOOK_DISCOUNT_ACTION,
            deleteBookDiscountAction: DELETE_BOOK_DISCOUNT_ACTION,
            toggleBookDiscountAction: TOGGLE_BOOK_DISCOUNT_ACTION,
            editBookAction: EDIT_BOOK_ACTION
        }),
        goToManageDiscount() {
            this.$router.push(RouteLink.ManageBonus);
        },
        onTableSelectItem(itemId) {
            console.log(itemId)
        },
        selectAllChanged(data) {
            console.log(data);
        },
        editItem(id) {
            this.selectDiscount(id);
            this.$router.push(RouteLink.ManageBonus);
        },
        addDiscountToBook(discountId) {

            if (this.actualDiscountIds.includes(discountId)) {
                showSwalToast('Bonus već postoji na knjizi.', 'info');
                return;
            }

            this.addBookDiscountAction({
                bookId: this.selectedBook.id,
                discountId
            }).then(() => {
                this.selectedDiscount = null;       // clear select
            })
        },
        removeDiscountFromBook(bookDiscountId) {

            showAreYouSureSwal({ text: "Jeste li sigurni da želite izbrisati bonus sa knjige?" }).then(result => {
                if (result.value) {
                    this.deleteBookDiscountAction({ bookId: this.selectedBook.id, bookDiscountId }).then(() => {
                        showSuccessSwal();
                    })
                }
            });
        },
        toggleDiscountEnabled(bookDiscount) {

            const { bookDiscountId, isEnabled } = bookDiscount;

            showAreYouSureSwal({
                text: `Jeste li sigurni da želite ${isEnabled ? "isključiti" : "uključiti"} bonus?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.toggleBookDiscountAction({ bookId: this.selectedBook.id, bookDiscountId, toggle: !isEnabled })
                }
            });
        },
    },
}
</script>

<style scoped lang="scss">

.bonus-tab {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding-top: 15px;
    height: 100%;

    .all-discounts-select {
        margin-left: auto;
        width: 400px;
    }

}

</style>
