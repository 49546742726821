import { apiCall, payment_requests } from '@/api/apiCall';

export function apiSetAppleProfitForPeriod(from_date, to_date, profit) {
    return apiCall({
        url: `${payment_requests}/set_apple_profit_for_period`,
        method: 'PUT',
        jsonData: {
            from_date,
            to_date,
            profit,
        },
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
