// actions
export const FETCH_ALL_USERS_ACTION = 'FETCH_ALL_USERS_ACTION';
export const FETCH_USERS_PAGE_ACTION = 'FETCH_USERS_PAGE_ACTION';
export const SEARCH_USERS_ACTION = 'SEARCH_USERS_ACTION';
export const TOGGLE_USER_ENABLED_ACTION = 'TOGGLE_USER_ENABLED_ACTION';
export const EDIT_USER_ACTION = 'EDIT_USER_ACTION';
export const ADD_USER_ACTION = 'ADD_USER_ACTION';
export const CHANGE_USER_PASSWORD_ACTION = 'CHANGE_USER_PASSWORD_ACTION';
export const DELETE_USER_ACTION = 'DELETE_USER_ACTION';
export const GET_USER_BY_ID_ACTION = 'GET_USER_BY_ID_ACTION';
export const CHANGE_USERS_PER_PAGE_ACTION = 'CHANGE_USERS_PER_PAGE_ACTION';

// mutations
export const SET_USERS = 'SET_USERS';
export const SELECT_USER = 'SELECT_USER';
export const SELECT_CAM = 'SELECT_CAM';
export const SET_USERS_ASYNC = 'SET_USERS_ASYNC';
export const SET_SEARCH_USERS = 'SET_SEARCH_USERS';
export const SET_USERS_SEARCH_TEXT = 'SET_USERS_SEARCH_TEXT';
export const APPEND_USERS_PAGE = 'APPEND_USERS_PAGE';
export const SET_SELECTED_USERS = 'SET_SELECTED_USERS';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_USERS_TOTAL_PAGES = 'SET_USERS_TOTAL_PAGES';
export const SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE';

