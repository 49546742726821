<template>
    <div class="ts-col" :style="style">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TSCol",
    props: {
        span: Number
    },
    computed: {
        style() {
            return {
                flexBasis: this.span ? (this.span / 24) * 100 + '%' : '50%',
                flexGrow: this.span !== undefined ? 1 : 2
            };
        }
    }
}
</script>

<style scoped lang="scss">

.ts-col {
    display: flex;
    flex-direction: column;
}

</style>