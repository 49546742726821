import { apiCall, book_discounts } from '@/api/apiCall';

export async function fetchBookDiscountsPage(page = 1, perPage = 10) {
    let url = new URL(book_discounts);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editBookDiscount(bookDiscountId, data) {
    return apiCall({
        url: book_discounts + `/${bookDiscountId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addBookDiscount({ bookId, discountId }) {
    return apiCall({
        url: book_discounts,
        method: 'POST',
        jsonData: {
            book: bookId,
            discount: discountId
        },
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteBookDiscount(bookDiscountId) {
    return apiCall({
        url: book_discounts + `/${bookDiscountId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchBookDiscountById(bookDiscountId) {
    return apiCall({
        url: `${book_discounts}/${bookDiscountId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function toggleBookDiscountEnabled(bookDiscountId, toggle) {
    return apiCall({
        url: `${book_discounts}/${bookDiscountId}/${toggle ? 'enable' : 'disable'}`,
        method: "PUT",
        jsonData: {},
        headers: {
            accept: 'application/json',
        },
    })
        .then(r => r.json());
}
