<script>

export default {
    name: 'CustomModal',
    methods: {
        close() {
            this.$emit('close');
        },
        onModalClick(e) {
            e.preventDefault();
            e.stopPropagation();
        }
    },
};
</script>

<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click="close">
            <div class="modal"
                 @click="onModalClick"
                 role="dialog"
                 aria-labelledby="modalTitle"
                 aria-describedby="modalDescription">
                <header class="modal-header" id="modalTitle">
                    <slot name="header">
                        This is the default tile!
                    </slot>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal">
                        &#x2715;
                    </button>
                </header>

                <section class="modal-body">
                    <slot name="body">
                        This is the default body!
                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">
                        This is the default footer!
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<style scoped lang="scss">
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        justify-content: space-between;
    }

    .modal-body {
        position: relative;
        padding: 10px 10px 0 10px;
        flex: 1;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: $mainAccentColor;
        background: transparent;
    }
}


.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .25s ease;
}
</style>
