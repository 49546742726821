import { analytics, apiCall } from '@/api/apiCall';

export async function apiFetchAppleAnalytics(query) {
    const url = `${analytics}/apple${query ? '?' + query : ''}`

    return apiCall({
        url: url,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
