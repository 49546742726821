// actions
export const FETCH_ALL_AUTHORS_ACTION = 'FETCH_ALL_AUTHORS_ACTION';
export const FETCH_AUTHORS_PAGE_ACTION = 'FETCH_AUTHORS_PAGE_ACTION';
export const SEARCH_AUTHORS_ACTION = 'SEARCH_AUTHORS_ACTION';
export const ADD_AUTHOR_ACTION = 'ADD_AUTHOR_ACTION';
export const EDIT_AUTHOR_ACTION = 'EDIT_AUTHOR_ACTION';
export const DELETE_AUTHOR_ACTION = 'DELETE_AUTHOR_ACTION';
export const CHANGE_AUTHOR_IMAGE_ACTION = 'CHANGE_AUTHOR_IMAGE_ACTION';
export const GET_AUTHOR_BY_ID_ACTION = 'GET_AUTHOR_BY_ID_ACTION';
export const CHANGE_AUTHORS_PER_PAGE_ACTION = 'CHANGE_AUTHORS_PER_PAGE_ACTION';

// mutations
export const SET_AUTHORS = 'SET_AUTHORS';
export const SELECT_AUTHOR = 'SELECT_AUTHOR';
export const SET_AUTHORS_ASYNC = 'SET_AUTHORS_ASYNC';
export const SET_SEARCH_AUTHORS = 'SET_SEARCH_AUTHORS';
export const SET_AUTHORS_SEARCH_TEXT = 'SET_AUTHORS_SEARCH_TEXT';
export const APPEND_AUTHORS_PAGE = 'APPEND_AUTHORS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_AUTHOR = 'UPDATE_AUTHOR';
export const ADD_NEW_AUTHOR = 'ADD_NEW_AUTHOR';
export const DELETE_AUTHOR = 'DELETE_AUTHOR';
export const SET_AUTHORS_CURRENT_PAGE = 'SET_AUTHORS_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
