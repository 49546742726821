<template>
    <div class="promo-codes">
        <div class="table-filters">
            <span>Pokaži</span>
            <container class="per-page">
                <select-field :options="perPageOptions"
                              @input="changePromocodesPerPage"
                              :filterable="false"
                              :clearable="false"
                              :value="perPage"/>
            </container>
            <container>
                <input-field
                    class="table-search-input"
                    @input="searchPromocodesAction"
                    type="text"
                    icon="icon-search"
                    :value="searchText"
                    placeholder="Pretraži Promo Kodove"/>
            </container>

            <container>
                <Button :flat="true" @click="goToManagePromocode">
                    Dodaj Promo Kod
                </Button>
            </container>
        </div>

        <custom-table :select="true"
                      :fields="tableFields"
                      :data="promocodesPage"
                      v-if="!async"
                      @column-click="item => editItem(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:type="props">
                <span>{{ props.item.type | parsePromocodeType }}</span>
            </template>

            <template v-slot:enabled="props">
                <span v-if="props.item.enabled">
                    Aktivan
                </span>
                <span v-else>
                    Neaktivan
                </span>
            </template>

            <template v-slot:expires_at="props">
                <span v-if="props.item.expires_at === null">
                    Nikada
                </span>
                <span v-else>
                    {{ props.item.expires_at | moment('DD.MM.YYYY') }}
                </span>
            </template>

            <template v-slot:actions="props">
                <div>
                    <dropdown-menu anchor="right">
                        <template v-slot:handle>
                            <i style="font-size: 15px" class="icon icon-menudots"/>
                        </template>

                        <dropdown-menu-item @click="editItem(props.item.id)">
                            Edituj
                        </dropdown-menu-item>
                        <dropdown-menu-item @click="deletePromocode(props.item.id)">
                            Izbriši
                        </dropdown-menu-item>
                    </dropdown-menu>
                </div>
            </template>
        </custom-table>

        <div class="promocodes-loader-container" v-else>
            <spinner/>
        </div>

        <div class=" footer">
            <custom-pagination :total-pages="totalPages"
                               :current-page="currentPage"
                               @change="changePromocodesPage"/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
    CHANGE_PROMOCODES_PER_PAGE_ACTION, DELETE_PROMOCODE_ACTION,
    FETCH_ALL_PROMOCODES_ACTION,
    SEARCH_PROMOCODES_ACTION, SELECT_PROMOCODE, SET_PROMOCODES_CURRENT_PAGE,
} from '@/store/actions/promoCodes';
import Spinner from '@/components/Ui/Spinner/Spinner';
import DropdownMenuItem from '@/components/Ui/DropdownMenu/DropdownMenuItem';
import DropdownMenu from '@/components/Ui/DropdownMenu/DropdownMenu';
import Button from '@/components/Ui/Button/Button';
import SelectField from '@/components/Ui/SelectField/SelectField';
import InputField from '@/components/Ui/InputField/InputField';
import Container from '@/components/Ui/Container/Container';
import CustomPagination from '@/components/CustomPagination/CustomPagination';
import CustomTable from '@/components/Ui/CustomTable/CustomTable';
import { RouteLink } from '@/store/modules/menu';
import { showAreYouSureSwal, showSuccessSwal } from '@/utils/utils';

export default {
    name: 'PromoCodes',
    components: {
        Spinner,
        DropdownMenuItem,
        DropdownMenu,
        Button,
        SelectField,
        InputField,
        Container,
        CustomPagination,
        CustomTable,
    },
    data() {
        return {
            tableFields: [{
                name: 'name',
                title: 'Ime',
                width: '25%',
                enableClick: true,
            }, {
                name: 'discount_percent',
                title: 'Popust %',
                width: '10%',
            }, {
                name: 'discount_apple_tiers',
                title: 'Popust Apple',
                width: '15%',
            }, {
                name: 'expires_at',
                title: 'Ističe u',
                slot: 'expires_at',
                width: '15%',
            }, {
                name: 'enabled',
                title: 'Aktivan',
                slot: 'enabled',
                width: '15%',
            }, {
                name: 'type',
                title: 'Tip',
                slot: 'type',
                width: '10%',
            }, {
                name: 'actions',
                title: '',
                width: '3%',
                lightText: true,
                slot: 'actions',
            }],
        };
    },
    computed: {
        ...mapGetters({
            promocodesPage: 'paginatedPromocodes',
            totalPages: 'totalPromocodesPages',
        }),
        async() {
            return this.$store.state.promoCodes.async;
        },
        searchText() {
            return this.$store.state.promoCodes.searchText;
        },
        currentPage() {
            return this.$store.state.promoCodes.currentPage;
        },
        perPage() {
            return this.$store.state.promoCodes.perPage;
        },
        perPageOptions() {
            return [10, 25, 50];
        },
    },
    methods: {
        ...mapMutations({
            changePromocodesPage: SET_PROMOCODES_CURRENT_PAGE,
            selectPromocode: SELECT_PROMOCODE,
        }),
        ...mapActions({
            fetchAllPromoCodesAction: FETCH_ALL_PROMOCODES_ACTION,
            searchPromocodesAction: SEARCH_PROMOCODES_ACTION,
            deletePromocodeAction: DELETE_PROMOCODE_ACTION,
            changePromocodesPerPage: CHANGE_PROMOCODES_PER_PAGE_ACTION,
        }),
        goToManagePromocode() {
            this.$router.push(RouteLink.ManageBonus);
        },
        onTableSelectItem(itemId) {
            console.log(itemId);
        },
        selectAllChanged(data) {
            console.log(data);
        },
        editItem(id) {
            this.selectPromocode(id);
            this.$router.push(RouteLink.ManageBonus + `/${id}`);
        },
        deletePromocode(promocodeId) {
            showAreYouSureSwal({ text: 'Jeste li sigurni da želite izbrisati promo kod?' }).then(result => {
                if (result.value) {
                    this.deletePromocodeAction(promocodeId).then(() => showSuccessSwal());
                }
            });
        },
    },
    mounted() {
        this.fetchAllPromoCodesAction();
    },
};
</script>

<style lang="scss">
.promo-codes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .promocodes-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
