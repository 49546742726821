import { apiCall, tags } from '@/api/apiCall';

export async function fetchTagsPage(page = 1, perPage = 10) {
    let url = new URL(tags);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editTag(tagId, data) {
    return apiCall({
        url: tags + `/${tagId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addTag(data) {
    return apiCall({
        url: tags,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteTag(tagId) {
    return apiCall({
        url: tags + `/${tagId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchTagById(tagId) {
    return apiCall({
        url: `${tags}/${tagId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}