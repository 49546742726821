import {
    SELECT_AUTHOR,
    SET_AUTHORS,
    FETCH_ALL_AUTHORS_ACTION,
    SET_AUTHORS_ASYNC,
    SET_SEARCH_AUTHORS,
    SET_AUTHORS_SEARCH_TEXT,
    FETCH_AUTHORS_PAGE_ACTION,
    APPEND_AUTHORS_PAGE,
    SEARCH_AUTHORS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_AUTHOR_ACTION,
    ADD_AUTHOR_ACTION,
    UPDATE_AUTHOR,
    ADD_NEW_AUTHOR,
    DELETE_AUTHOR_ACTION,
    DELETE_AUTHOR,
    SET_AUTHORS_CURRENT_PAGE,
    SET_TOTAL_PAGES,
    CHANGE_AUTHOR_IMAGE_ACTION,
    GET_AUTHOR_BY_ID_ACTION, CHANGE_AUTHORS_PER_PAGE_ACTION,
} from '@/store/actions/authors';
import {
    addAuthor, changeAuthorImage,
    deleteAuthor,
    editAuthor, fetchAuthorById,
    fetchAuthorsPage,
} from '@/api/authors/authors';
import { getPerPage, mergeName, searchAuthor, setPerPage } from '@/utils/utils';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        authors: [],

        // Filtering
        searchText: null,
        searchAuthors: null,
        searchAsync: false,
        selectedAuthorId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        async: false,
    }),
    mutations: {
        [SET_AUTHORS](state, Authors) {
            state.authors = Authors;
        },
        [SELECT_AUTHOR](state, authorId) {
            state.selectedAuthorId = authorId;
        },
        [SET_AUTHORS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_AUTHORS](state, searchAuthors) {
            state.searchAuthors = searchAuthors;
        },
        [SET_AUTHORS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_AUTHORS_PAGE](state, Authors) {
            if (state.authors !== null) {
                state.authors = state.authors.concat(Authors);
            } else {
                state.authors = [...Authors];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_AUTHOR](state, { id, ...data }) {
            for (let author of state.authors) {
                if (author.id === id) {
                    Object.assign(author, data);
                    return;
                }
            }
        },
        [ADD_NEW_AUTHOR](state, newauthor) {
            const index = state.authors.findIndex(author => author.id === newauthor.id);

            if (index === -1) {
                state.authors.unshift(newauthor);
            }
        },
        [DELETE_AUTHOR](state, authorId) {
            if (state.authors !== null) {
                state.authors = state.authors.filter(author => author.id !== authorId);
            }
        },
        [SET_AUTHORS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
    },
    actions: {
        async [FETCH_ALL_AUTHORS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_AUTHORS_ASYNC, true);
            commit(SET_AUTHORS, []);
            commit(SET_SEARCH_AUTHORS, null);
            commit(SET_AUTHORS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_AUTHORS_PAGE_ACTION, { page });
            }

            commit(SET_AUTHORS_CURRENT_PAGE, 1);
            commit(SET_TOTAL_PAGES, page);

            commit(SET_AUTHORS_ASYNC, false);
        },
        async [FETCH_AUTHORS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Authors,
                } = await fetchAuthorsPage(page, state.perPage);
                if (Array.isArray(Authors)) {
                    commit(APPEND_AUTHORS_PAGE, Authors);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Authors Page', ex);
            }
        },
        [SEARCH_AUTHORS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_AUTHORS_SEARCH_TEXT, null);
                commit(SET_SEARCH_AUTHORS, null);
            } else {
                commit(SET_AUTHORS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_AUTHORS, state.authors.filter(author => searchAuthor(author, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_AUTHOR_ACTION]({ commit }, { id, ...data }) {
            commit(SET_AUTHORS_ASYNC, true);
            try {
                const updatedAuthor = await editAuthor(id, data);
                commit(UPDATE_AUTHOR, updatedAuthor);
            } finally {
                commit(SET_AUTHORS_ASYNC, false);
            }
        },
        async [ADD_AUTHOR_ACTION]({ commit }, data) {
            commit(SET_AUTHORS_ASYNC, true);
            try {
                const newAuthor = await addAuthor(data);
                commit(ADD_NEW_AUTHOR, newAuthor);
                return newAuthor;
            } finally {
                commit(SET_AUTHORS_ASYNC, false);
            }
        },
        async [DELETE_AUTHOR_ACTION]({ commit }, authorId) {
            commit(SET_AUTHORS_ASYNC, true);
            try {
                await deleteAuthor(authorId);
                commit(DELETE_AUTHOR, authorId);
            } finally {
                commit(SET_AUTHORS_ASYNC, false);
            }
        },
        async [CHANGE_AUTHOR_IMAGE_ACTION]({ commit }, { authorId, image }) {
            commit(SET_AUTHORS_ASYNC, true);
            try {
                const updatedImage = await changeAuthorImage(authorId, image);
                commit(UPDATE_AUTHOR, { id: authorId, ...updatedImage });
            } finally {
                commit(SET_AUTHORS_ASYNC, false);
            }
        },
        async [GET_AUTHOR_BY_ID_ACTION]({ state, commit }, { authorId }) {
            if (state.async) return;

            commit(SET_AUTHORS_ASYNC, true);
            const author = await fetchAuthorById(authorId).catch(() => commit(SET_AUTHORS_ASYNC, false));

            if (author) {
                state.authors = [author, ...state.authors];
                commit(SELECT_AUTHOR, author.id);
            }

            commit(SET_AUTHORS_ASYNC, false);
        },
        async [CHANGE_AUTHORS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);

            commit(UPDATE_PER_PAGE);

            dispatch(FETCH_ALL_AUTHORS_ACTION);
        }
    },
    getters: {
        selectedAuthor(state) {
            return state.authors.find(b => b.id === state.selectedAuthorId);
        },
        authorOptions(state) {
            return state.authors.map(a => ({ label: mergeName(a), value: a.id }));
        },
        allAuthors(state) {
            if (state.searchAuthors !== null) {
                return state.searchAuthors;
            } else {
                return state.authors;
            }
        },
        paginatedAuthors(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allAuthors.slice(beginIndex, endIndex);
        },
        totalAuthorsPages(state) {
            return state.totalPages;
        },
        authorsAsync(state) {
            return state.async;
        }
    },
};
