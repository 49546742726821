<template>
    <div class="analytics">
        <div class="table-filters">
            <span>Prikaži Za: </span>

            <container>
                <select-field v-model="selectedYear"
                              :clearable="true"
                              :options="yearOptions"/>
            </container>

            <container>
                <select-field v-model="selectedMonth"
                              :clearable="false"
                              :disabled="selectedYear === null"
                              :options="monthOptions"/>
            </container>

            <div class="buttons-container">
                <add-apple-analytic-modal @refresh="fetchFilteredAnalytics" v-if="isAdmin"/>

                <Button class="apply-btn" @click="fetchFilteredAnalytics">
                    Primijeni
                </Button>
            </div>
        </div>
        <admin-analytics v-if="isAdmin"/>
        <publisher-analytics v-else-if="isPubAdmin"/>
        <sales-agent-analytics v-else/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isPublisherAdmin, isSuperAdmin } from '@/utils/utils';
import SalesAgentAnalytics from '@/views/Analytics/components/SalesAgentAnalytics';
import PublisherAnalytics from '@/views/Analytics/components/PublisherAnalytics';
import { FETCH_ANALYTICS } from '@/store/actions/chapterTransactions';
import Container from '@/components/Ui/Container/Container';
import Button from '@/components/Ui/Button/Button';
import AdminAnalytics from '@/views/Analytics/components/AdminAnalytics';
import SelectField from '@/components/Ui/SelectField/SelectField';
import 'moment/locale/bs';
import moment from 'moment';
import { capitalize } from 'lodash';
import AddAppleAnalyticModal from '@/views/AppleAnalytics/AddAppleAnalyticModal';

const ALL_YEAR_OPTION = 'all_year';

export default {
    name: 'Analytics',
    data() {
        return {
            selectedDate: this.$moment().format('YYYY-MM'),
            selectedYear: null,
            selectedMonth: ALL_YEAR_OPTION,
        };
    },
    components: {
        AddAppleAnalyticModal,
        SelectField,
        AdminAnalytics,
        Button,
        Container,
        SalesAgentAnalytics,
        PublisherAnalytics,
    },
    computed: {
        ...mapGetters([
            'user',
            'analytics',
        ]),
        isAdmin() {
            return isSuperAdmin(this.user);
        },
        isPubAdmin() {
            return isPublisherAdmin(this.user);
        },
        currentDate() {
            return this.$moment().format('YYYY-MM');
        },
        yearOptions() {
            const thisYear = this.$moment().year();

            const options = [];

            for (let i = thisYear; i >= 2021; i--) {
                options.push({
                    label: i,
                    value: i,
                });
            }

            return options;
        },
        monthOptions() {

            return [
                {
                    label: 'Čitava godina',
                    value: ALL_YEAR_OPTION,
                }, ...moment.months().map(longname => {

                    const date = moment(longname, 'MMMM').locale('bs');

                    return {
                        label: capitalize(date.format('MMMM')),
                        value: date.month().toString(),
                    };
                }),
            ];
        },
    },
    methods: {
        ...mapActions({
            fetchAnalytics: FETCH_ANALYTICS,
        }),
        fetchFilteredAnalytics() {

            const isSelectedFullYear = this.selectedMonth === ALL_YEAR_OPTION;

            const monthNum = Number(this.selectedMonth);

            const date = moment([this.selectedYear, isSelectedFullYear ? 0 : monthNum]);

            const fromDate = date.toISOString();
            const toDate = date.add(1, isSelectedFullYear ? 'year' : 'month').toISOString();

            const query = date.isValid() ? `from_date=${fromDate}&to_date=${toDate}` : null;

            this.fetchAnalytics(query);
        },
    },
    beforeMount() {
        if (!this.analytics.length) {
            this.fetchAnalytics();
        }
    },
};
</script>

<style scoped lang="scss">

.analytics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;

    .table-filters {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }

        .select-container {
            min-width: 150px;
        }

        .to-date {
            margin-left: 20px;
        }

        .buttons-container {
            margin-left: auto;
            display: flex;

            .apply-btn {
                margin-left: 8px;
            }
        }
    }
}
</style>
