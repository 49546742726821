<template>
    <div class="tab" v-show="isActive" v-if="!disabled">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        name: { required: true },
        selected: { default: false },
        disabled: { default: false },
    },
    data() {
        return {
            isActive: false,
        };

    },
    mounted() {
        this.isActive = this.selected;
    },
};
</script>

<style scoped lang="scss">
.tab {
    user-select: none;
    flex: 1;
    height: 100%;
}

</style>
