import { apiCall, discounts } from '@/api/apiCall';

export async function fetchDiscountsPage(page = 1, perPage = 10) {
    let url = new URL(discounts);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editDiscount(discountId, data) {
    return apiCall({
        url: discounts + `/${discountId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addDiscount(data) {
    return apiCall({
        url: discounts,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteDiscount(discountId) {
    return apiCall({
        url: discounts + `/${discountId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchDiscountById(discountId) {
    return apiCall({
        url: `${discounts}/${discountId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function applyDiscount(discountId) {
    return apiCall({
        url: discounts + `/${discountId}/apply`,
        method: 'PUT',
        jsonData: {},
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}