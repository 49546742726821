<template>
    <div class="header">
        <div class="route-title">
            <span class="main">{{ routeMainName }}</span>
            <span v-if="routeSubName">|</span>
            <span class="secondary" v-if="routeSubName">{{ routeSubName }}</span>
        </div>
    </div>
</template>

<script>
import { SET_DRAWER_EXPANDED } from "@/store/actions/menu";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Header",
    computed: {
        ...mapGetters([
            'isDrawerExpanded',
            'activeItem'
        ]),
        userProfilePicture() {
            return null;
        },
        routeItemMeta() {
            return this.$route.meta;
        },
        routeMainName() {
            return this.routeItemMeta?.mainName;
        },
        routeSubName() {
            return this.routeItemMeta?.subName;
        }
    },
    methods: {
        ...mapMutations({
            setDrawerExpanded: SET_DRAWER_EXPANDED
        }),
        toggleDrawerExpanded() {
            this.setDrawerExpanded(!this.isDrawerExpanded);
        },
        toggleUserDropdown() {
            this.userDropdownOpened = !this.userDropdownOpened;
        }
    }
};

</script>

<style scoped lang="scss">

.header {
    position: absolute;
    top: 0;
    left: $drawerWidth;
    right: 0;
    height: $headerHeight;
    padding: 10px 22px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: all 250ms ease-in-out;

    .header-item {
        display: flex;
        align-items: center;
    }

    .route-title {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 23px;

        .main {
            font-weight: 300;
            margin-right: 5px;
        }

        .secondary {
            font-weight: 700;
            margin-left: 5px;
        }
    }
}

</style>
