<template>
    <div class="manage-agent-book">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="agent-book-title">Nova stavka</div>
        <div class="agent-book-status">
        </div>
        <container class="body-container">

            <div class="col">
                <input-label :required="true" :error="errors.sales_agent" label="Agent">
                    <select-field v-model="form.sales_agent" :options="salesAgentsOptions"/>
                </input-label>
                <input-label :required="true" :error="errors.book" label="Knjiga">
                    <select-field v-model="form.book" :options="bookOptions"/>
                </input-label>
                <input-label label="Postotak zarade (%)">
                    <input-field v-model="form.income_percent" type="number" :min="0" :max="100"/>
                </input-label>
            </div>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <Button class="save-button" @click="submit" :loading="async">
                Snimi
            </Button>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference, mergeName } from '@/utils/utils';
import {
    ADD_AGENT_BOOK_ACTION,
    EDIT_AGENT_BOOK_ACTION,
    GET_AGENT_BOOK_BY_ID_ACTION,
    CHANGE_AGENT_BOOK_IMAGE_ACTION,
    SELECT_AGENT_BOOK,
} from '@/store/actions/agentBooks';
import { cloneDeep } from 'lodash';
import Spinner from '@/components/Ui/Spinner/Spinner';
import SelectField from "@/components/Ui/SelectField/SelectField";
import { FETCH_ALL_BOOKS_ACTION } from "@/store/actions/books";
import { FETCH_ALL_USERS_ACTION } from "@/store/actions/users";

const transformFormFields = (form) => {

    if (form.book) {
        form.book = form.book?.id;
    }

    if (form.sales_agent) {
        form.sales_agent = form.sales_agent?.id;
    }

    return form;
}


export default {
    name: 'ManageAgentBook',
    components: { InputField, SelectField, Spinner, Button, InputLabel, Container },
    data() {
        return {
            form: {},
            images: [],
            errors: {
                general: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            agentBook: 'selectedAgentBook',
            salesAgents: 'salesAgents',
            bookOptions: 'bookOptions',
            bookAsync: 'booksAsync',
            userAsync: 'usersAsync',
            agentBookAsync: 'agentBooksAsync'
        }),
        async() {
            return this.bookAsync || this.userAsync || this.agentBookAsync;
        },
        salesAgentsOptions() {
            return this.salesAgents.map(a => ({
                value: a.id,
                label: mergeName(a)
            }))
        },
        filteredBookOptions() {
            return this.bookOptions.filter(b => b.id)
        }
    },
    methods: {
        ...mapActions({
            addAgentBook: ADD_AGENT_BOOK_ACTION,
            editAgentBook: EDIT_AGENT_BOOK_ACTION,
            getAgentBookById: GET_AGENT_BOOK_BY_ID_ACTION,
            changeAgentBookImage: CHANGE_AGENT_BOOK_IMAGE_ACTION,
            fetchAllBooks: FETCH_ALL_BOOKS_ACTION,
            fetchAllUsers: FETCH_ALL_USERS_ACTION
        }),
        ...mapMutations({
            selectAgentBook: SELECT_AGENT_BOOK,
        }),
        setData() {
            if (this.agentBook) {
                this.form = transformFormFields(cloneDeep(this.agentBook));
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.sales_agent) {
                errors.sales_agent = 'Morate izabrati agenta.';
            }

            if (!this.form.book) {
                errors.book = 'Morate izabrati knjigu.';
            }

            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        cancelImageUpload() {
            this.images = [];
            this.abortToken.abort();
        },
        async submit() {

            if (!this.validateFields()) return;

            this.form.income_percent = Number(this.form.income_percent ?? 0);

            if (this.agentBook) {
                try {

                    const diff = difference(this.form, this.agentBook)

                    await this.editAgentBook({ id: this.agentBook.id, ...diff });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing agentBook');
                }
            } else {
                //adding
                try {

                    const diff = difference(this.form, {})

                    await this.addAgentBook({ ...diff }).then(() => this.$router.back())

                } catch (ex) {
                    console.error('error adding agentBook');
                }
            }
        },
    },
    async beforeMount() {
        const { params } = this.$route;

        if (!this.agentBook) {
            if (params.id) {
                await this.getAgentBookById({ agentBookId: params.id });
            }
        }

        this.setData();

        if (!this.bookOptions.length) {
            this.fetchAllBooks();
        }

        if (!this.salesAgents.length) {
            this.fetchAllUsers();
        }
    },
    beforeDestroy() {
        this.selectAgentBook(null);
    },
};
</script>

<style lang="scss">
.manage-agent-book {

    .body-container {
        display: flex;
        margin-top: 16px;
        flex-wrap: wrap;

        .container {
            display: flex;
        }

        .col {
            min-width: 400px;
            display: flex;
            flex-direction: column;
        }

        .image-container {
            display: flex;
            flex-direction: column;

            .agent-book-image {
                margin-top: 8px;
                height: 58px;
                width: 58px;
                border-radius: 8px;
            }
        }

        .labeled-input {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .save-button {
            margin-left: auto;
            margin-top: auto;
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }
    }
}
</style>
