<template>
    <div class="chapters-price-modal">

        <Button class='edit-prices-btn' @click="showModal">
            + Dodaj apple prihod
        </Button>

        <CustomModal
            v-show="priceModalOpened"
            @close="closeModal">
            <template v-slot:header>
                Dodaj apple prihod za period
            </template>

            <template v-slot:body>
                <div class="error-message">{{ error }}</div>

                <input-label label="Datum početka">
                    <div class="input-container">
                        <datepicker :value="fromDate"
                                    format="d/M/yyyy"
                                    wrapper-class="datepicker-wrapper"
                                    input-class="tvornica-snova datepicker"
                                    @input="date => updateDateField(date, 'fromDate')"
                                    name="fromDate"></datepicker>
                    </div>
                    <!--                    <input-field type="date" :value="fromDate" @input="date => updateDateField(date, 'fromDate')"/>-->
                </input-label>

                <input-label label="Datum isteka">
                    <div class="input-container">
                        <datepicker :value="toDate"
                                    format="d/M/yyyy"
                                    wrapper-class="datepicker-wrapper"
                                    input-class="tvornica-snova datepicker"
                                    @input="date => updateDateField(date, 'toDate')"
                                    name="toDate"></datepicker>
                    </div>
                    <!--                    <input-field type="date" :value="toDate" @input="date => updateDateField(date, 'toDate')"/>-->
                </input-label>

                <input-label label="Iznos ($):">
                    <div class="input-container">
                        <currency-input v-model="appleProfit"
                                        class="tvornica-snova currency-input"
                                        :auto-decimal-mode="true"
                                        currency="USD"
                                        :value-as-integer="true"
                                        :precision="2"/>
                    </div>
                </input-label>
            </template>

            <template v-slot:footer>
                <div class="buttons">
                    <Button class="cancel" @click="closeModal">
                        Otkaži
                    </Button>
                    <Button class="save"
                            :disabled="isSaveDisabled"
                            @click="saveAppleProfit">
                        Snimi
                    </Button>
                </div>
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomModal from '@/components/Ui/CustomModal/CustomModal';
import Button from '@/components/Ui/Button/Button';
import { showSwalToast } from '@/utils/utils';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import { apiSetAppleProfitForPeriod } from '@/api/paymentRequests/paymentRequests';
import Datepicker from 'vuejs-datepicker';

export default {
    name: 'AddAppleAnalyticModal',
    components: { InputLabel, Button, CustomModal, Datepicker },
    data() {
        return {
            fromDate: new Date().toISOString(),
            toDate: new Date().toISOString(),
            appleProfit: 0,
            priceModalOpened: false,
            error: '',
        };
    },
    computed: {
        isSaveDisabled() {
            return false;
        },
    },
    methods: {
        updateDateField(date, field) {
            this[field] = this.$moment(date).toISOString();
        },
        showModal() {
            this.fromDate = new Date().toISOString();
            this.toDate = new Date().toISOString();
            this.appleProfit = 0;
            this.priceModalOpened = true;
            this.error = '';
        },
        closeModal() {
            this.priceModalOpened = false;
        },
        async saveAppleProfit() {

            this.priceModalOpened = false;

            let fromMoment = this.$moment(this.fromDate);
            let toMoment = this.$moment(this.toDate);

            //[year, month, day, hour, minute, second, millisecond]

            let fromDate = this.$moment.utc([fromMoment.year(), fromMoment.month(), fromMoment.date(), 0, 0, 0, 0]).toISOString();
            let toDate = this.$moment.utc([toMoment.year(), toMoment.month(), toMoment.date(), 23, 59, 59, 999]).toISOString();

            await apiSetAppleProfitForPeriod(fromDate, toDate, this.appleProfit).then(() => {
                this.priceModalOpened = false;
                showSwalToast('Uspješno spašene cijene', 'success');
                this.$nextTick(() => {
                    this.$emit('refresh');
                });
            });
        },
    },
};
</script>

<style scoped lang="scss">

.chapters-price-modal {

    ::v-deep.modal {
        width: 40%;
        height: 70%;
        min-width: 500px;

        .modal-body {
            height: 80%;
            overflow: auto;
        }

        .modal-footer {
            margin-top: 10px;
            border-top: 1px solid $widgetBorderColor;
        }
    }

    .error-message {
        height: 25px;
        color: #F34658;
    }

    &::v-deep .modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            button {
                margin-left: 8px;
            }

        }
    }
}

</style>
