<template>
    <div class="status-tab">
        <div class="current-status">
            <div class="row">
                <span>DATUM</span>
                <span>STATUS</span>
            </div>
            <div class="row info-row">
                <span class="date">{{ book.created_at|moment('DD.MM.YYYY HH:mm') }}</span>
                <div class="book-status">
                    <book-status-dot :status="book.status"/>
                    <span>{{ book.status|parseBookStatus }}</span>
                </div>
            </div>
        </div>

        <div class="fields">
            <TSSwitch v-model="form.notifyUsers">
                <span style="margin-right: auto">Obavijesti korisnike</span>
            </TSSwitch>
            <div class="labeled-input">
                <span class="label">Komentar za administratora</span>
                <textarea-field v-model="form.admin_comment" placeholder="Komentar"/>
            </div>
        </div>

        <div class="buttons">
            <Button :flat="true"
                    :loading="async"
                    v-if="showRejectApproveBtns"
                    class="reject-btn"
                    @click="rejectBookAction(book.id)">
                Odbij
            </Button>
            <Button :flat="true"
                    :loading="async"
                    v-if="showRejectApproveBtns"
                    class="approve-btn"
                    @click="approveBookAction(book.id)">
                Odobri
            </Button>
            <Button :flat="true"
                    :loading="async"
                    class="save-btn"
                    @click="$emit('save', form)">
                Snimi
            </Button>
            <Button :flat="true"
                    :loading="async"
                    v-if="!showTogglePublishBtn"
                    :disabled="awaitingApproval"
                    class="request-approval-btn"
                    @click="requestApprovalAction(book.id)">
                Zatraži odobrenje
            </Button>
            <Button :flat="true"
                    @click="togglePublished"
                    v-if="showTogglePublishBtn"
                    :loading="async"
                    :class="{'publish-btn': true, 'inverse': isBookPublished}">
                {{ publishBtnText }}
            </Button>
        </div>
    </div>
</template>

<script>
import BookStatusDot from "@/components/BookStatusDot/BookStatusDot";
import TSSwitch from "@/components/Ui/TSSwitch/TSSwitch";
import TextareaField from "@/components/Ui/TextareaField/TextareaField";
import Button from "@/components/Ui/Button/Button";
import { pick } from "lodash";
import { mapActions, mapGetters } from "vuex";
import {
    APPROVE_BOOK_ACTION,
    REJECT_BOOK_ACTION,
    REQUEST_APPROVAL_ACTION,
    TOGGLE_BOOK_PUBLISHED_ACTION
} from "@/store/actions/books";
import { FETCH_ALL_DISCOUNTS_ACTION } from "@/store/actions/discounts";
import { BOOK_STATUS } from "@/utils/consts";

const transformFormFields = (form) => {

    if (form.discounts) {
        form.discounts = form.discounts.map(d => d.id);
    }

    return form;
}

export default {
    name: "StatusTab",
    components: { Button, TextareaField, TSSwitch, BookStatusDot },
    data() {
        return {
            form: {}
        }
    },
    computed: {
        ...mapGetters({
            book: 'selectedBook',
            isSuperAdmin: 'isSuperAdmin'
        }),
        async() {
            return this.$store.state.books.async;
        },
        bookStatus() {
            return this.book?.status;
        },
        isBookPublished() {
            return this.bookStatus === BOOK_STATUS.PUBLISHED;
        },
        awaitingApproval() {
            return this.bookStatus === BOOK_STATUS.AWAITING_APPROVAL;
        },
        isApproved() {
            return this.bookStatus === BOOK_STATUS.APPROVED;
        },
        publishBtnText() {
            return this.isBookPublished ? 'Odjavi knjigu' : 'Objavi knjigu';
        },
        showRejectApproveBtns() {
            return this.isSuperAdmin && this.bookStatus === BOOK_STATUS.AWAITING_APPROVAL;
        },
        showTogglePublishBtn() {
            return this.isSuperAdmin || [BOOK_STATUS.APPROVED, BOOK_STATUS.PUBLISHED].includes(this.bookStatus);
        }
    },
    watch: {
        book: function (newVal) {
            this.form = transformFormFields(pick(newVal, ['admin_comment', 'discounts']));
        }
    },
    methods: {
        ...mapActions({
            toggleBookPublished: TOGGLE_BOOK_PUBLISHED_ACTION,
            fetchDiscounts: FETCH_ALL_DISCOUNTS_ACTION,
            requestApprovalAction: REQUEST_APPROVAL_ACTION,
            approveBookAction: APPROVE_BOOK_ACTION,
            rejectBookAction: REJECT_BOOK_ACTION
        }),
        togglePublished() {
            this.toggleBookPublished({ bookId: this.book?.id, toggle: !this.isBookPublished });
        },
    },
    async beforeMount() {
        if (!this.discountOptions?.length) {
            await this.fetchDiscounts();
        }
    },
    mounted() {
        this.form = transformFormFields(pick(this.book, ['admin_comment', 'discounts']))
    }
}
</script>

<style scoped lang="scss">

.status-tab {
    background: #fff;
    box-shadow: $widgetShadow;
    padding-bottom: 20px;

    .current-status {
        margin-bottom: 35px;

        .row {
            display: flex;
            align-items: center;
            padding: 25px 40px;

            > * {
                font-size: 16px;
                color: $darkTextColor;
                width: 30%;
            }
        }

        .info-row {
            background: $widgetBg;
            border-top: 1px solid $widgetBorderColor;
            border-bottom: 1px solid $widgetBorderColor;

            .date {
                color: $greyTextColor;
            }

            .book-status {
                align-items: center;
                display: flex;

                .book-status-dot {
                    margin-right: 5px;
                }
            }
        }
    }

    .fields {
        max-width: 480px;
        padding: 0 40px 20px 40px;

        .switch-container {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .labeled-input {
            margin-right: 0;
        }
    }

    .buttons {
        margin: auto 20px 0 auto;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .publish-btn.inverse, .reject-btn {
            background: rgba(231, 76, 60, 0.9);

            &:focus,
            &:hover {
                background: #e74c3c;
                border: 1px solid #e74c3c
            }
        }

        button.tvornica-snova {
            margin-left: 20px;
        }
    }
}

</style>