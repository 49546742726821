import {
    SELECT_COINS_PACKAGE,
    SET_COINS_PACKAGES,
    FETCH_ALL_COINS_PACKAGES_ACTION,
    SET_COINS_PACKAGES_ASYNC,
    SET_SEARCH_COINS_PACKAGES,
    SET_COINS_PACKAGES_SEARCH_TEXT,
    FETCH_COINS_PACKAGES_PAGE_ACTION,
    APPEND_COINS_PACKAGES_PAGE,
    SEARCH_COINS_PACKAGES_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_COINS_PACKAGE_ACTION,
    ADD_COINS_PACKAGE_ACTION,
    UPDATE_COINS_PACKAGE,
    ADD_NEW_COINS_PACKAGE,
    DELETE_COINS_PACKAGE_ACTION,
    DELETE_COINS_PACKAGE,
    SET_COINS_PACKAGES_CURRENT_PAGE,
    SET_TOTAL_PAGES,
    CHANGE_COINS_PACKAGE_IMAGE_ACTION,
    GET_COINS_PACKAGE_BY_ID_ACTION,
    SET_COINS_PACKAGES_UPLOAD_ABORT_TOKEN,
    SET_COINS_PACKAGES_UPLOAD_PROGRESS, CHANGE_COINS_PACKAGES_PER_PAGE_ACTION,
} from '@/store/actions/coinsPackages';
import {
    addCoinsPackage,
    changeCoinsPackageImage,
    deleteCoinsPackage,
    editCoinsPackage,
    fetchCoinsPackageById, fetchCoinsPackagesPage,
} from '@/api/coinsPackages/coinsPackages';
import { getPerPage, searchCoinsPackage, setPerPage } from '@/utils/utils';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        coinsPackages: [],

        // Filtering
        searchText: null,
        searchCoinsPackages: null,
        searchAsync: false,
        selectedCoinsPackageId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        uploadProgress: null,
        abortToken: null,
        async: false,
    }),
    mutations: {
        [SET_COINS_PACKAGES](state, coinsPackages) {
            state.coinsPackages = coinsPackages;
        },
        [SELECT_COINS_PACKAGE](state, coinsPackageId) {
            state.selectedCoinsPackageId = coinsPackageId;
        },
        [SET_COINS_PACKAGES_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_COINS_PACKAGES](state, searchCoinsPackages) {
            state.searchCoinsPackages = searchCoinsPackages;
        },
        [SET_COINS_PACKAGES_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_COINS_PACKAGES_PAGE](state, coinsPackages) {
            if (state.coinsPackages !== null) {
                state.coinsPackages = state.coinsPackages.concat(coinsPackages);
            } else {
                state.coinsPackages = [...coinsPackages];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_COINS_PACKAGE](state, { id, ...data }) {
            for (let coinsPackage of state.coinsPackages) {
                if (coinsPackage.id === id) {
                    Object.assign(coinsPackage, data);
                    return;
                }
            }
        },
        [ADD_NEW_COINS_PACKAGE](state, newCoinsPackage) {
            const index = state.coinsPackages.findIndex(coinsPackage => coinsPackage.id === newCoinsPackage.id);

            if (index === -1) {
                state.coinsPackages.unshift(newCoinsPackage);
            }
        },
        [DELETE_COINS_PACKAGE](state, coinsPackageId) {
            if (state.coinsPackages !== null) {
                state.coinsPackages = state.coinsPackages.filter(coinsPackage => coinsPackage.id !== coinsPackageId);
            }
        },
        [SET_COINS_PACKAGES_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
        [SET_COINS_PACKAGES_UPLOAD_ABORT_TOKEN](state, token) {
            state.abortToken = token;
        },
        [SET_COINS_PACKAGES_UPLOAD_PROGRESS](state, progress) {
            state.uploadProgress = progress;
        }
    },
    actions: {
        async [FETCH_ALL_COINS_PACKAGES_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_COINS_PACKAGES_ASYNC, true);
            commit(SET_COINS_PACKAGES, []);
            commit(SET_SEARCH_COINS_PACKAGES, null);
            commit(SET_COINS_PACKAGES_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_COINS_PACKAGES_PAGE_ACTION, { page });
            }

            commit(SET_COINS_PACKAGES_CURRENT_PAGE, 1);
            commit(SET_TOTAL_PAGES, page);

            commit(SET_COINS_PACKAGES_ASYNC, false);
        },
        async [FETCH_COINS_PACKAGES_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': coinsPackages,
                } = await fetchCoinsPackagesPage(page, state.perPage);
                if (Array.isArray(coinsPackages)) {
                    commit(APPEND_COINS_PACKAGES_PAGE, coinsPackages);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch coinsPackages Page', ex);
            }
        },
        [SEARCH_COINS_PACKAGES_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_COINS_PACKAGES_SEARCH_TEXT, null);
                commit(SET_SEARCH_COINS_PACKAGES, null);
            } else {
                commit(SET_COINS_PACKAGES_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_COINS_PACKAGES, state.coinsPackages.filter(coinsPackage => searchCoinsPackage(coinsPackage, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_COINS_PACKAGE_ACTION]({ commit }, { id, ...data }) {
            commit(SET_COINS_PACKAGES_ASYNC, true);
            try {
                const updatedCoinsPackage = await editCoinsPackage(id, data);
                commit(UPDATE_COINS_PACKAGE, updatedCoinsPackage);
            } finally {
                commit(SET_COINS_PACKAGES_ASYNC, false);
            }
        },
        async [ADD_COINS_PACKAGE_ACTION]({ state, commit }, data) {
            if (state.async) return;

            commit(SET_COINS_PACKAGES_ASYNC, true);
            try {
                const newCoinsPackage = await addCoinsPackage(data);
                commit(ADD_NEW_COINS_PACKAGE, newCoinsPackage);
                return newCoinsPackage;
            } finally {
                commit(SET_COINS_PACKAGES_ASYNC, false);
            }
        },
        async [DELETE_COINS_PACKAGE_ACTION]({ commit }, coinsPackageId) {
            commit(SET_COINS_PACKAGES_ASYNC, true);
            try {
                await deleteCoinsPackage(coinsPackageId);
                commit(DELETE_COINS_PACKAGE, coinsPackageId);
            } finally {
                commit(SET_COINS_PACKAGES_ASYNC, false);
            }
        },
        async [CHANGE_COINS_PACKAGE_IMAGE_ACTION]({ commit }, { coinsPackageId, images }) {
            const image = images.length > 0 ? images[0] : null;
            if (!image) return;

            commit(SET_COINS_PACKAGES_ASYNC, true);

            let abortToken = new AbortController();

            commit(SET_COINS_PACKAGES_UPLOAD_ABORT_TOKEN, abortToken);

            try {
                const { image: updatedImage } = await changeCoinsPackageImage(coinsPackageId, image, abortToken, (progress) => {
                    commit(SET_COINS_PACKAGES_UPLOAD_PROGRESS, progress);
                });
                commit(UPDATE_COINS_PACKAGE, { id: coinsPackageId, image: updatedImage });
            } finally {
                commit(SET_COINS_PACKAGES_ASYNC, false);
                commit(SET_COINS_PACKAGES_UPLOAD_PROGRESS, null);
                commit(SET_COINS_PACKAGES_UPLOAD_ABORT_TOKEN, null);
            }
        },
        async [GET_COINS_PACKAGE_BY_ID_ACTION]({ state, commit }, { coinsPackageId }) {
            if (state.async) return;

            commit(SET_COINS_PACKAGES_ASYNC, true);
            const coinsPackage = await fetchCoinsPackageById(coinsPackageId).catch(() => commit(SET_COINS_PACKAGES_ASYNC, false));

            if (coinsPackage) {
                state.coinsPackages = [coinsPackage, ...state.coinsPackages];
                commit(SELECT_COINS_PACKAGE, coinsPackage.id);
            }

            commit(SET_COINS_PACKAGES_ASYNC, false);
        },
        async [CHANGE_COINS_PACKAGES_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);

            commit(UPDATE_PER_PAGE);

            dispatch(FETCH_ALL_COINS_PACKAGES_ACTION);
        }
    },
    getters: {
        selectedCoinsPackage(state) {
            return state.coinsPackages.find(b => b.id === state.selectedCoinsPackageId);
        },
        coinsPackageOptions(state) {
            return state.coinsPackages.map(m => ({ label: m.name, value: m.id }));
        },
        allCoinsPackages(state) {
            if (state.searchCoinsPackages !== null) {
                return state.searchCoinsPackages;
            } else {
                return state.coinsPackages;
            }
        },
        paginatedCoinsPackages(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allCoinsPackages.slice(beginIndex, endIndex);
        },
        totalCoinsPackagesPages(state) {
            return state.totalPages;
        },
        coinsPackagesAsync(state) {
            return state.async;
        },
    },
};
