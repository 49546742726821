<template>
    <div class="ts-row">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TSRow",
}
</script>

<style scoped lang="scss">

.ts-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

</style>