<template>
    <div class="general-data">
        <vue-element-loading :active="async"/>
        <div class="col">

            <input-label label="Originalni naziv knjige">
                <input-field v-model="form.original_title" :disabled="true"/>
            </input-label>

            <input-label label="Naziv knjige">
                <input-field v-model="form.title"
                             placeholder="Naziv knjige..."/>
            </input-label>

            <input-label label="Autor">
                <select-field v-model="form.author"
                              :options="authorOptions"
                              :on-add-option="onAddAuthorOption"/>
            </input-label>

            <input-label label="Izdavač" v-if="isSuperAdmin">
                <select-field v-model="form.publisher"
                              :disabled="publishersAsync"
                              :options="publisherOptions"
                              :on-add-option="onAddPublisherOption"/>
            </input-label>

            <input-label label="Opis knjige">
                <textarea-field v-model="form.description"/>
            </input-label>
        </div>

        <div class="col">
            <input-label label="Barkod">
                <input-field v-model="form.barcode" placeholder="Barkod"/>
            </input-label>

            <input-label label="Kategorije">
                <select-field :multiple="true"
                              v-model="form.categories"
                              :disabled="categoriesAsync"
                              :options="categoryOptions"
                              :on-add-option="onAddCategoryOption"/>
            </input-label>

            <input-label label="Tagovi">
                <select-field :multiple="true"
                              v-model="form.tags"
                              :disabled="tagsAsync"
                              :options="tagOptions"
                              :on-add-option="onAddTagOption"/>
            </input-label>

            <input-label label="Cijena knjige (€)">
                <input-field v-model="form.priceEuros" type="number" :min="0"/>
            </input-label>

            <input-label label="Cijena knjige (Apple Tier Price)">
                <select-field v-model="form.apple_tier_price"
                              :options="applePriceTierOptions"/>
            </input-label>

            <input-label label="Šifra knjige">
                <input-field v-model="form.internal_id"/>
            </input-label>

            <input-label class="image-container" label="Slika" v-if="imageUrl">
                <img :src="imageUrl" class="book-image"/>
            </input-label>

            <input-label label="Naslovna Slika">
                <uploader :progress="uploadProgress"
                          :async="uploadProgress !== null"
                          @cancel="cancelImageUpload"
                          v-model="form.images"/>
            </input-label>
        </div>
        <Button :flat="true"
                :loading="booksAsync"
                class="save-btn"
                @click="$emit('save', form)">
            Snimi
        </Button>
    </div>
</template>

<script>
import InputField from '@/components/Ui/InputField/InputField';
import SelectField from '@/components/Ui/SelectField/SelectField';
import TextareaField from '@/components/Ui/TextareaField/TextareaField';
import Button from '@/components/Ui/Button/Button';
import { cloneDeep } from 'lodash';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import { mapActions, mapGetters } from 'vuex';
import { ADD_AUTHOR_ACTION, FETCH_ALL_AUTHORS_ACTION } from '@/store/actions/authors';
import { ADD_CATEGORY_ACTION, FETCH_ALL_CATEGORIES_ACTION } from '@/store/actions/categories';
import { ADD_TAG_ACTION, FETCH_ALL_TAGS_ACTION } from '@/store/actions/tags';
import { ADD_PUBLISHER_ACTION, FETCH_ALL_PUBLISHERS_ACTION } from '@/store/actions/publishers';
import { splitName } from '@/utils/utils';
import Uploader from '@/components/Ui/Uploader/Uploader';
import { storage_url } from '@/api/apiCall';


export default {
    name: 'GeneralDataTab',
    components: { Uploader, InputLabel, Button, TextareaField, SelectField, InputField },
    props: {
        book: Object,
    },
    data() {
        return {
            form: cloneDeep(this.book),
        };
    },
    computed: {
        ...mapGetters([
            'authorOptions',
            'categoryOptions',
            'tagOptions',
            'publisherOptions',
            'categoriesAsync',
            'tagsAsync',
            'publishersAsync',
            'booksAsync',
            'authorsAsync',
            'isSuperAdmin',
        ]),
        applePriceTierOptions() {
            const opts = Array.apply(null, Array(40)).map((x, i) => {
                return { label: `Tier ${i + 1} (${i}.99 USD)`, value: `tier${i + 1}` };
            });
            console.log(opts);
            return opts;
        },
        async() {
            return this.booksAsync || this.categoriesAsync || this.tagsAsync || this.authorsAsync || this.publishersAsync;
        },
        abortToken() {
            return this.$store.state.books.abortToken;
        },
        uploadProgress() {
            return this.$store.state.books.uploadProgress;
        },
        imageUrl() {
            if (this.form?.image?.content_url) {
                return `${storage_url}${this.form.image?.content_url}`;
            }

            return null;
        },
    },
    watch: {
        book: function (newVal) {
            this.form = cloneDeep(newVal);
        },
    },
    methods: {
        ...mapActions({
            fetchAuthors: FETCH_ALL_AUTHORS_ACTION,
            fetchCategories: FETCH_ALL_CATEGORIES_ACTION,
            fetchTags: FETCH_ALL_TAGS_ACTION,
            fetchPublishers: FETCH_ALL_PUBLISHERS_ACTION,
            addAuthorAction: ADD_AUTHOR_ACTION,
            addCategoryAction: ADD_CATEGORY_ACTION,
            addTagAction: ADD_TAG_ACTION,
            addPublisherAction: ADD_PUBLISHER_ACTION,
        }),
        cancelImageUpload() {
            this.abortToken.abort();
            this.form.images = [];
        },
        onAddTagOption(newOption) {
            let name = newOption.label;

            const currentTags = [...this.form.tags];

            this.addTagAction({ name }).then(created => {
                if (created?.id) {
                    this.form.tags = [...currentTags, created.id];
                }

            });
        },
        onAddCategoryOption(newOption) {
            let name = newOption.label;

            const currentCategories = [...this.form.categories];

            this.addCategoryAction({ name }).then(created => {
                if (created?.id) {
                    this.form.categories = [...currentCategories, created.id];
                }

            });
        },
        onAddAuthorOption(newOption) {

            let name = splitName(newOption.label);

            this.addAuthorAction({ ...name }).then(created => {
                if (created?.id) {
                    this.form.author = created.id;
                }
            });
        },
        onAddPublisherOption(newOption) {

            let name = newOption.label;

            this.addPublisherAction({ name }).then(created => {
                if (created?.id) {
                    this.form.publisher = created.id;
                }
            });
        },

    },
    async beforeMount() {
        if (!this.authorOptions?.length) {
            await this.fetchAuthors();
        }
        if (!this.categoryOptions?.length) {
            await this.fetchCategories();
        }
        if (!this.tagOptions?.length) {
            await this.fetchTags();
        }
        if (!this.publisherOptions?.length && this.isSuperAdmin) {
            await this.fetchPublishers();
        }
    },
};
</script>

<style scoped lang="scss">

.general-data {
    display: flex;
    background: #fff;
    margin-top: 10px;
    box-shadow: $widgetShadow;
    padding: 25px 40px;
    flex-wrap: wrap;

    .row {
        flex-wrap: wrap;
    }

    .col {
        min-width: 400px;
        display: flex;
        flex-direction: column;
    }

    .image-container {
        display: flex;
        flex-direction: column;

        .book-image {
            object-fit: cover;
            margin-top: 8px;
            height: 128px;
            width: 72px;
            border-radius: 8px;
        }
    }

    .save-btn {
        margin-top: auto;
        margin-left: auto;
        min-width: 160px;
    }

}

</style>
