<template>
    <div :class="['tvornica-snova', 'switch-container', focus && 'focus']">
        <slot/>
        <internal-button-switch @focusin="focus = true"
                                @focusout="focus = false"
                                :value="value" :sync="true"
                                @change="$emit('input', $event.value)"
                                :width="40"
                                :height="25"
                                color="#4122F0"/>
    </div>
</template>

<script>
import InternalButtonSwitch from "@/components/Ui/TSSwitch/components/InternalButtonSwitch";

export default {
    name: "TSSwitch",
    components: { InternalButtonSwitch },
    data() {
        return {
            focus: false,
        }
    },
    props: {
        value: Boolean,
    }
}
</script>

<style lang="scss">
div.tvornica-snova.switch-container {
    display: flex;
    outline: none;
}
</style>
