// actions
export const FETCH_ALL_PROMOCODES_ACTION = 'FETCH_ALL_PROMOCODES_ACTION';
export const FETCH_PROMOCODES_PAGE_ACTION = 'FETCH_PROMOCODES_PAGE_ACTION';
export const SEARCH_PROMOCODES_ACTION = 'SEARCH_PROMOCODES_ACTION';
export const ADD_PROMOCODE_ACTION = 'ADD_PROMOCODE_ACTION';
export const EDIT_PROMOCODE_ACTION = 'EDIT_PROMOCODE_ACTION';
export const DELETE_PROMOCODE_ACTION = 'DELETE_PROMOCODE_ACTION';
export const GET_PROMOCODE_BY_ID_ACTION = 'GET_PROMOCODE_BY_ID_ACTION';
export const CHANGE_PROMOCODES_PER_PAGE_ACTION = 'CHANGE_PROMOCODES_PER_PAGE_ACTION';

// mutations
export const SET_PROMOCODES = 'SET_PROMOCODES';
export const SELECT_PROMOCODE = 'SELECT_PROMOCODE';
export const SET_PROMOCODES_ASYNC = 'SET_PROMOCODES_ASYNC';
export const SET_SEARCH_PROMOCODES = 'SET_SEARCH_PROMOCODES';
export const SET_PROMOCODES_SEARCH_TEXT = 'SET_PROMOCODES_SEARCH_TEXT';
export const APPEND_PROMOCODES_PAGE = 'APPEND_PROMOCODES_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE';
export const ADD_NEW_PROMOCODE = 'ADD_NEW_PROMOCODE';
export const DELETE_PROMOCODE = 'DELETE_PROMOCODE';
export const SET_PROMOCODES_CURRENT_PAGE = 'SET_PROMOCODES_CURRENT_PAGE';
export const SET_PROMOCODE_TOTAL_PAGES = 'SET_PROMOCODE_TOTAL_PAGES';
