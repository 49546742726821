import { apiCall, books, chapters } from '@/api/apiCall';
import { progressFetch } from '@/api/progressFetch';
import { showSwalToast } from '@/utils/utils';
import { ERROR_MESSAGES } from '@/utils/consts';

// import { showSuccessSwal } from '@/utils/utils';

export async function fetchBooksPage(page = 1, perPage = 10) {
    let url = new URL(books);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editBook(bookId, data) {
    return apiCall({
        url: books + `/${bookId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addBook(data) {
    return apiCall({
        url: books,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteBook(bookId) {
    return apiCall({
        url: books + `/${bookId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function changeBookImage(bookId, image, abortToken, onProgressChange) {
    let url = new URL(`${books}/${bookId}/change_image`);

    const formData = new FormData();

    formData.append('file', image.file);

    try {
        return await progressFetch({
            url: url.href,
            method: 'POST',
            body: formData,
            signal: abortToken.signal,
            uploadProgress: onProgressChange,
        });
    } catch (ex) {
        console.error('error uploading image');
        if (ex?.detail) {
            showSwalToast(ERROR_MESSAGES[ex.detail] ?? ex.detail, 'error');
        }
        throw ex;
    }
}

export async function editChapter(chapterId, data) {
    return apiCall({
        url: chapters + `/${chapterId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteChapter(chapterId) {
    return apiCall({
        url: chapters + `/${chapterId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function moveChapterApi(chapterId, up = true) {
    return apiCall({
        url: `${chapters}/${chapterId}/move_${up ? 'up' : 'down'}`,
        method: 'PUT',
        jsonData: {},
    })
        .then(r => {
            return r.json();
        });
}

export async function addChapter(bookId, data) {
    return apiCall({
        url: chapters,
        method: 'POST',
        jsonData: {
            book: bookId,
            ...data,
        },
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function fetchBookById(bookId) {
    return apiCall({
        url: `${books}/${bookId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function toggleBookPublished(bookId, toggle) {
    return apiCall({
        url: `${books}/${bookId}/${toggle ? 'publish' : 'unpublish'}`,
        method: 'PUT',
        jsonData: {},
        headers: {
            accept: 'application/json',
        },
    })
        .then(r => r.json());
}

export async function requestBookApproval(bookId) {
    return apiCall({
        url: `${books}/${bookId}/request_approval`,
        method: 'PUT',
        jsonData: {},
        headers: {
            accept: 'application/json',
        },
    })
        .then(r => r.json());
}

export async function approveBook(bookId) {
    return apiCall({
        url: `${books}/${bookId}/approve`,
        method: 'PUT',
        jsonData: {},
        headers: {
            accept: 'application/json',
        },
    })
        .then(r => r.json());
}

export async function rejectBook(bookId) {
    return apiCall({
        url: `${books}/${bookId}/reject`,
        method: 'PUT',
        jsonData: {},
        headers: {
            accept: 'application/json',
        },
    })
        .then(r => r.json());
}
