import { apiCall, endpoint, users } from '@/api/apiCall';
import { showSuccessSwal } from "@/utils/utils";

export async function fetchUsersPage(page = 1, perPage = 10) {
    let url = new URL(users);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function toggleUserEnabled(userId, toggle) {
    return apiCall({
        url: `${endpoint}/users/${userId}/${toggle ? 'enable' : 'disable'}`,
        method: "PUT",
        jsonData: {},
        headers: {
            accept: 'application/json',
        },
    })
        .then(r => r.json());
}

export async function editUser(userId, data) {
    return apiCall({
        url: users + `/${userId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            return r.json();
        });
}

export async function addUser(data) {
    return apiCall({
        url: users,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            return r.json();
        });
}

export async function changeUserPassword(userId, password) {
    return apiCall({
        url: users + `/${userId}/change_password`,
        method: 'PUT',
        jsonData: {
            password
        },
    })
        .then(r => {
            return r.json();
        });
}

export async function deleteUser(userId) {
    return apiCall({
        url: users + `/${userId}`,
        method: 'DELETE',
    })
        .then(() => {
            showSuccessSwal();
        });
}

export async function fetchUserById(userId) {
    return apiCall({
        url: `${users}/${userId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}