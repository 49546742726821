<template>
    <div class="manage-promocode">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="promocode-title">Novi Promo Kod</div>
        <div class="promocode-status">
        </div>
        <container class="body-container">
            <TSSwitch v-model="form.enabled">
                <span style="margin-right: 1rem; margin-bottom: 2rem;">Promo kod aktivan</span>
            </TSSwitch>

            <input-label :required="true" :error="errors.name" label="Naziv ">
                <input-field v-model="form.name" placeholder="Naziv"/>
            </input-label>

            <input-label :required="true" :error="errors.discount_percent" label="Iznos % ">
                <input-field v-model="form.discount_percent" type="number"/>
            </input-label>

            <input-label :required="true" :error="errors.discount_apple_tiers" label="Iznos Apple ">
                <input-field v-model="form.discount_apple_tiers" type="number"/>
            </input-label>

            <input-label label="Datum isteka">
                <input-field type="date" :value="form.expires_at" @input="date => updateDateField(date, 'expires_at')"/>
            </input-label>

            <input-label label="Tip">
                <select-field :value="form.type"
                              @input="setType"
                              :options="typeOptions"/>
            </input-label>

            <input-label label="Knjige" v-if="form.type !== PROMOCODE_TYPE.REGISTER">
                <select-field :multiple="true"
                              v-model="form.books"
                              :disabled="async"
                              :options="bookOptions"/>
            </input-label>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <div class="buttons">
                <Button class="save-button" @click="submit" :loading="async">
                    Snimi
                </Button>
            </div>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference } from '@/utils/utils';
import {
    ADD_PROMOCODE_ACTION,
    EDIT_PROMOCODE_ACTION,
    GET_PROMOCODE_BY_ID_ACTION,
    SELECT_PROMOCODE,
} from '@/store/actions/promoCodes';
import { cloneDeep } from 'lodash';
import SelectField from '@/components/Ui/SelectField/SelectField';
import Spinner from '@/components/Ui/Spinner/Spinner';
import { FETCH_ALL_BOOKS_ACTION } from '@/store/actions/books';
import { PROMOCODE_TYPE } from '@/utils/consts';
import TSSwitch from '@/components/Ui/TSSwitch/TSSwitch';

const transformFormFields = (form) => {
    if (form.books) {
        form.books = form.books.map(book => book.id);
    }

    if (form.enabled === undefined) {
        form.enabled = true;
    }

    return form;
};

const defaultForm = {
    name: '',
    discount_percent: 0,
    discount_apple_tiers: 0,
    books: [],
};

export default {
    name: 'ManagePromocode',
    components: { TSSwitch, Spinner, SelectField, Button, InputLabel, InputField, Container },
    data() {
        return {
            form: cloneDeep(defaultForm),
            applyDicountAsync: false,
            errors: {
                general: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            promocode: 'selectedPromocode',
            bookOptions: 'bookOptions',
            booksAsync: 'booksAsync',
            promocodesAsnyc: 'promocodesAsync',
        }),
        PROMOCODE_TYPE() {
            return PROMOCODE_TYPE;
        },
        async() {
            return this.booksAsync || this.promocodesAsnyc || this.applyDicountAsync;
        },
        booksValues() {
            return this.form.books.map(book => book.id);
        },
        typeOptions() {
            return [{
                label: 'Normalni',
                value: PROMOCODE_TYPE.NORMAL,
            }, {
                label: 'Jednokratni - Po korisniku',
                value: PROMOCODE_TYPE.ONESHOT,
            }, {
                label: 'Jednokratni - Za sve',
                value: PROMOCODE_TYPE.ONESHOT_GLOBAL,
            }, {
                label: 'Registracioni',
                value: PROMOCODE_TYPE.REGISTER,
            }];
        },
    },
    methods: {
        ...mapActions({
            addPromocode: ADD_PROMOCODE_ACTION,
            editPromocode: EDIT_PROMOCODE_ACTION,
            getPromocodeById: GET_PROMOCODE_BY_ID_ACTION,
            fetchBooks: FETCH_ALL_BOOKS_ACTION,
        }),
        ...mapMutations({
            selectPromocode: SELECT_PROMOCODE,
        }),
        onChangeBooks(e) {
            console.log(e);
        },
        updateDateField(date, field) {
            const isoDate = this.$moment(date).utc().endOf('day').toISOString();
            this.$set(this.form, field, isoDate);
        },
        setType(type) {
            this.form.type = type;

            if (this.form.type === PROMOCODE_TYPE.REGISTER) {
                this.form.books = [];
            }
        },
        setData() {
            if (this.promocode) {
                this.form = transformFormFields(cloneDeep(this.promocode));
            } else {
                this.form = {
                    enabled: true,
                }
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.name) {
                errors.title = 'Naziv je obavezan';
            }


            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        async submit() {
            if (!this.validateFields()) return;

            if (this.form.discount_percent) {
                this.form.discount_percent = +this.form.discount_percent;
            }

            if (this.form.discount_apple_tiers) {
                this.form.discount_apple_tiers = +this.form.discount_apple_tiers;
            }

            if (this.form.enabled === undefined) {
                this.form.enabled = true;
            }

            if (this.promocode) {
                try {
                    await this.editPromocode({ id: this.promocode.id, ...difference(this.form, this.promocode) });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing promocode');
                }
            } else {
                //adding
                try {
                    await this.addPromocode({ ...difference(this.form, defaultForm) }).then(() => this.$router.back());
                } catch (ex) {
                    this.errors.general.push('Error adding promocode');
                    console.error('error adding promocode');
                }
            }
        },
    },
    async beforeMount() {

        const { params } = this.$route;

        if (!this.promocode) {

            if (params.id) {
                await this.getPromocodeById({ promocodeId: params.id });
            }
        }

        if (!this.bookOptions?.length) {
            await this.fetchBooks();
        }

        this.setData();
    },
    beforeDestroy() {
        this.selectPromocode(null);
    },
};
</script>

<style lang="scss">
.manage-promocode {
    .body-container {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .labeled-input {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .buttons {
            margin: auto 20px 0 auto;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            button.tvornica-snova {
                margin-left: 20px;
            }
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }
    }
}
</style>
