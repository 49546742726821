// actions
export const FETCH_ALL_DISCOUNTS_ACTION = 'FETCH_ALL_DISCOUNTS_ACTION';
export const FETCH_DISCOUNTS_PAGE_ACTION = 'FETCH_DISCOUNTS_PAGE_ACTION';
export const SEARCH_DISCOUNTS_ACTION = 'SEARCH_DISCOUNTS_ACTION';
export const ADD_DISCOUNT_ACTION = 'ADD_DISCOUNT_ACTION';
export const EDIT_DISCOUNT_ACTION = 'EDIT_DISCOUNT_ACTION';
export const DELETE_DISCOUNT_ACTION = 'DELETE_DISCOUNT_ACTION';
export const GET_DISCOUNT_BY_ID_ACTION = 'GET_DISCOUNT_BY_ID_ACTION';
export const CHANGE_DISCOUNTS_PER_PAGE_ACTION = 'CHANGE_DISCOUNTS_PER_PAGE_ACTION';

// mutations
export const SET_DISCOUNTS = 'SET_DISCOUNTS';
export const SELECT_DISCOUNT = 'SELECT_DISCOUNT';
export const SET_DISCOUNTS_ASYNC = 'SET_DISCOUNTS_ASYNC';
export const SET_SEARCH_DISCOUNTS = 'SET_SEARCH_DISCOUNTS';
export const SET_DISCOUNTS_SEARCH_TEXT = 'SET_DISCOUNTS_SEARCH_TEXT';
export const APPEND_DISCOUNTS_PAGE = 'APPEND_DISCOUNTS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const ADD_NEW_DISCOUNT = 'ADD_NEW_DISCOUNT';
export const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
export const SET_DISCOUNTS_CURRENT_PAGE = 'SET_DISCOUNTS_CURRENT_PAGE';
export const SET_DISCOUNT_TOTAL_PAGES = 'SET_DISCOUNT_TOTAL_PAGES';
