// actions
export const FETCH_ALL_PUBLISHERS_ACTION = 'FETCH_ALL_PUBLISHERS_ACTION';
export const FETCH_PUBLISHERS_PAGE_ACTION = 'FETCH_PUBLISHERS_PAGE_ACTION';
export const SEARCH_PUBLISHERS_ACTION = 'SEARCH_PUBLISHERS_ACTION';
export const ADD_PUBLISHER_ACTION = 'ADD_PUBLISHER_ACTION';
export const EDIT_PUBLISHER_ACTION = 'EDIT_PUBLISHER_ACTION';
export const DELETE_PUBLISHER_ACTION = 'DELETE_PUBLISHER_ACTION';
export const GET_PUBLISHER_BY_ID_ACTION = 'GET_PUBLISHER_BY_ID_ACTION';
export const CHANGE_PUBLISHERS_PER_PAGE_ACTION = 'CHANGE_PUBLISHERS_PER_PAGE_ACTION';

// mutations
export const SET_PUBLISHERS = 'SET_PUBLISHERS';
export const SELECT_PUBLISHER = 'SELECT_PUBLISHER';
export const SET_PUBLISHERS_ASYNC = 'SET_PUBLISHERS_ASYNC';
export const SET_SEARCH_PUBLISHERS = 'SET_SEARCH_PUBLISHERS';
export const SET_PUBLISHERS_SEARCH_TEXT = 'SET_PUBLISHERS_SEARCH_TEXT';
export const APPEND_PUBLISHERS_PAGE = 'APPEND_PUBLISHERS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_PUBLISHER = 'UPDATE_PUBLISHER';
export const ADD_NEW_PUBLISHER = 'ADD_NEW_PUBLISHER';
export const DELETE_PUBLISHER = 'DELETE_PUBLISHER';
export const SET_PUBLISHERS_CURRENT_PAGE = 'SET_PUBLISHERS_CURRENT_PAGE';
export const SET_PUBLISHER_TOTAL_PAGES = 'SET_PUBLISHER_TOTAL_PAGES';
