import {
    SELECT_PROMOCODE,
    SET_PROMOCODES,
    FETCH_ALL_PROMOCODES_ACTION,
    SET_PROMOCODES_ASYNC,
    SET_SEARCH_PROMOCODES,
    SET_PROMOCODES_SEARCH_TEXT,
    FETCH_PROMOCODES_PAGE_ACTION,
    APPEND_PROMOCODES_PAGE,
    SEARCH_PROMOCODES_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_PROMOCODE_ACTION,
    ADD_PROMOCODE_ACTION,
    UPDATE_PROMOCODE,
    ADD_NEW_PROMOCODE,
    DELETE_PROMOCODE_ACTION,
    DELETE_PROMOCODE,
    SET_PROMOCODES_CURRENT_PAGE,
    SET_PROMOCODE_TOTAL_PAGES,
    GET_PROMOCODE_BY_ID_ACTION, CHANGE_PROMOCODES_PER_PAGE_ACTION,
} from '@/store/actions/promoCodes';
import {
    addPromocode,
    deletePromocode,
    editPromocode,
    fetchPromocodesPage,
} from '@/api/promocodes/promocodes';
import { getPerPage, searchPromocode, setPerPage } from '@/utils/utils';
import { fetchPromocodeById } from '@/api/promocodes/promocodes';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        promocodes: [],

        // Filtering
        searchText: null,
        searchPromocodes: null,
        searchAsync: false,
        selectedPromocodeId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        async: false,
    }),
    mutations: {
        [SET_PROMOCODES](state, Promocodes) {
            state.promocodes = Promocodes;
        },
        [SELECT_PROMOCODE](state, promocodeId) {
            state.selectedPromocodeId = promocodeId;
        },
        [SET_PROMOCODES_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_PROMOCODES](state, searchPromocodes) {
            state.searchPromocodes = searchPromocodes;
        },
        [SET_PROMOCODES_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_PROMOCODES_PAGE](state, Promocodes) {
            if (state.promocodes !== null) {
                state.promocodes = state.promocodes.concat(Promocodes);
            } else {
                state.promocodes = [...Promocodes];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_PROMOCODE](state, { id, ...data }) {
            for (let promocode of state.promocodes) {
                if (promocode.id === id) {
                    Object.assign(promocode, data);
                    return;
                }
            }
        },
        [ADD_NEW_PROMOCODE](state, newpromocode) {
            const index = state.promocodes.findIndex(promocode => promocode.id === newpromocode.id);

            if (index === -1) {
                state.promocodes.unshift(newpromocode);
            }
        },
        [DELETE_PROMOCODE](state, promocodeId) {
            if (state.promocodes !== null) {
                state.promocodes = state.promocodes.filter(promocode => promocode.id !== promocodeId);
            }
        },
        [SET_PROMOCODES_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_PROMOCODE_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
    },
    actions: {
        async [FETCH_ALL_PROMOCODES_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_PROMOCODES_ASYNC, true);
            commit(SET_PROMOCODES, []);
            commit(SET_SEARCH_PROMOCODES, null);
            commit(SET_PROMOCODES_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_PROMOCODES_PAGE_ACTION, { page });
            }

            commit(SET_PROMOCODES_CURRENT_PAGE, 1);
            commit(SET_PROMOCODE_TOTAL_PAGES, page);

            commit(SET_PROMOCODES_ASYNC, false);
        },
        async [FETCH_PROMOCODES_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Promocodes,
                } = await fetchPromocodesPage(page, state.perPage);
                if (Array.isArray(Promocodes)) {
                    commit(APPEND_PROMOCODES_PAGE, Promocodes);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Promocodes Page', ex);
            }
        },
        [SEARCH_PROMOCODES_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_PROMOCODES_SEARCH_TEXT, null);
                commit(SET_SEARCH_PROMOCODES, null);
            } else {
                commit(SET_PROMOCODES_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_PROMOCODES, state.promocodes.filter(promocode => searchPromocode(promocode, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_PROMOCODE_ACTION]({ commit }, { id, ...data }) {
            commit(SET_PROMOCODES_ASYNC, true);
            try {
                const updatedPromocode = await editPromocode(id, data);
                commit(UPDATE_PROMOCODE, updatedPromocode);
            } finally {
                commit(SET_PROMOCODES_ASYNC, false);
            }
        },
        async [ADD_PROMOCODE_ACTION]({ commit }, data) {
            commit(SET_PROMOCODES_ASYNC, true);
            try {
                const newPromocode = await addPromocode(data);
                commit(ADD_NEW_PROMOCODE, newPromocode);
                return newPromocode;
            } finally {
                commit(SET_PROMOCODES_ASYNC, false);
            }
        },
        async [DELETE_PROMOCODE_ACTION]({ commit }, promocodeId) {
            commit(SET_PROMOCODES_ASYNC, true);
            try {
                await deletePromocode(promocodeId);
                commit(DELETE_PROMOCODE, promocodeId);
            } finally {
                commit(SET_PROMOCODES_ASYNC, false);
            }
        },
        async [GET_PROMOCODE_BY_ID_ACTION]({ state, commit }, { promocodeId }) {
            if (state.async) return;

            commit(SET_PROMOCODES_ASYNC, true);
            const promocode = await fetchPromocodeById(promocodeId).catch(() => commit(SET_PROMOCODES_ASYNC, false));

            if (promocode) {
                state.promocodes = [promocode, ...state.promocodes];
                commit(SELECT_PROMOCODE, promocode.id);
            }

            commit(SET_PROMOCODES_ASYNC, false);
        },
        async [CHANGE_PROMOCODES_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);
            commit(UPDATE_PER_PAGE);
            dispatch(FETCH_ALL_PROMOCODES_ACTION);
        },
    },
    getters: {
        selectedPromocode(state) {
            return state.promocodes.find(b => b.id === state.selectedPromocodeId);
        },
        promocodeOptions(state) {
            return state.promocodes.map(d => ({ label: d.title, value: d.id }));
        },
        allPromocodes(state) {
            if (state.searchPromocodes !== null) {
                return state.searchPromocodes;
            } else {
                return state.promocodes;
            }
        },
        paginatedPromocodes(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allPromocodes.slice(beginIndex, endIndex);
        },
        totalPromocodesPages(state) {
            return state.totalPages;
        },
        promocodesAsync(state) {
            return state.async;
        },
    },
};
