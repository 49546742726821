<template>
    <div class="chapters-tab">
        <div class="chapters-list-container">
            <div class="chapters-list">

                <!-- New Chapter-->
                <div :class="{'single-chapter new-chapter': true, 'active': !editMode}">
                <span class="chapter-number">
                    {{ chapters.length + 1 }}
                </span>
                    <div class="title-and-date">
                        <span class="title">{{ 'Novo poglavlje - ' }}</span>
                        <span class="date">{{ new Date()|moment('DD.MM.YYYY') }}</span>
                    </div>
                    <i class="icon-plus add-new" @click="addNewChapter"/>
                </div>

                <!-- Chapter range -->
                <div class="single-chapter total" @click="selectChapter('all')">
                    <span class="chapter-number">
                        {{ chaptersRangeText }}
                    </span>
                    <div class="title-and-date">
                        <span class="title">{{ 'Sva poglavlja' }}</span>
                        <span class="date">{{ book.created_at|moment('DD.MM.YYYY HH:mm') }}</span>
                    </div>
                    <span class="price">{{ totalBookPrice }}</span>
                </div>

                <!-- Chapter list-->
                <div v-for="(chapter, index) in chapterForms"
                     @click="selectChapter(chapter.id)"
                     :key="chapter.id"
                     :class="{'single-chapter': true, 'active': isActiveChapter(chapter.id)}">
                    <div class="up-down-arrows">
                        <i class="icon-up" @click.capture.stop="moveChapter(chapter, true)"/>
                        <i class="icon-down" @click.capture.stop="moveChapter(chapter, false)"/>
                    </div>
                    <span class="chapter-number">
                        {{ chapterForms.length - index }}
                    </span>
                    <div class="title-and-date">
                        <span class="title">{{ chapter.title }}</span>
                        <span class="date">{{ chapter.created_at|moment('DD.MM.YYYY HH:mm') }}</span>
                    </div>
                    <span class="price">{{ chapter.price_in_cents | parseCents }}</span>
                </div>

            </div>

        </div>
        <div class="chapter-data">
            <div class="title-and-price">
                <input-label label="Naslov pogavlja" class="chapter-title">
                    <input-field v-model="selectedChapter.title"/>
                </input-label>

<!--                <input-label label="Cijena (€)" class="chapter-price">-->
<!--                    <input-field :value="selectedChapter.price_in_cents | parseCents" type="number" :min="0"-->
<!--                                 :disabled="true"/>-->
<!--                </input-label>-->
            </div>

            <custom-editor :content-prop="selectedChapterContent.content"
                           @change="onContentChange"
                           :enabled="isEditable"/>

            <div class="buttons">
                <Button :flat="true"
                        :loading="async"
                        v-if="selectedChapterId"
                        class="delete-btn"
                        @click="deleteSelectedChapter">
                    Izbriši poglavlje
                </Button>

<!--                <ChaptersPriceModal :book="book"-->
<!--                                    :chapter-forms="chapterForms"-->
<!--                                    @save="onSave"-->
<!--                                    @updateChapter="updateChapter"/>-->

                <Button :flat="true"
                        :loading="async"
                        class="save-btn"
                        :disabled="!isEditable"
                        @click="onSave">
                    Snimi
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/Ui/InputField/InputField';
import { mapActions, mapGetters } from 'vuex';
import CustomEditor from '@/components/Ui/CustomEditor/CustomEditor';
import Button from '@/components/Ui/Button/Button';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import {
    ADD_CHAPTER_ACTION,
    DELETE_CHAPTER_ACTION,
    EDIT_CHAPTER_ACTION,
    MOVE_CHAPTER_ACTION,
} from '@/store/actions/books';
import { cloneDeep } from 'lodash';
import {
    chaptersSortFuncReverse,
    difference,
    roundDecimals,
    showAreYouSureSwal,
} from '@/utils/utils';
// import ChaptersPriceModal from '@/views/ManageBook/components/ChaptersPriceModal';

export default {
    name: 'ChaptersTab',
    components: {  InputLabel, Button, CustomEditor, InputField },
    data() {
        return {
            chapterForms: [],
            selectedChapterId: null,
        };
    },
    computed: {
        ...mapGetters({
            book: 'selectedBook',
        }),
        editMode() {
            return Boolean(this.selectedChapterId);
        },
        async() {
            return this.$store.state.books.async;
        },
        chapters() {
            return this.book?.chapters || [];
        },
        chaptersRangeText() {
            return `${1}-${this.chapters?.length}`;
        },
        totalBookPrice() {
            return roundDecimals(this.book?.price_in_cents / 100, 4);
        },
        isEditable() {
            return this.selectedChapterId !== 'all';
        },
        selectedChapterContent() {
            if (this.selectedChapterId === 'all') {
                return {
                    content: this.mergeChaptersContent(),
                };
            }

            return this.chapterForms.find(c => c.id === this.selectedChapterId) || {};
        },
        selectedChapter() {
            const index = this.chapterForms.findIndex(c => c.id === this.selectedChapterId);
            if (index !== -1) {
                return this.chapterForms[index];
            } else {
                return {};
            }
        },
    },
    methods: {
        ...mapActions({
            editChapterAction: EDIT_CHAPTER_ACTION,
            addChapterAction: ADD_CHAPTER_ACTION,
            deleteChapterAction: DELETE_CHAPTER_ACTION,
            moveChapterAction: MOVE_CHAPTER_ACTION,
        }),
        updateChapter(chapter) {
            const newForms = [...this.chapterForms];

            const chapterIndex = newForms.findIndex(c => c.id === chapter.id);
            if (chapterIndex !== -1) {
                const newChapter = cloneDeep(newForms[chapterIndex]);
                Object.assign(newChapter, chapter);
                newForms[chapterIndex] = newChapter;
            }

            this.chapterForms = newForms;
        },
        async moveChapter(chapter, up = true) {
            await this.moveChapterAction({ bookId: this.book.id, chapterId: chapter.id, up });

            this.$nextTick(() => {
                const forms = [...this.chapterForms];

                this.chapters.forEach(chapter => {
                    const i = forms.findIndex(f => f.id === chapter.id);

                    if (i !== -1) {
                        forms[i].order_index = chapter.order_index;
                    }
                });

                this.chapterForms = forms.sort(chaptersSortFuncReverse);
            });
        },
        isActiveChapter(chapterId) {
            return this.selectedChapterId === chapterId;
        },
        onContentChange(newContent) {
            this.selectedChapter.content = newContent;
        },
        selectChapter(chapterId) {
            this.selectedChapterId = chapterId;
        },
        async addNewChapter() {
            const titleChapterNum = this.chapters.length + 1;

            const newChapter = await this.addChapterAction({
                bookId: this.book.id,
                data: {
                    title: `Poglavlje ${titleChapterNum}`,
                },
            });

            this.chapterForms.unshift(cloneDeep(newChapter));
            this.selectedChapterId = newChapter.id;
        },
        removeChapterForm(chapterId) {
            const index = this.chapterForms.findIndex(c => c.id === chapterId);

            if (index !== -1) {
                this.chapterForms.splice(index, 1);
            }

            this.selectedChapterId = null;
        },
        deleteSelectedChapter() {
            showAreYouSureSwal({ text: 'Jeste li sigurni da želite izbrisati poglavlje?' }).then(result => {
                if (result.value) {
                    this.deleteChapterAction({
                        bookId: this.book.id,
                        chapterId: this.selectedChapterId,
                    }).then(() => {
                        this.removeChapterForm(this.selectedChapterId);
                    });
                }
            });
        },
        async onSave() {
            const promises = [];

            this.chapterForms.slice().reverse().forEach(chapterForm => {

                let diff = difference(chapterForm, this.chapters.find(c => c.id === chapterForm.id) || {});

                if (JSON.stringify(diff) !== '{}') {

                    chapterForm.price_in_cents = chapterForm.price_in_cents ?? 0;

                    promises.push(
                        this.editChapterAction({
                            bookId: this.book.id,
                            chapterId: chapterForm.id,
                            data: chapterForm,
                        }),
                    );
                }
            });

            await Promise.all(promises);
        },
        mergeChaptersContent() {
            return this.chapters.reduce((acc, chapter) => {
                return acc + `<h2>${chapter.title}</h2>${chapter.content}`;
            }, '');
        },
        setChapterForms() {
            this.chapterForms = cloneDeep(this.chapters).sort(chaptersSortFuncReverse);
        },
    },
    beforeMount() {
        this.setChapterForms();
    },
};
</script>

<style scoped lang="scss">

.chapters-tab {
    display: flex;
    background: #fff;
    padding: 30px 20px 20px 0;
    height: 100%;

    .chapters-list-container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        width: 380px;
        margin-right: 30px;

        .chapters-list {
            width: 380px;
            background: #fff;
            display: flex;
            flex-direction: column;
            overflow: auto;
            flex-basis: 0;
            flex-grow: 1;

            .single-chapter {
                display: flex;
                align-items: center;
                padding: 25px 10px 25px 16px;
                border-top: 1px solid $widgetBorderColor;
                cursor: pointer;

                &:last-of-type {
                    border-bottom: 1px solid $widgetBorderColor;
                }

                .chapter-number {
                    text-align: center;
                    line-height: 40px;
                    min-width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    background: $mainAccentColor;
                    color: #fff;
                    font-weight: 800;
                    font-size: 16px;
                    margin-right: 10px;
                }

                .up-down-arrows {
                    display: flex;
                    flex-direction: column;
                    margin-right: 12px;

                    .icon-up {
                        margin-bottom: 2px;
                    }

                    .icon-down {
                        margin-top: 2px;
                    }

                    .icon-up, .icon-down {
                        cursor: pointer;
                        opacity: 0.4;
                        font-weight: bold;
                        font-size: 12px;
                        transition: all 200ms ease-in-out;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .title-and-date {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    .title {
                        color: $darkTextColor;
                        margin-bottom: 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .date {
                        font-size: 14px;
                        color: $greyTextColor;

                    }
                }

                .price {
                    font-size: 16px;
                    color: $darkTextColor;
                    margin-left: auto;
                }

                &.total {
                    border-bottom: 1px solid $widgetBorderColor;
                    margin-bottom: 20px;
                    cursor: initial;
                }

                &.new-chapter {
                    cursor: initial;

                    .chapter-number {
                        background: rgba(52, 51, 91, 0.5);
                    }

                    .title {
                        color: $greyTextColor;
                    }

                    .add-new {
                        margin-left: auto;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }

                &.active, &:hover {
                    background: rgba(52, 51, 91, 0.07);
                }
            }
        }

    }

    .chapter-data {
        flex: 1;
        display: flex;
        flex-direction: column;

        .labeled-input {
            margin-bottom: 0;
        }

        .title-and-price {
            display: flex;

            .chapter-title {
                flex: 1;
            }

            .chapter-price {
                margin-right: 0;
            }
        }

        .custom-editor {
            //max-height: 520px; // TODO, do this properly
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: auto;

            .delete-btn {
                background: #F34658;
                border: none;
                margin-right: auto;

                &:hover {
                    background: rgba(243, 70, 88, 0.8);
                }
            }

            .save-btn {
                margin-left: 15px;
                min-width: 130px;
            }
        }
    }
}

</style>
