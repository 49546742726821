<template>
    <div class="login page">
        <div class="login-container">
            <div class="logo-container">
                <div class="logo-text"><span>TvornicaSnova</span> Admin</div>
            </div>

            <p class="welcome-back">{{ $t('welcome_back') }}!</p>
            <p class="sign-in-to-continue">{{ $t('sign_in_to_continue') }}</p>

            <div class="inputs">
                <container>
                    <input-field v-model="email"
                                 :disabled="async"
                                 v-on:keyup.native.enter="submit"
                                 name="email"
                                 autofill="username"
                                 :error="errors['email']"
                                 :placeholder="$t('email')"
                                 required
                                 type="email"/>
                </container>
                <container>
                    <input-field v-model="password"
                                 :disabled="async"
                                 v-on:keyup.native.enter="submit"
                                 style="margin-top: 24px;"
                                 autofill="password"
                                 name="password"
                                 :error="errors['password']"
                                 :placeholder="$t('password')"
                                 required
                                 type="password"/>
                </container>
            </div>
            <div v-for="error in errors" class="error" :key="error">
                <span>{{ error }}</span>
            </div>
            <container class="button-container">
                <Button :loading="async" @click="submit">
                    {{ $t('login') }}
                </Button>
            </container>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/Ui/InputField/InputField';
import { mapActions } from 'vuex';
import { PERFORM_LOGIN_ACTION } from '@/store/actions/login';
import Container from '@/components/Ui/Container/Container';
import Button from '@/components/Ui/Button/Button';

export default {
    name: 'Login',
    components: { Button, Container, InputField },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            email: '',
            password: '',
        };
    },
    computed: {
        errors() {
            return this.$store.state.login.loginErrors;
        },

        async() {
            return this.$store.state.login.async;
        },
    },
    methods: {
        ...mapActions({
            performLogin: PERFORM_LOGIN_ACTION,
        }),

        submit() {
            if (this.async) return;

            this.performLogin({
                email: this.email,
                password: this.password,
            });
        },
    },

};
</script>

<style scoped lang="scss">

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;

    .error {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 700;
        color: indianred;
    }

    .login-container {
        background: $pageBg;
        display: flex;
        flex-direction: column;
        z-index: 2;
        min-width: 40%;
        padding: 50px 70px;

        .welcome-back {
            font-size: 22px;
            font-weight: 600;
        }

        .sign-in-to-continue {
            font-size: 14px;
            margin-top: 12px;
            color: var(--secondary-color);
        }

        .inputs {
            margin-top: 10px;

            .outer-input-container {
                width: 22.5rem;
            }
        }

        .button-container {
            align-self: flex-end;
            margin-top: 16px;
        }
    }
}
</style>
