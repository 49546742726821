// actions
export const FETCH_ALL_BOOKS_ACTION = 'FETCH_ALL_BOOKS_ACTION';
export const FETCH_BOOKS_PAGE_ACTION = 'FETCH_BOOKS_PAGE_ACTION';
export const SEARCH_BOOKS_ACTION = 'SEARCH_BOOKS_ACTION';
export const ADD_BOOK_ACTION = 'ADD_BOOK_ACTION';
export const EDIT_BOOK_ACTION = 'EDIT_BOOK_ACTION';
export const DELETE_BOOK_ACTION = 'DELETE_BOOK_ACTION';
export const CHANGE_BOOK_IMAGE_ACTION = 'CHANGE_BOOK_IMAGE_ACTION';
export const EDIT_CHAPTER_ACTION = 'EDIT_CHAPTER_ACTION';
export const DELETE_CHAPTER_ACTION = 'DELETE_CHAPTER_ACTION';
export const ADD_CHAPTER_ACTION = 'ADD_CHAPTER_ACTION';
export const MOVE_CHAPTER_ACTION = 'MOVE_CHAPTER_ACTION';
export const GET_BOOK_BY_ID_ACTION = 'GET_BOOK_BY_ID_ACTION';
export const TOGGLE_BOOK_PUBLISHED_ACTION = 'TOGGLE_BOOK_PUBLISHED_ACTION';
export const REQUEST_APPROVAL_ACTION = 'REQUEST_APPROVAL_ACTION';
export const APPROVE_BOOK_ACTION = 'APPROVE_BOOK_ACTION';
export const REJECT_BOOK_ACTION = 'REJECT_BOOK_ACTION';
export const SET_BOOKS_UPLOAD_PROGRESS = 'SET_BOOKS_UPLOAD_PROGRESS';
export const SET_BOOKS_UPLOAD_ABORT_TOKEN = 'SET_BOOKS_UPLOAD_ABORT_TOKEN';
export const ADD_BOOK_DISCOUNT_ACTION = 'ADD_BOOK_DISCOUNT_ACTION';
export const DELETE_BOOK_DISCOUNT_ACTION = 'DELETE_BOOK_DISCOUNT_ACTION';
export const TOGGLE_BOOK_DISCOUNT_ACTION = 'TOGGLE_BOOK_DISCOUNT_ACTION';
export const CHANGE_BOOKS_PER_PAGE_ACTION = 'CHANGE_BOOKS_PER_PAGE_ACTION';

// mutations
export const SET_BOOKS = 'SET_BOOKS';
export const SELECT_BOOK = 'SELECT_BOOK';
export const SET_BOOKS_ASYNC = 'SET_BOOKS_ASYNC';
export const SET_SEARCH_BOOKS = 'SET_SEARCH_BOOKS';
export const SET_BOOKS_SEARCH_TEXT = 'SET_BOOKS_SEARCH_TEXT';
export const APPEND_BOOKS_PAGE = 'APPEND_BOOKS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_BOOK = 'UPDATE_BOOK';
export const ADD_NEW_BOOK = 'ADD_NEW_BOOK';
export const DELETE_BOOK = 'DELETE_BOOK';
export const SET_BOOKS_CURRENT_PAGE = 'SET_BOOKS_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER';
export const DELETE_CHAPTER = 'DELETE_CHAPTER';
export const SELECT_CHAPTER = 'SELECT_CHAPTER';
export const ADD_CHAPTER = 'ADD_CHAPTER';
export const ADD_BOOK_DISCOUNT = 'ADD_BOOK_DISCOUNT';
export const DELETE_BOOK_DISCOUNT = 'DELETE_BOOK_DISCOUNT';
export const UPDATE_BOOK_DISCOUNT = 'UPDATE_BOOK_DISCOUNT';
