<template>
    <div class="admin-analytics">
        <custom-table :select="true"
                      :fields="tableFields"
                      v-if="!async"
                      :data="analytics"
                      @column-click="item => checkBookDetails(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:amount_apple="props">
                <span>{{ parseCentsToEuros(props.item.apple_total_amount) }} </span>
            </template>

        </custom-table>
        <div class="chapterTransactions-loader-container" v-else>
            <spinner/>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/Ui/CustomTable/CustomTable";
import Spinner from "@/components/Ui/Spinner/Spinner";
import { mapGetters } from "vuex";
import { roundDecimals } from "@/utils/utils";

export default {
    name: 'AdminAppleAnalytics',
    components: {
        Spinner,
        CustomTable
    },
    data() {
        return {
            tableFields: [{
                name: 'title',
                title: 'Knjiga',
                enableClick: true
            }, {
                name: 'amount_apple',
                title: 'Apple (€)',
                slot: 'amount_apple'
            }]
        };
    },
    computed: {
        ...mapGetters({
            analytics: 'appleAnalytics',
            user: 'user'
        }),
        async() {
            return this.$store.state.chapterTransactions.async;
        },
    },
    methods: {
        onTableSelectItem(itemId) {
            console.log(itemId)
        },
        selectAllChanged(data) {
            console.log(data);
        },
        checkBookDetails(book) {
            console.log(book);
        },
        parseCentsToEuros(priceInCents) {
            return roundDecimals(priceInCents / 100);
        },
    },
};
</script>

<style scoped lang="scss">
.admin-analytics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .chapterTransactions-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
