<template>
    <div class="custom-pagination">
        <paginate
            v-model="currentPageVal"
            :page-count="totalPages"
            :prev-text="prevIcon"
            :next-text="nextIcon"
            container-class="pagination"/>
    </div>
</template>

<script>

import Paginate from 'vuejs-paginate'

export default {
    name: "CustomPagination",
    components: { Paginate },
    props: {
        totalPages: Number,
        currentPage: Number
    },
    computed: {
        prevIcon() {
            return "<i class='icon-down left-arrow'/>"
        },
        nextIcon() {
            return "<i class='icon-down right-arrow'/>"
        },
        currentPageVal: {
            get() {
                return this.currentPage;
            },
            set(val) {
                this.$emit('change', val);
            },
        },
    }
}
</script>

<style scoped lang="scss">

.custom-pagination {

    ::v-deep .pagination {
        display: flex;
        list-style: none;
        background: #fff;
        padding: 4px 10px;
        border-radius: 4px;
        box-shadow: $widgetShadow;

        li {
            margin: 0 5px;
            padding: 2px 8px;
            display: inline-block;
            font-size: 14px;
            border-radius: 5px;

            &.active {
                background: $mainAccentColor;
                color: #fff;
            }
        }

        li, a {
            &:focus {
                outline: none;
            }
        }

        .left-arrow, .right-arrow {
            font-size: 12px;
            font-weight: bold;
            display: inline-block;
        }

        .left-arrow {
            transform: rotate(90deg);
        }

        .right-arrow {
            transform: rotate(-90deg);
        }
    }
}

</style>