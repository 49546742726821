var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawer"},[_c('div',{staticClass:"title-holder"},[(_vm.isDrawerExpanded)?_c('span',[_vm._v("Tvornica Snova")]):_vm._e(),_c('i',{staticClass:"icon-menu drawer-toggle",on:{"click":_vm.toggleDrawerExpanded}})]),_c('div',{staticClass:"menu-items"},_vm._l((_vm.groupsForUser),function(group){return _c('div',{key:group.name,staticClass:"menu-item"},[_c('router-link',{attrs:{"to":group.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{class:['route-link', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[(_vm.isDrawerExpanded)?_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(group.name)+" ")]):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"menu-group"},_vm._l((group.items),function(item){return _c('div',{key:item.name,staticClass:"menu-sub-item"},[_c('router-link',{attrs:{"to":item.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{class:['route-link', isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[(_vm.isDrawerExpanded)?_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()])]}}],null,true)})],1)}),0)],1)}),0),_c('div',{staticClass:"profile-version-holder"},[_c('div',{staticClass:"profile-holder"},[_c('dropdown-menu',{attrs:{"anchor":"left"},scopedSlots:_vm._u([{key:"handle",fn:function(){return [_c('div',{staticClass:"profile",on:{"click":_vm.toggleUserDropdown}},[(_vm.userProfilePicture)?_c('img',{attrs:{"src":_vm.userProfilePicture,"alt":""}}):_c('rounded-initials',{attrs:{"user":_vm.user}})],1)]},proxy:true}])},[_c('dropdown-menu-item',{on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1),(_vm.isDrawerExpanded)?_c('span',{staticClass:"username"},[_vm._v(_vm._s(_vm._f("mergeName")(_vm.user)))]):_vm._e()],1),_c('div',{staticClass:"version-field",style:({visibility: _vm.isDrawerExpanded ? 'visible' : 'hidden'})},[_c('span',[_vm._v(_vm._s(_vm.versionText))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }