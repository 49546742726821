<template>
    <div class="rounded-initials">

        <span v-if="Boolean(user && user.first_name)">
            {{ user.first_name.charAt(0) }}
        </span>

        <span v-if="Boolean(user && user.last_name)">
            {{ user.last_name.charAt(0) }}
        </span>

        <span class="na-text" v-if="!Boolean(user) || (!Boolean(user.first_name) && !Boolean(user.last_name))">
            NN
        </span>
    </div>
</template>

<script>
export default {
    name: 'RoundedInitials',
    props: {
        user: Object,
    },
};
</script>

<style scoped lang="scss">

.rounded-initials {
    height: 35px;
    width: 35px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;
    background-color: $mainAccentColor;
    color: #fff;
    border: 1px solid transparent;

    .na-text {
        opacity: 0.5;
    }
}
</style>
