<template>
    <div class="users">

        <div class="table-filters">
            <span>Pokaži</span>
            <container class="per-page">
                <select-field :options="perPageOptions"
                              @input="changeUsersPerPage"
                              :filterable="false"
                              :clearable="false"
                              :value="perPage"/>
            </container>
            <container>
                <input-field
                    class="table-search-input"
                    @input="searchUsersAction"
                    type="text"
                    icon="icon-search"
                    :value="searchText"
                    placeholder="Pretraži Korisnike"/>
            </container>

            <container>
                <Button :flat="true" @click="goToManageUser">
                    Dodaj Korisnika
                </Button>
            </container>
        </div>

        <custom-table :select="true"
                      :fields="tableFields"
                      :data="usersPage"
                      v-if="!async"
                      @column-click="item => editItem(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:name="props">
                <span>{{ props.item | mergeName }}</span>
            </template>

            <template v-slot:created_at="props">
                <span>{{ props.item.created_at | moment('DD.MM.YYYY') }}</span>
            </template>
            <template v-slot:actions="props">
                <div>
                    <dropdown-menu anchor="right">
                        <template v-slot:handle>
                            <i style="font-size: 15px" class="icon icon-menudots"/>
                        </template>

                        <dropdown-menu-item @click="editItem(props.item.id)">
                            Edituj
                        </dropdown-menu-item>
                        <dropdown-menu-item @click="deleteUser(props.item.id)">
                            Izbriši
                        </dropdown-menu-item>
                    </dropdown-menu>
                </div>
            </template>
        </custom-table>
        <div class="publishers-loader-container" v-else>
            <spinner/>
        </div>

        <div class=" footer">
            <custom-pagination :total-pages="totalPages"
                               :current-page="currentPage"
                               @change="changeUsersPage"/>
        </div>
    </div>
</template>

<script>
import CustomTable from '@/components/Ui/CustomTable/CustomTable';
import CustomPagination from '@/components/CustomPagination/CustomPagination';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RouteLink } from '@/store/modules/menu';
import {
    CHANGE_USERS_PER_PAGE_ACTION,
    DELETE_USER_ACTION,
    FETCH_ALL_USERS_ACTION,
    SEARCH_USERS_ACTION,
    SELECT_USER,
    SET_USERS_CURRENT_PAGE,
} from '@/store/actions/users';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import SelectField from '@/components/Ui/SelectField/SelectField';
import Button from '@/components/Ui/Button/Button';
import { showAreYouSureSwal, showSuccessSwal } from "@/utils/utils";
import DropdownMenuItem from "@/components/Ui/DropdownMenu/DropdownMenuItem";
import DropdownMenu from "@/components/Ui/DropdownMenu/DropdownMenu";
import Spinner from '@/components/Ui/Spinner/Spinner';

export default {
    name: 'Users',
    components: {
        Spinner,
        DropdownMenu,
        DropdownMenuItem,
        Button,
        SelectField,
        InputField,
        Container,
        CustomPagination,
        CustomTable
    },
    data() {
        return {
            tableFields: [{
                name: 'name',
                title: 'Ime',
                slot: 'name',
                enableClick: true,
            }, {
                name: 'email',
                title: 'Email',
            }, {
                name: 'phone',
                title: 'Broj telefona',
            }, {
                accessor: 'publisher.name',
                title: 'Izdavač',
            }, {
                name: 'created_at',
                title: 'Datum Kreiranja',
                slot: "created_at",
            }, {
                name: 'actions',
                title: '',
                width: '3%',
                lightText: true,
                slot: 'actions',
            }]
        };
    },
    computed: {
        ...mapGetters({
            usersPage: 'paginatedUsers',
            totalPages: 'totalUsersPages',
        }),
        async() {
            return this.$store.state.users.async;
        },
        searchText() {
            return this.$store.state.users.searchText;
        },
        currentPage() {
            return this.$store.state.users.currentPage;
        },
        perPage() {
            return this.$store.state.users.perPage;
        },
        perPageOptions() {
            return [10, 25, 50];
        }
    },
    methods: {
        ...mapMutations({
            changeUsersPage: SET_USERS_CURRENT_PAGE,
            selectUser: SELECT_USER,
        }),

        ...mapActions({
            fetchAllUsersAction: FETCH_ALL_USERS_ACTION,
            searchUsersAction: SEARCH_USERS_ACTION,
            deleteUserAction: DELETE_USER_ACTION,
            changeUsersPerPage: CHANGE_USERS_PER_PAGE_ACTION
        }),
        goToManageUser() {
            this.$router.push(RouteLink.ManageUser);
        },
        onTableSelectItem(itemId) {
            console.log(itemId)
        },
        selectAllChanged(data) {
            console.log(data);
        },
        editItem(id) {
            this.selectUser(id);
            this.$router.push(RouteLink.ManageUser + `/${id}`);
        },
        deleteUser(userId) {
            showAreYouSureSwal({ text: "Jeste li sigurni da želite izbrisati korisnika?" }).then(result => {
                if (result.value) {
                    this.deleteUserAction(userId).then(() => showSuccessSwal())
                }
            });
        }
    },
    mounted() {
        this.fetchAllUsersAction();
    }
};
</script>

<style scoped lang="scss">
.users {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .publishers-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
