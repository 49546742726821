import { render, staticRenderFns } from "./EditorMenu.vue?vue&type=template&id=134ae8cc&scoped=true&"
import script from "./EditorMenu.vue?vue&type=script&lang=js&"
export * from "./EditorMenu.vue?vue&type=script&lang=js&"
import style0 from "../sass/editor_main.scss?vue&type=style&index=0&id=134ae8cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134ae8cc",
  null
  
)

export default component.exports