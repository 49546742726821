<template>
    <div class="categories">

        <div class="table-filters">
            <span>Pokaži</span>
            <container class="per-page">
                <select-field :options="perPageOptions"
                              @input="changeCategoriesPerPage"
                              :filterable="false"
                              :clearable="false"
                              :value="perPage"/>
            </container>
            <container>
                <input-field
                    class="table-search-input"
                    @input="searchCategoriesAction"
                    type="text"
                    icon="icon-search"
                    :value="searchText"
                    placeholder="Pretraži Kategorije"/>
            </container>

            <container>
                <Button :flat="true" @click="goToManageCategory">
                    Dodaj Kategoriju
                </Button>
            </container>
        </div>

        <custom-table :select="true"
                      :fields="tableFields"
                      :data="categoriesPage"
                      v-if="!async"
                      @column-click="item => editItem(item.id)"
                      @selectAllChanged="selectAllChanged"
                      @selectItem="onTableSelectItem">

            <template v-slot:created_at="props">
                <span>{{ props.item.created_at | moment('DD.MM.YYYY') }}</span>
            </template>
            <template v-slot:actions="props">
                <div>
                    <dropdown-menu anchor="right">
                        <template v-slot:handle>
                            <i style="font-size: 15px" class="icon icon-menudots"/>
                        </template>

                        <dropdown-menu-item @click="editItem(props.item.id)">
                            Edituj
                        </dropdown-menu-item>
                        <dropdown-menu-item @click="deleteCategory(props.item.id)">
                            Izbriši
                        </dropdown-menu-item>
                    </dropdown-menu>
                </div>
            </template>
        </custom-table>
        <div class="categories-loader-container" v-else>
            <spinner/>
        </div>

        <div class="footer">
            <custom-pagination :total-pages="totalPages"
                               :current-page="currentPage"
                               @change="changeCategoriesPage"/>
        </div>
    </div>
</template>

<script>
import CustomTable from '@/components/Ui/CustomTable/CustomTable';
import CustomPagination from '@/components/CustomPagination/CustomPagination';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RouteLink } from '@/store/modules/menu';
import {
    CHANGE_CATEGORIES_PER_PAGE_ACTION,
    DELETE_CATEGORY_ACTION,
    FETCH_ALL_CATEGORIES_ACTION,
    SEARCH_CATEGORIES_ACTION,
    SELECT_CATEGORY,
    SET_CATEGORIES_CURRENT_PAGE,
} from '@/store/actions/categories';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import SelectField from '@/components/Ui/SelectField/SelectField';
import Button from '@/components/Ui/Button/Button';
import DropdownMenu from "@/components/Ui/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "@/components/Ui/DropdownMenu/DropdownMenuItem";
import { showAreYouSureSwal, showSuccessSwal } from "@/utils/utils";
import Spinner from '@/components/Ui/Spinner/Spinner';

export default {
    name: 'Categories',
    components: {
        Spinner,
        DropdownMenuItem,
        DropdownMenu,
        Button,
        SelectField,
        InputField,
        Container,
        CustomPagination,
        CustomTable
    },
    data() {
        return {
            tableFields: [{
                name: 'name',
                title: 'Ime',
                enableClick: true
            }, {
                name: 'description',
                title: 'Opis',
                lightText: true,
            }, {
                name: 'created_at',
                title: 'Datum Kreiranja',
                slot: "created_at",
            }, {
                name: 'actions',
                title: '',
                width: '3%',
                lightText: true,
                slot: 'actions',
            }]
        };
    },
    computed: {
        ...mapGetters({
            categoriesPage: 'paginatedCategories',
            totalPages: 'totalCategoriesPages',
        }),
        async() {
            return this.$store.state.categories.async;
        },
        searchText() {
            return this.$store.state.categories.searchText;
        },
        currentPage() {
            return this.$store.state.categories.currentPage;
        },
        perPage() {
            return this.$store.state.categories.perPage;
        },
        perPageOptions() {
            return [10, 25, 50];
        },
    },
    methods: {
        ...mapMutations({
            changeCategoriesPage: SET_CATEGORIES_CURRENT_PAGE,
            selectCategory: SELECT_CATEGORY,
        }),

        ...mapActions({
            fetchAllCategoriesAction: FETCH_ALL_CATEGORIES_ACTION,
            searchCategoriesAction: SEARCH_CATEGORIES_ACTION,
            deleteCategoryAction: DELETE_CATEGORY_ACTION,
            changeCategoriesPerPage: CHANGE_CATEGORIES_PER_PAGE_ACTION
        }),
        goToManageCategory() {
            this.$router.push(RouteLink.ManageCategory);
        },
        onTableSelectItem(itemId) {
            console.log(itemId)
        },
        selectAllChanged(data) {
            console.log(data);
        },
        editItem(id) {
            this.selectCategory(id);
            this.$router.push(RouteLink.ManageCategory + `/${id}`);
        },
        deleteCategory(categoryId) {
            showAreYouSureSwal({ text: "Jeste li sigurni da želite izbrisati kategoriju?" }).then(result => {
                if (result.value) {
                    this.deleteCategoryAction(categoryId).then(() => showSuccessSwal())
                }
            });
        }
    },
    mounted() {
        this.fetchAllCategoriesAction();
    }
};
</script>

<style scoped lang="scss">
.categories {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    .table-filters {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .per-page {
            margin-right: auto;
        }

        div.tvornica-snova.container-padder {
            min-width: 80px;
            width: fit-content;
            margin-left: 10px;
        }
    }

    .categories-loader-container {
        display: flex;
        width: 100%;
        align-self: center;
        justify-content: center;

        .spinner-container {
            align-self: center;
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
