// actions
export const FETCH_ALL_TAGS_ACTION = 'FETCH_ALL_TAGS_ACTION';
export const FETCH_TAGS_PAGE_ACTION = 'FETCH_TAGS_PAGE_ACTION';
export const SEARCH_TAGS_ACTION = 'SEARCH_TAGS_ACTION';
export const ADD_TAG_ACTION = 'ADD_TAG_ACTION';
export const EDIT_TAG_ACTION = 'EDIT_TAG_ACTION';
export const DELETE_TAG_ACTION = 'DELETE_TAG_ACTION';
export const GET_TAG_BY_ID_ACTION = 'GET_TAG_BY_ID_ACTION';
export const CHANGE_TAGS_PER_PAGE_ACTION = 'CHANGE_TAGS_PER_PAGE_ACTION';

// mutations
export const SET_TAGS = 'SET_TAGS';
export const SELECT_TAG = 'SELECT_TAG';
export const SET_TAGS_ASYNC = 'SET_TAGS_ASYNC';
export const SET_SEARCH_TAGS = 'SET_SEARCH_TAGS';
export const SET_TAGS_SEARCH_TEXT = 'SET_TAGS_SEARCH_TEXT';
export const APPEND_TAGS_PAGE = 'APPEND_TAGS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_TAG = 'UPDATE_TAG';
export const ADD_NEW_TAG = 'ADD_NEW_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const SET_TAGS_CURRENT_PAGE = 'SET_TAGS_CURRENT_PAGE';
export const SET_TAG_TOTAL_PAGES = 'SET_TAG_TOTAL_PAGES';
