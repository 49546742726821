import { apiCall, promocodes } from '@/api/apiCall';

export async function fetchPromocodesPage(page = 1, perPage = 10) {
    let url = new URL(promocodes);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editPromocode(promocodeId, data) {
    return apiCall({
        url: promocodes + `/${promocodeId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addPromocode(data) {
    return apiCall({
        url: promocodes,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deletePromocode(promocodeId) {
    return apiCall({
        url: promocodes + `/${promocodeId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchPromocodeById(promocodeId) {
    return apiCall({
        url: `${promocodes}/${promocodeId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
