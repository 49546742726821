<template>
    <div class="manage-book">
        <vue-element-loading :active="async" class="vue-element-loader">
            <spinner/>
        </vue-element-loading>
        <div class="book-title">
            {{ bookTitle }}
        </div>
        <div class="book-status">
            <book-status-dot :status="bookStatus"/>
            <span>{{ bookStatus|parseBookStatus }}</span>
        </div>
        <custom-tabs>
            <tab name="Dostupnost i Rezervacija" :disabled="editMode" :selected="true">
                <reservation-tab @save="onSave"/>
            </tab>
            <tab name="Status" :disabled="!editMode" :selected="true">
                <status-tab :book="form" @save="onSave"/>
            </tab>
            <tab name="Generalni podaci" :disabled="!editMode">
                <general-data-tab :book="form" @save="onSave"/>
            </tab>
            <tab name="Poglavlja" :disabled="!editMode">
                <chapters-tab/>
            </tab>
            <tab name="Bonusi" :disabled="!editMode">
                <bonus-tab/>
            </tab>
        </custom-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BookStatusDot from '@/components/BookStatusDot/BookStatusDot';
import CustomTabs from '@/components/Ui/CustomTabs/CustomTabs';
import Tab from '@/components/Ui/CustomTabs/Tab';
import GeneralDataTab from '@/views/ManageBook/components/GeneralDataTab';
import ChaptersTab from '@/views/ManageBook/components/ChaptersTab';
import {
    ADD_BOOK_ACTION,
    CHANGE_BOOK_IMAGE_ACTION,
    EDIT_BOOK_ACTION,
    GET_BOOK_BY_ID_ACTION,
    SELECT_BOOK,
} from '@/store/actions/books';
import { cloneDeep } from 'lodash';
import Spinner from '@/components/Ui/Spinner/Spinner';
import StatusTab from '@/views/ManageBook/components/StatusTab';
import ReservationTab from '@/views/ManageBook/components/ReservationTab';
import { difference } from '@/utils/utils';
import BonusTab from '@/views/ManageBook/components/BonusTab';

const transformFormFields = (form) => {

    if (form.author) {
        form.author = form.author.id;
    }

    if (form.tags) {
        form.tags = form.tags.map(t => t.id);
    }

    if (form.publisher) {
        form.publisher = form.publisher.id;
    }

    if (form.categories) {
        form.categories = form.categories.map(c => c.id);
    }

    if (form.discounts) {
        form.discounts = form.discounts.map(c => c.id);
    }

    form.priceEuros = (form.price_in_cents ?? 0) / 100;

    form.images = [];

    return form;
};

export default {
    name: 'ManageBook',
    components: {
        BonusTab,
        ReservationTab,
        StatusTab,
        Spinner,
        ChaptersTab,
        GeneralDataTab,
        Tab,
        CustomTabs,
        BookStatusDot,
    },
    data() {
        return {
            editMode: false,
            errors: {},
            originalBook: {},
            form: {},
        };
    },
    computed: {
        ...mapGetters({
            book: 'selectedBook',
        }),
        async() {
            return this.$store.state.books.async;
        },
        bookTitle() {
            return this.book?.title ?? 'Novi naslov';
        },
        bookStatus() {
            return this.book?.status;
        }
    },
    watch: {
        book: function (newBook) {
            this.form = transformFormFields(cloneDeep(newBook));
            this.originalBook = cloneDeep(newBook);
        }
    },
    methods: {
        ...mapMutations({
            selectBook: SELECT_BOOK,
        }),
        ...mapActions({
            getBookById: GET_BOOK_BY_ID_ACTION,
            editBookAction: EDIT_BOOK_ACTION,
            addBookAction: ADD_BOOK_ACTION,
            changeBookImageAction: CHANGE_BOOK_IMAGE_ACTION,
        }),
        goEditMode(book) {
            this.editMode = true;
            this.originalBook = cloneDeep(book);
            this.form = transformFormFields(cloneDeep(book));
        },
        validateFields(form) {
            const errors = {};
            if (form.original_title !== undefined && !form.original_title) {
                errors['original_title'] = this.$t('title_is_required');
            }

            if (form.author !== undefined && !form.author) {
                errors['author'] = this.$t('author_is_required');
            }

            this.errors = errors;

            return Object.keys(errors).length === 0;
        },
        async onSave(form) {

            if (!this.validateFields(form)) {         // fields not valid
                return;
            }

            // handle price in cents
            form.price_in_cents = (parseInt(form.priceEuros * 100)) ?? 0;

            let changed = difference(form, this.originalBook);

            if (this.editMode) {
                await this.editBookAction({
                    id: this.form.id,
                    data: changed,
                });

                // EDIT IMAGE
                if (changed.images) {
                    await this.changeBookImageAction({
                        bookId: this.form.id,
                        images: changed.images,
                    });
                }

            } else {

                delete form.price_in_cents;

                const newBook = await this.addBookAction(form);

                if (form.images && newBook) {
                    await this.changeBookImageAction({
                        bookId: newBook.id,
                        images: form.images,
                    });
                }

                if (newBook) {
                    this.selectBook(newBook.id);
                    this.goEditMode(newBook);
                }
            }
        },
    },
    async beforeMount() {

        const { params } = this.$route;

        if (params.id) {
            await this.getBookById({ bookId: params.id });
        }

        if (this.book) {
            this.goEditMode(this.book);
        }
    },
    beforeDestroy() {
        this.selectBook(null);
    },
};
</script>

<style scoped lang="scss">

.manage-book {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    .book-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 36px;
        font-weight: bold;
        color: $darkTextColor;

        .book-warning {
            font-weight: normal;
            color: #faad14;
            font-size: 18px;
        }
    }

    .book-status {
        align-items: center;
        display: flex;
        margin-bottom: 20px;

        .book-status-dot {
            margin-right: 5px;
        }
    }

    .vue-element-loader {
        z-index: 2;
    }
}

</style>
