<template>
    <div @click="handleClick" class="tvornica-snova dropdown-menu-item">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'DropdownMenuItem',
    methods: {
        handleClick(e) {
            e.preventDefault();
            e.stopPropagation();
            this.$emit('click', e)
        }
    }
};
</script>

<style scoped lang="scss">
div.tvornica-snova.dropdown-menu-item {
    background: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    white-space: nowrap;
    font-size: 15px;

    &:hover {
        background: #f0f0f0;
    }
}
</style>
