import { apiCall, agent_books } from '@/api/apiCall';

export async function fetchAgentBooksPage(page = 1, perPage = 10) {
    let url = new URL(agent_books);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json());
}

export async function editAgentBook(agentBookId, data) {
    return apiCall({
        url: agent_books + `/${agentBookId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addAgentBook(data) {
    return apiCall({
        url: agent_books,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteAgentBook(agentBookId) {
    return apiCall({
        url: agent_books + `/${agentBookId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function fetchAgentBookById(agentBookId) {
    return apiCall({
        url: `${agent_books}/${agentBookId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
