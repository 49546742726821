// actions
export const FETCH_ALL_CHAPTER_TRANSACTIONS_ACTION = 'FETCH_ALL_CHAPTER_TRANSACTIONS_ACTION';
export const FETCH_CHAPTER_TRANSACTIONS_PAGE_ACTION = 'FETCH_CHAPTER_TRANSACTIONS_PAGE_ACTION';
export const SEARCH_CHAPTER_TRANSACTIONS_ACTION = 'SEARCH_CHAPTER_TRANSACTIONS_ACTION';
export const ADD_CHAPTER_TRANSACTION_ACTION = 'ADD_CHAPTER_TRANSACTION_ACTION';
export const EDIT_CHAPTER_TRANSACTION_ACTION = 'EDIT_CHAPTER_TRANSACTION_ACTION';
export const DELETE_CHAPTER_TRANSACTION_ACTION = 'DELETE_CHAPTER_TRANSACTION_ACTION';
export const GET_CHAPTER_TRANSACTION_BY_ID_ACTION = 'GET_CHAPTER_TRANSACTION_BY_ID_ACTION';
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS'
export const FETCH_APPLE_ANALYTICS = 'FETCH_APPLE_ANALYTICS'
export const CHANGE_CHAPTER_TRANSACTIONS_PER_PAGE_ACTION = 'CHANGE_CHAPTER_TRANSACTIONS_PER_PAGE_ACTION';

// mutations
export const SET_CHAPTER_TRANSACTIONS = 'SET_CHAPTER_TRANSACTIONS';
export const SELECT_CHAPTER_TRANSACTION = 'SELECT_CHAPTER_TRANSACTION';
export const SET_CHAPTER_TRANSACTIONS_ASYNC = 'SET_CHAPTER_TRANSACTIONS_ASYNC';
export const SET_SEARCH_CHAPTER_TRANSACTIONS = 'SET_SEARCH_CHAPTER_TRANSACTIONS';
export const SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT = 'SET_CHAPTER_TRANSACTIONS_SEARCH_TEXT';
export const APPEND_CHAPTER_TRANSACTIONS_PAGE = 'APPEND_CHAPTER_TRANSACTIONS_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_CHAPTER_TRANSACTION = 'UPDATE_CHAPTER_TRANSACTION';
export const ADD_NEW_CHAPTER_TRANSACTION = 'ADD_NEW_CHAPTER_TRANSACTION';
export const DELETE_CHAPTER_TRANSACTION = 'DELETE_CHAPTER_TRANSACTION';
export const SET_CHAPTER_TRANSACTIONS_CURRENT_PAGE = 'SET_CHAPTER_TRANSACTIONS_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_CHAPTER_TRANSACTIONS_UPLOAD_ABORT_TOKEN = 'SET_CHAPTER_TRANSACTIONS_UPLOAD_ABORT_TOKEN';
export const SET_CHAPTER_TRANSACTIONS_UPLOAD_PROGRESS = 'SET_CHAPTER_TRANSACTIONS_UPLOAD_PROGRESS';
export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_APPLE_ANALYTICS = 'SET_APPLE_ANALYTICS';
