import {
    SELECT_PUBLISHER,
    SET_PUBLISHERS,
    FETCH_ALL_PUBLISHERS_ACTION,
    SET_PUBLISHERS_ASYNC,
    SET_SEARCH_PUBLISHERS,
    SET_PUBLISHERS_SEARCH_TEXT,
    FETCH_PUBLISHERS_PAGE_ACTION,
    APPEND_PUBLISHERS_PAGE,
    SEARCH_PUBLISHERS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_PUBLISHER_ACTION,
    ADD_PUBLISHER_ACTION,
    UPDATE_PUBLISHER,
    ADD_NEW_PUBLISHER,
    DELETE_PUBLISHER_ACTION,
    DELETE_PUBLISHER,
    SET_PUBLISHERS_CURRENT_PAGE,
    SET_PUBLISHER_TOTAL_PAGES,
     GET_PUBLISHER_BY_ID_ACTION, CHANGE_PUBLISHERS_PER_PAGE_ACTION,
} from '@/store/actions/publishers';
import {
    addPublisher,
    deletePublisher,
    editPublisher,
    fetchPublishersPage,
} from '@/api/publishers/publishers';
import { getPerPage, searchPublisher, setPerPage } from '@/utils/utils';
import { fetchPublisherById } from "@/api/publishers/publishers";
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        publishers: [],

        // Filtering
        searchText: null,
        searchPublishers: null,
        searchAsync: false,
        selectedPublisherId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        async: false,
    }),
    mutations: {
        [SET_PUBLISHERS](state, Publishers) {
            state.publishers = Publishers;
        },
        [SELECT_PUBLISHER](state, publisherId) {
            state.selectedPublisherId = publisherId;
        },
        [SET_PUBLISHERS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_PUBLISHERS](state, searchPublishers) {
            state.searchPublishers = searchPublishers;
        },
        [SET_PUBLISHERS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_PUBLISHERS_PAGE](state, Publishers) {
            if (state.publishers !== null) {
                state.publishers = state.publishers.concat(Publishers);
            } else {
                state.publishers = [...Publishers];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_PUBLISHER](state, { id, ...data }) {
            for (let publisher of state.publishers) {
                if (publisher.id === id) {
                    Object.assign(publisher, data);
                    return;
                }
            }
        },
        [ADD_NEW_PUBLISHER](state, newpublisher) {
            const index = state.publishers.findIndex(publisher => publisher.id === newpublisher.id);

            if (index === -1) {
                state.publishers.unshift(newpublisher);
            }
        },
        [DELETE_PUBLISHER](state, publisherId) {
            if (state.publishers !== null) {
                state.publishers = state.publishers.filter(publisher => publisher.id !== publisherId);
            }
        },
        [SET_PUBLISHERS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_PUBLISHER_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
    },
    actions: {
        async [FETCH_ALL_PUBLISHERS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_PUBLISHERS_ASYNC, true);
            commit(SET_PUBLISHERS, []);
            commit(SET_SEARCH_PUBLISHERS, null);
            commit(SET_PUBLISHERS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_PUBLISHERS_PAGE_ACTION, { page });
            }

            commit(SET_PUBLISHERS_CURRENT_PAGE, 1);
            commit(SET_PUBLISHER_TOTAL_PAGES, page);

            commit(SET_PUBLISHERS_ASYNC, false);
        },
        async [FETCH_PUBLISHERS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Publishers,
                } = await fetchPublishersPage(page, state.perPage);
                if (Array.isArray(Publishers)) {
                    commit(APPEND_PUBLISHERS_PAGE, Publishers);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Publishers Page', ex);
            }
        },
        [SEARCH_PUBLISHERS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_PUBLISHERS_SEARCH_TEXT, null);
                commit(SET_SEARCH_PUBLISHERS, null);
            } else {
                commit(SET_PUBLISHERS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_PUBLISHERS, state.publishers.filter(publisher => searchPublisher(publisher, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_PUBLISHER_ACTION]({ commit }, { id, ...data }) {
            commit(SET_PUBLISHERS_ASYNC, true);
            try {
                const updatedPublisher = await editPublisher(id, data);
                commit(UPDATE_PUBLISHER, updatedPublisher);
            } finally {
                commit(SET_PUBLISHERS_ASYNC, false);
            }
        },
        async [ADD_PUBLISHER_ACTION]({ commit }, data) {
            commit(SET_PUBLISHERS_ASYNC, true);
            try {
                const newPublisher = await addPublisher(data);
                commit(ADD_NEW_PUBLISHER, newPublisher);
                return newPublisher;
            } finally {
                commit(SET_PUBLISHERS_ASYNC, false);
            }
        },
        async [DELETE_PUBLISHER_ACTION]({ commit }, publisherId) {
            commit(SET_PUBLISHERS_ASYNC, true);
            try {
                await deletePublisher(publisherId);
                commit(DELETE_PUBLISHER, publisherId);
            } finally {
                commit(SET_PUBLISHERS_ASYNC, false);
            }
        },
        async [GET_PUBLISHER_BY_ID_ACTION]({ state, commit }, { publisherId }) {
            if (state.async) return;

            commit(SET_PUBLISHERS_ASYNC, true);
            const publisher = await fetchPublisherById(publisherId).catch(() => commit(SET_PUBLISHERS_ASYNC, false));

            if (publisher) {
                state.publishers = [publisher, ...state.publishers];
                commit(SELECT_PUBLISHER, publisher.id);
            }

            commit(SET_PUBLISHERS_ASYNC, false);
        },
        async [CHANGE_PUBLISHERS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);
            commit(UPDATE_PER_PAGE);
            dispatch(FETCH_ALL_PUBLISHERS_ACTION);
        }
    },
    getters: {
        selectedPublisher(state) {
            return state.publishers.find(b => b.id === state.selectedPublisherId);
        },
        publisherOptions(state) {
            return state.publishers.map(m => ({ label: m.name, value: m.id }));
        },
        allPublishers(state) {
            if (state.searchPublishers !== null) {
                return state.searchPublishers;
            } else {
                return state.publishers;
            }
        },
        paginatedPublishers(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allPublishers.slice(beginIndex, endIndex);
        },
        totalPublishersPages(state) {
            return state.totalPages;
        },
        publishersAsync(state) {
            return state.async;
        }
    },
};
