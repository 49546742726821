<template>
    <div class="dashboard">
        hello dashboard
    </div>
</template>

<script>
export default {
    name: "Home",
};
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
