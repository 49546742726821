<template>
    <div class="select-container">
        <vue-select v-model="inputVal"
                    :taggable="isTaggable"
                    @option:created="onAddOption"
                    :createOption="createOption"
                    :options="options"
                    :filterable="filterable"
                    :clearable="clearable"
                    :multiple="multiple"
                    :reduce="valueAccessor"
                    :disabled="disabled">
            <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                    Nisu nađeni rezultati za <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Počnite kucati da pretražite.</em>
            </template>
        </vue-select>
    </div>
</template>

<script>

import VueSelect from 'vue-select';
import { getDeepProp } from "@/utils/utils";

export default {
    name: 'SelectField',
    components: { VueSelect },
    props: {
        name: String,
        options: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false
        },
        accessor: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: [String, Number, Array],
        placeholder: String,
        filterable: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        onAddOption: {              // This one is called when enter is pressed on creating new option
            type: Function,
            default() {
                //
            }
        },
        createOption: {                 // This function only transforms new option while it's typed
            type: Function,
            default(newOption) {
                if (typeof this.options[0] === 'object') {
                    newOption = { label: newOption, value: newOption }
                }

                return newOption
            }
        },
    },
    methods: {
        valueAccessor(option) {
            if (this.accessor) {
                return getDeepProp(option, this.accessor);
            }

            // Try to default to 'value' field
            if (typeof (option) === "object" && option?.value) {
                return option.value;
            }

            return option;
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        isTaggable() {
            // Check if user set the needed func
            return this.$options.propsData.onAddOption !== undefined;
        }
    },
};
</script>

<style lang="scss" scoped>

.select-container {
    &::v-deep .v-select {

        .vs__dropdown-toggle {
            border: none;
            padding: 5px;
            align-items: center;
        }

        .vs__selected {
            font-size: 12px;
            margin-top: 0;
            padding: 2px 14px;
            background: #EAEDF0;
            border: none;
            border-radius: 4px;
            margin-right: 15px;
        }

        &.vs--single .vs__selected {
            padding: 0 0.25em;
            font-size: 14px;
            background: transparent;
            margin-right: 0;
        }

        .vs__search {
            padding: 0;

            &:focus {
                padding: 0 7px;
            }
        }

        .vs__deselect {
            margin-left: 10px;
            transform: scale(0.9);
        }

        .vs__open-indicator {
            transform: scale(0.8);
        }

        .vs--open {
            .vs__open-indicator {
                transform: rotate(180deg) scale(0.8);
            }
        }

        .vs__actions {
            padding-bottom: 4px;
            height: fit-content;
        }
    }
}

</style>
