<template>
    <div class="tvornica-snova container-padder">
        <div class="tvornica-snova container">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Container"
}
</script>

<style scoped lang="scss">
div.tvornica-snova.container-padder {
    div.tvornica-snova.container {
        background: #ffffff;
        box-shadow: $widgetShadow;
        border-radius: 4px;
    }
}
</style>
