<template>
    <div class="manage-tag">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="tag-title">Novi Tag</div>
        <div class="tag-status">
        </div>
        <container class="body-container">
            <input-label :required="true" :error="errors.name" label="Naziv ">
                <input-field v-model="form.name" placeholder="Naziv"/>
            </input-label>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <Button class="save-button" @click="submit" :loading="async">
                Snimi
            </Button>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference } from '@/utils/utils';
import { ADD_TAG_ACTION, EDIT_TAG_ACTION, GET_TAG_BY_ID_ACTION, SELECT_TAG } from '@/store/actions/tags';
import { cloneDeep } from 'lodash';
import Spinner from "@/components/Ui/Spinner/Spinner";

const defaultForm = {
    name: '',
    description: '',
};

export default {
    name: 'ManageTag',
    components: { Spinner, Button, InputLabel, InputField, Container },
    data() {
        return {
            form: cloneDeep(defaultForm),
            errors: {
                general: [],
            },
        };
    },
    methods: {
        ...mapActions({
            addTag: ADD_TAG_ACTION,
            editTag: EDIT_TAG_ACTION,
            getTagById: GET_TAG_BY_ID_ACTION
        }),
        ...mapMutations({
            selectTag: SELECT_TAG,
        }),
        setData() {
            if (this.tag) {
                this.form = cloneDeep(this.tag);
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.name) {
                errors.name = 'Naziv je obavezan';
            }

            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        async submit() {
            if (!this.validateFields()) return;

            if (this.tag) {
                try {
                    await this.editTag({ id: this.tag.id, ...difference(this.form, this.tag) });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing tag');
                }
            } else {
                //adding
                try {
                    await this.addTag({ ...difference(this.form, defaultForm) }).then(() => this.$router.back());
                } catch (ex) {
                    this.errors.general.push('Error adding tag');
                    console.error('error adding tag');
                }
            }
        },
    },
    async beforeMount() {

        const { params } = this.$route;

        if (!this.tag) {

            if (params.id) {
                await this.getTagById({ tagId: params.id });
            }
        }

        this.setData();
    },
    beforeDestroy() {
        this.selectTag(null);
    },
    computed: {
        ...mapGetters({
            tag: 'selectedTag',
        }),
        async() {
            return this.$store.state.tags.async;
        },
    },
};
</script>

<style lang="scss">
.manage-tag {
    .body-container {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .labeled-input {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .save-button {
            margin-left: auto;
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }
    }
}
</style>
