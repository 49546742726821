import {
    SELECT_DISCOUNT,
    SET_DISCOUNTS,
    FETCH_ALL_DISCOUNTS_ACTION,
    SET_DISCOUNTS_ASYNC,
    SET_SEARCH_DISCOUNTS,
    SET_DISCOUNTS_SEARCH_TEXT,
    FETCH_DISCOUNTS_PAGE_ACTION,
    APPEND_DISCOUNTS_PAGE,
    SEARCH_DISCOUNTS_ACTION,
    SET_SEARCH_ASYNC,
    EDIT_DISCOUNT_ACTION,
    ADD_DISCOUNT_ACTION,
    UPDATE_DISCOUNT,
    ADD_NEW_DISCOUNT,
    DELETE_DISCOUNT_ACTION,
    DELETE_DISCOUNT,
    SET_DISCOUNTS_CURRENT_PAGE,
    SET_DISCOUNT_TOTAL_PAGES,
    GET_DISCOUNT_BY_ID_ACTION, CHANGE_DISCOUNTS_PER_PAGE_ACTION,
} from '@/store/actions/discounts';
import {
    addDiscount,
    deleteDiscount,
    editDiscount,
    fetchDiscountsPage,
} from '@/api/discounts/discounts';
import { getPerPage, searchDiscount, setPerPage } from '@/utils/utils';
import { fetchDiscountById } from '@/api/discounts/discounts';
import { UPDATE_PER_PAGE } from '@/store/actions';

export default {
    state: () => ({
        discounts: [],

        // Filtering
        searchText: null,
        searchDiscounts: null,
        searchAsync: false,
        selectedDiscountId: null,

        // CustomPagination
        currentPage: 1,
        perPage: getPerPage(),
        totalPages: 1,

        async: false,
    }),
    mutations: {
        [SET_DISCOUNTS](state, Discounts) {
            state.discounts = Discounts;
        },
        [SELECT_DISCOUNT](state, discountId) {
            state.selectedDiscountId = discountId;
        },
        [SET_DISCOUNTS_ASYNC](state, async) {
            state.async = async;
        },
        [SET_SEARCH_DISCOUNTS](state, searchDiscounts) {
            state.searchDiscounts = searchDiscounts;
        },
        [SET_DISCOUNTS_SEARCH_TEXT](state, searchText) {
            state.searchText = searchText;
        },
        [APPEND_DISCOUNTS_PAGE](state, Discounts) {
            if (state.discounts !== null) {
                state.discounts = state.discounts.concat(Discounts);
            } else {
                state.discounts = [...Discounts];
            }
        },
        [SET_SEARCH_ASYNC](state, async) {
            state.searchAsync = async;
        },
        [UPDATE_DISCOUNT](state, { id, ...data }) {
            for (let discount of state.discounts) {
                if (discount.id === id) {
                    Object.assign(discount, data);
                    return;
                }
            }
        },
        [ADD_NEW_DISCOUNT](state, newdiscount) {
            const index = state.discounts.findIndex(discount => discount.id === newdiscount.id);

            if (index === -1) {
                state.discounts.unshift(newdiscount);
            }
        },
        [DELETE_DISCOUNT](state, discountId) {
            if (state.discounts !== null) {
                state.discounts = state.discounts.filter(discount => discount.id !== discountId);
            }
        },
        [SET_DISCOUNTS_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
        [SET_DISCOUNT_TOTAL_PAGES](state, total) {
            state.totalPages = total;
        },
        [UPDATE_PER_PAGE](state) {
            state.perPage = getPerPage();
            state.currentPage = 1;
        },
    },
    actions: {
        async [FETCH_ALL_DISCOUNTS_ACTION]({ commit, dispatch, state }) {

            if (state.async) return;

            let page = 0;

            commit(SET_DISCOUNTS_ASYNC, true);
            commit(SET_DISCOUNTS, []);
            commit(SET_SEARCH_DISCOUNTS, null);
            commit(SET_DISCOUNTS_SEARCH_TEXT, null);

            let hasMore = true;

            while (hasMore) {
                page++;
                hasMore = await dispatch(FETCH_DISCOUNTS_PAGE_ACTION, { page });
            }

            commit(SET_DISCOUNTS_CURRENT_PAGE, 1);
            commit(SET_DISCOUNT_TOTAL_PAGES, page);

            commit(SET_DISCOUNTS_ASYNC, false);
        },
        async [FETCH_DISCOUNTS_PAGE_ACTION]({ state, commit }, { page }) {
            try {
                const {
                    'hydra:view': pagination,
                    'hydra:member': Discounts,
                } = await fetchDiscountsPage(page, state.perPage);
                if (Array.isArray(Discounts)) {
                    commit(APPEND_DISCOUNTS_PAGE, Discounts);
                }
                return pagination['hydra:next'];
            } catch (ex) {
                console.error('Fetch Discounts Page', ex);
            }
        },
        [SEARCH_DISCOUNTS_ACTION]({ commit, state }, searchText) {
            commit(SET_SEARCH_ASYNC, true);
            if (searchText === null || searchText.length === 0) {
                commit(SET_DISCOUNTS_SEARCH_TEXT, null);
                commit(SET_SEARCH_DISCOUNTS, null);
            } else {
                commit(SET_DISCOUNTS_SEARCH_TEXT, searchText);
                commit(SET_SEARCH_DISCOUNTS, state.discounts.filter(discount => searchDiscount(discount, searchText)));
            }
            commit(SET_SEARCH_ASYNC, false);
        },
        async [EDIT_DISCOUNT_ACTION]({ commit }, { id, ...data }) {
            commit(SET_DISCOUNTS_ASYNC, true);
            try {
                const updatedDiscount = await editDiscount(id, data);
                commit(UPDATE_DISCOUNT, updatedDiscount);
            } finally {
                commit(SET_DISCOUNTS_ASYNC, false);
            }
        },
        async [ADD_DISCOUNT_ACTION]({ commit }, data) {
            commit(SET_DISCOUNTS_ASYNC, true);
            try {
                const newDiscount = await addDiscount(data);
                commit(ADD_NEW_DISCOUNT, newDiscount);
                return newDiscount;
            } finally {
                commit(SET_DISCOUNTS_ASYNC, false);
            }
        },
        async [DELETE_DISCOUNT_ACTION]({ commit }, discountId) {
            commit(SET_DISCOUNTS_ASYNC, true);
            try {
                await deleteDiscount(discountId);
                commit(DELETE_DISCOUNT, discountId);
            } finally {
                commit(SET_DISCOUNTS_ASYNC, false);
            }
        },
        async [GET_DISCOUNT_BY_ID_ACTION]({ state, commit }, { discountId }) {
            if (state.async) return;

            commit(SET_DISCOUNTS_ASYNC, true);
            const discount = await fetchDiscountById(discountId).catch(() => commit(SET_DISCOUNTS_ASYNC, false));

            if (discount) {
                state.discounts = [discount, ...state.discounts];
                commit(SELECT_DISCOUNT, discount.id);
            }

            commit(SET_DISCOUNTS_ASYNC, false);
        },
        async [CHANGE_DISCOUNTS_PER_PAGE_ACTION]({ commit, dispatch }, perPage) {
            setPerPage(perPage);
            commit(UPDATE_PER_PAGE);
            dispatch(FETCH_ALL_DISCOUNTS_ACTION);
        },
    },
    getters: {
        selectedDiscount(state) {
            return state.discounts.find(b => b.id === state.selectedDiscountId);
        },
        discountOptions(state) {
            return state.discounts.map(d => ({ label: d.title, value: d.id }));
        },
        allDiscounts(state) {
            if (state.searchDiscounts !== null) {
                return state.searchDiscounts;
            } else {
                return state.discounts;
            }
        },
        paginatedDiscounts(state, getters) {
            const beginIndex = (state.currentPage - 1) * state.perPage;
            const endIndex = beginIndex + state.perPage;
            return getters.allDiscounts.slice(beginIndex, endIndex);
        },
        totalDiscountsPages(state) {
            return state.totalPages;
        },
        discountsAsync(state) {
            return state.async;
        },
    },
};
