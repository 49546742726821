<template>
    <div class="custom-editor">

        <editor-menu :editor="editor"/>

        <div class="editor-window">
            <editor-content class="editor__content"
                            @paste="onPaste"
                            :editor="editor"/>
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Image,
} from 'tiptap-extensions';
import EditorMenu from './components/EditorMenu';
import { stripHtml } from '@/utils/utils';

export default {
    components: {
        EditorMenu,
        EditorContent,
    },
    props: {
        contentProp: String,
        enabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Image(),
                ],
                editorProps: {
                    transformPastedHTML: (html) => {
                        let stripped = stripHtml(html);
                        return stripped;
                    },
                },
                content: this.contentProp,
                onUpdate: ({ getHTML }) => {
                    // get new content on update
                    this.$emit('change', getHTML());
                },
                autoFocus: true,
                editable: this.enabled
            }),
        };
    },
    methods: {
        onPaste(e) {
            console.log(e);
        },
    },
    watch: {
        contentProp: function (newVal) {

            if (newVal === this.editor.getHTML()) {    // Internal change, do nothing
                return;
            }

            this.editor.setContent(newVal ?? "");
        },
        enabled: function (newVal) {
            this.editor.setOptions({ editable: newVal });
        }
    },
    computed: {},
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>


<style lang="scss">

@import "sass/editor_main";

.custom-editor {
    display: flex;
    flex-direction: column;
    margin: 25px 0;
    overflow: auto;
    background: $widgetBg;
    border-radius: 4px;
    flex-basis: 0;
    flex-grow: 1;

    .editor-window {
        overflow: auto;
        flex: 1;
        padding: 5px 15px;
        border: 1px solid var(--border-color);
    }
}

</style>

