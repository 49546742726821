<template>
    <div class="manage-publisher">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="publisher-title">Novi Izdavač</div>
        <div class="publisher-status">
        </div>
        <container class="body-container">
            <input-label :required="true" :error="errors.name" label="Naziv">
                <input-field v-model="form.name" placeholder="Naziv"/>
            </input-label>

            <input-label :required="true" :error="errors.address" label="Adresa">
                <input-field v-model="form.address" placeholder="Adresa"/>
            </input-label>

            <input-label :required="true" :error="errors.phone" label="Telefon">
                <input-field v-model="form.phone" placeholder="Telefon"/>
            </input-label>

            <input-label :required="true" :error="errors.email" label="Email">
                <input-field v-model="form.email" placeholder="Email"/>
            </input-label>

            <input-label label="Website">
                <input-field v-model="form.website" placeholder="Website"/>
            </input-label>

            <input-label label="Procenat zarade (%)">
                <input-field v-model="form.income_percent" type="number" :min="0" :max="100"/>
            </input-label>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <div style="display: flex">
                <Button class="save-button" @click="assignPublisherBooks" :loading="async">
                    Dodijeli knjige
                </Button>

                <Button class="save-button" @click="submit" :loading="async">
                    Snimi
                </Button>
            </div>
        </container>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Container from '@/components/Ui/Container/Container';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference, showErrorSwal, showSuccessSwal } from '@/utils/utils';
import {
    ADD_PUBLISHER_ACTION,
    EDIT_PUBLISHER_ACTION,
    GET_PUBLISHER_BY_ID_ACTION,
    SELECT_PUBLISHER,
} from '@/store/actions/publishers';
import { cloneDeep } from 'lodash';
import Spinner from '@/components/Ui/Spinner/Spinner';
import { unlockAllPublisherBooks } from '@/api/publishers/publishers';

const defaultForm = {
    name: '',
    address: '',
    phone: '',
    website: '',
    email: '',
};

export default {
    name: 'ManagePublisher',
    components: { Spinner, Button, InputLabel, InputField, Container },
    data() {
        return {
            form: cloneDeep(defaultForm),
            errors: {
                general: [],
            },
        };
    },
    methods: {
        ...mapActions({
            addPublisher: ADD_PUBLISHER_ACTION,
            editPublisher: EDIT_PUBLISHER_ACTION,
            getPublisherById: GET_PUBLISHER_BY_ID_ACTION,
        }),
        ...mapMutations({
            selectPublisher: SELECT_PUBLISHER,
        }),
        setData() {
            if (this.publisher) {
                this.form = cloneDeep(this.publisher);
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.name) {
                errors.name = 'Naziv je obavezan';
            }

            if (!this.form.email) {
                errors.email = 'Email je obavezan';
            }

            if (!this.form.address) {
                errors.address = 'Adresa je obavezna';
            }

            if (!this.form.phone) {
                errors.phone = 'Broj telefona je obavezan';
            }

            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        async assignPublisherBooks() {
            try {
                await unlockAllPublisherBooks(this.publisher.id);
                showSuccessSwal();
            } catch (ex) {
                showErrorSwal();
            }
        },
        async submit() {
            if (!this.validateFields()) return;

            this.form.income_percent = Number(this.form.income_percent);

            if (this.publisher) {
                try {
                    await this.editPublisher({ id: this.publisher.id, ...difference(this.form, this.publisher) });
                    this.$router.back();
                } catch (ex) {
                    console.error('error editing publisher');
                }
            } else {
                //adding
                try {
                    await this.addPublisher({ ...difference(this.form, defaultForm) }).then(() => this.$router.back());
                } catch (ex) {
                    this.errors.general.push('Error adding publisher');
                    console.error('error adding publisher');
                }
            }
        },
    },
    async beforeMount() {

        const { params } = this.$route;

        if (!this.publisher) {

            if (params.id) {
                await this.getPublisherById({ publisherId: params.id });
            }
        }

        this.setData();
    },
    beforeDestroy() {
        this.selectPublisher(null);
    },
    computed: {
        ...mapGetters({
            publisher: 'selectedPublisher',
        }),
        async() {
            return this.$store.state.publishers.async;
        },
    },
};
</script>

<style lang="scss">
.manage-publisher {
    .body-container {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .labeled-input {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .save-button {
            margin-left: auto;
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }
    }
}
</style>
